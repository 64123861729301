import React, { useContext, useEffect, useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import {
  AppBar,
  CssBaseline,
  Toolbar,
  IconButton,
  SwipeableDrawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Avatar,
  Collapse,
  Typography,
  Button,
  CircularProgress,
} from "@material-ui/core";
import ReconnectingWebSocket from "reconnecting-websocket";

import { Context } from "../../context";
import { Link } from "react-router-dom";
import Api from "../../service";
import history from "../../routes/history";

import MenuIcon from "@material-ui/icons/Menu";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ListItemButton from "@mui/material/ListItemButton";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import LocalActivityOutlinedIcon from "@mui/icons-material/LocalActivityOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearchOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import GroupAddOutlinedIcon from "@mui/icons-material/GroupAddOutlined";
import StorefrontOutlinedIcon from "@mui/icons-material/StorefrontOutlined";
import LocalMallOutlinedIcon from "@mui/icons-material/LocalMallOutlined";

import StoreMetrics from "../../components/storeMetrics";
import PartnerMetrics from "../../components/partnerMetrics";
import SellerMetrics from "../../components/sellerMetrics";
import AdminMetrics from "../../components/adminMetrics";

const drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    color: "#ffffff",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: theme.palette.primary.main,
  },
  appbarContent: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  menuButton: {
    marginRight: 10,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    [theme.breakpoints.up("xxs")]: {
      width: "250px",
    },
    [theme.breakpoints.up("xs")]: {
      width: "250px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "250px",
    },
    [theme.breakpoints.up("md")]: {
      width: "250px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "300px",
    },
    [theme.breakpoints.up("xl")]: {
      width: "300px",
    },
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    background: theme.palette.primary.main,
    width: drawerWidth,
    [theme.breakpoints.up("xxs")]: {
      width: "250px",
    },
    [theme.breakpoints.up("xs")]: {
      width: "250px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "250px",
    },
    [theme.breakpoints.up("md")]: {
      width: "250px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "300px",
    },
    [theme.breakpoints.up("xl")]: {
      width: "300px",
    },
    overflowX: "hidden",
    scrollbarWidth: "thin",
  },
  listIcon: {
    color: "#ffffff",
  },
  avatarColor: {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
  avatarBorder: {
    height: 84,
    width: 84,
    borderWidth: 2,
    borderColor: theme.palette.secondary.light,
    borderStyle: "solid",
    display: "inline-block",
    borderRadius: "50%",
    boxSizing: "inherit",
  },
  avatarSize: {
    height: 60,
    width: 60,
    fontSize: 25,
    margin: "10px 0px 0px 10px",
    textAlign: "center",
    boxShadow: "0px 8px 10px -7px #000000",
  },
  avatarContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    marginBottom: 20,
    marginTop: 20,
  },
  hover: {
    "&:hover": {
      opacity: 0.5,
    },
  },
  userName: {
    fontWeight: "bold",
    fontSize: 20,
    textAlign: "center",
    color: "#ffffff",
    paddingLeft: 10,
    paddingRight: 10,
  },
  creditsTitle: {
    fontSize: 20,
    textAlign: "center",
    color: "#ffffff",
    marginTop: 10,
  },
}));

function DefineAvatar({ name }) {
  const trimmedName = name.trim();
  const firstLetter = trimmedName[0].toUpperCase();
  const lastLetter = trimmedName.split(" ").pop()[0].toUpperCase();

  return (
    <>
      {firstLetter}
      {lastLetter}
    </>
  );
}

const MainPage = () => {
  const classes = useStyles();

  const {
    barLeftOpen,
    setBarLeftOpen,
    selectedIndex,
    setSelectedIndex,
    setPage,
    cookie,
    handleLogout,
    page,
    setTabIndex,
    setOpenUsers,
    openUsers,
    setOpenPackage,
    openPackage,
    openCampaign,
    setOpenCampaign,
    setIsFromCreateCampaign,
    setIsFromEditCampaign,
    setLoading,
    setCookie,
    setUserInfos,
    setLoadingLogin,
  } = useContext(Context);

  const [loadingUpdateCredits, setLoadingUpdateCredits] = useState(false);

  useEffect(() => {
    const token = cookie.token.accessToken;
    Api.defaults.headers.common["x-access-token"] = token;

    setLoading(false);
    setLoadingLogin(false);
    setLoadingUpdateCredits(false);

    if (window.location.hostname !== "localhost") {
      if (cookie.userInfo[0].role === "store") {
        const ws = new ReconnectingWebSocket(
          `wss://${process.env.REACT_APP_DOMAIN}/?_id=${cookie.userInfo[0]._id}`
        );

        ws.onopen = () => {};

        ws.onmessage = (event) => {
          const data = JSON.parse(event.data);

          if (data.statusTransaction === "PAID_OUT") {
            updateCookie(data.newCredits);
          }
        };

        ws.onerror = (error) => {
          console.error("Erro na conexão WebSocket:", error.message);
          console.error("Stack trace:", error.stack);
        };

        ws.onerror = (error) => {
          console.error("Erro na conexão WebSocket:", error);
        };

        return () => {
          ws.close();
        };
      }
    }
  }, []); // eslint-disable-line

  const handleDrawerOpen = () => {
    setBarLeftOpen(true);
  };

  function updateCookie(newCredit) {
    const newData = {
      _id: cookie.userInfo[0]._id,
      fullName: cookie.userInfo[0].fullName,
      password: cookie.userInfo[0].password,
      manager: cookie.userInfo[0].manager,
      role: cookie.userInfo[0].role,
      credit: newCredit,
      phone: cookie.userInfo[0].phone,
      email: cookie.userInfo[0].email,
      document: cookie.userInfo[0].document,
      address: cookie.userInfo[0].address,
      status: cookie.userInfo[0].status,
    };

    setCookie("userInfo", [newData], { expire: 1, path: "/" });
    setUserInfos([newData]);
  }

  const handleDrawerClose = () => {
    setBarLeftOpen(false);
  };

  const handleHelpLogout = () => {
    handleDrawerClose();
    handleLogout();
  };

  const handleSelected = (e, index, origin, tabIndexLocal) => {
    switch (index) {
      case 1:
        setOpenUsers(false);
        setOpenPackage(false);
        setOpenCampaign(false);
        setBarLeftOpen(false);
        setPage(origin);
        setSelectedIndex([index, origin]);
        setTabIndex(tabIndexLocal);

        break;
      case 2:
        setPage(origin);
        setSelectedIndex([index, origin]);
        setBarLeftOpen(false);
        setTabIndex(tabIndexLocal);
        break;

      case 3:
        setPage(origin);
        setSelectedIndex([index, origin]);
        setBarLeftOpen(false);
        setTabIndex(tabIndexLocal);
        break;

      case 4:
        setPage(origin);
        setSelectedIndex([index, origin]);
        setBarLeftOpen(false);
        setTabIndex(tabIndexLocal);
        break;

      case 5:
        setPage(origin);
        setSelectedIndex([index, origin]);
        setBarLeftOpen(false);
        setTabIndex(tabIndexLocal);
        break;

      case 6:
        setPage(origin);
        setSelectedIndex([index, origin]);
        setBarLeftOpen(false);
        setTabIndex(tabIndexLocal);
        setIsFromEditCampaign(false);
        if (tabIndexLocal === 1) {
          setIsFromCreateCampaign(true);
        } else {
          setIsFromCreateCampaign(false);
        }
        break;

      case 7:
        setPage(origin);
        setSelectedIndex([index, origin]);
        setBarLeftOpen(false);
        setTabIndex(tabIndexLocal);
        break;

      case 8:
        setPage(origin);
        setSelectedIndex([index, origin]);
        setBarLeftOpen(false);
        setTabIndex(tabIndexLocal);
        break;

      case 11:
        history.push("/");
        window.location.reload();
        break;

      default:
        setPage(origin);
        setSelectedIndex([index, "main"]);
        setBarLeftOpen(false);
        setTabIndex(tabIndexLocal);
        break;
    }
  };

  const handleExpandUsers = () => {
    setOpenUsers(!openUsers);
  };

  const handleExpandPackage = () => {
    setOpenPackage(!openPackage);
  };

  const handleExpandCampaing = () => {
    setOpenCampaign(!openCampaign);
  };

  const handleOpenUserInfo = () => {
    setBarLeftOpen(false);
    setPage("Editar perfil");
    setTabIndex(5);
    history.push("/user-details");
  };

  const handleHomePageButton = () => {
    history.push("/main");
    setLoading(true);
    window.location.reload();
  };

  const handleUpdateCredits = () => {
    setLoadingUpdateCredits(true);
    Api.get("/api/user/detail", {
      params: {
        _id: cookie.userInfo[0]._id,
      },
    })
      .then((res) => {
        setCookie("userInfo", res.data, { expire: 1, path: "/" });
        setUserInfos(res.data);
        setLoadingUpdateCredits(false);
      })
      .catch((err) => {
        console.error(err);
        setLoadingUpdateCredits(false);
      });
  };

  return (
    <>
      <CssBaseline />
      <div className={classes.root}>
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: barLeftOpen,
          })}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, {
                [classes.hide]: barLeftOpen,
              })}
            >
              <MenuIcon />
            </IconButton>
            <div className={classes.appbarContent}>
              <Typography variant="h6" noWrap>
                {page}
              </Typography>
              <IconButton onClick={handleHomePageButton}>
                <HomeOutlinedIcon className={classes.listIcon} />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
        <SwipeableDrawer
          open={barLeftOpen}
          onClose={handleDrawerClose}
          onOpen={handleDrawerOpen}
          classes={{
            paper: classes.drawerOpen,
          }}
        >
          <div className={classes.avatarContainer}>
            <IconButton onClick={handleOpenUserInfo}>
              <div className={`${classes.avatarBorder}`}>
                <Avatar
                  className={`${classes.avatarColor} ${classes.avatarSize}`}
                >
                  <DefineAvatar name={cookie.userInfo[0].fullName} />
                </Avatar>
              </div>
            </IconButton>
            <div style={{ whiteSpace: "pre-wrap" }}>
              <div className={classes.userName}>
                {cookie.userInfo[0].fullName}
              </div>
            </div>
            {cookie.userInfo[0].role === "store" && (
              <div style={{ whiteSpace: "pre-wrap" }}>
                <div className={classes.creditsTitle}>
                  Créditos: {cookie.userInfo[0].credit}
                </div>
              </div>
            )}
            {(cookie.userInfo[0].role === "partner" ||
              cookie.userInfo[0].role === "seller" ||
              cookie.userInfo[0].role === "admin" ||
              cookie.userInfo[0].role === "store") && (
              <>
                {cookie.userInfo[0].role !== "store" && (
                  <div style={{ whiteSpace: "pre-wrap" }}>
                    {loadingUpdateCredits ? (
                      <CircularProgress
                        style={{ color: "white", marginTop: 8 }}
                        size={25}
                      />
                    ) : (
                      <div className={classes.creditsTitle}>
                        Saldo: R$ {Number(cookie.userInfo[0].credit).toFixed(2)}
                      </div>
                    )}
                  </div>
                )}
                <Button
                  color="secondary"
                  variant="outlined"
                  style={{ marginTop: 20 }}
                  onClick={handleUpdateCredits}
                >
                  Atualizar Saldo
                </Button>
              </>
            )}
          </div>

          <List>
            <ListItem
              button
              component={Link}
              to="/main"
              className={classes.listIcon}
              selected={selectedIndex[0] === 1}
              onClick={(e) => handleSelected(e, 1, "Página Inicial")}
            >
              <ListItemIcon>
                <HomeOutlinedIcon className={classes.listIcon} />
              </ListItemIcon>
              <ListItemText primary="Página Inicial" />
            </ListItem>

            <ListItem
              button
              component={Link}
              to="/"
              className={classes.listIcon}
              selected={selectedIndex[0] === 11}
              onClick={(e) => handleSelected(e, 11, "Painel do Cliente")}
            >
              <ListItemIcon>
                <LocalMallOutlinedIcon className={classes.listIcon} />
              </ListItemIcon>
              <ListItemText primary="Painel do Cliente" />
            </ListItem>

            <ListItem
              button
              component={Link}
              to="/user-details"
              className={classes.listIcon}
              selected={selectedIndex[0] === 5}
              onClick={(e) => handleSelected(e, 5, "Editar perfil")}
            >
              <ListItemIcon>
                <ManageAccountsOutlinedIcon className={classes.listIcon} />
              </ListItemIcon>
              <ListItemText primary="Editar perfil" />
            </ListItem>

            {cookie.userInfo[0].role === "admin" && (
              <>
                {/* START USER BUTTON */}
                <ListItem
                  button
                  key="user"
                  className={classes.listIcon}
                  selected={selectedIndex[0] === 2}
                  onClick={handleExpandUsers}
                >
                  <ListItemIcon className={classes.listIcon}>
                    <GroupOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Usuários" />
                  {openUsers ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={openUsers} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItemButton
                      sx={{ pl: 4 }}
                      component={Link}
                      to="/user"
                      selected={
                        selectedIndex[0] === 2 &&
                        selectedIndex[1] === "Usuários"
                      }
                      onClick={(e) => handleSelected(e, 2, "Usuários")}
                    >
                      <ListItemIcon className={classes.listIcon}>
                        <SearchOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary="Consultar"
                        className={classes.listIcon}
                      />
                    </ListItemButton>
                    <ListItemButton
                      sx={{ pl: 4 }}
                      component={Link}
                      to="/create"
                      selected={
                        selectedIndex[0] === 2 &&
                        selectedIndex[1] === "Cadastrar"
                      }
                      onClick={(e) => handleSelected(e, 2, "Cadastrar", 0)}
                    >
                      <ListItemIcon className={classes.listIcon}>
                        <AddOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary="Cadastrar"
                        className={classes.listIcon}
                      />
                    </ListItemButton>
                  </List>
                </Collapse>
                {/* END USER BUTTON */}

                {/* START PACKAGE BUTTON */}
                <ListItem
                  button
                  component={Link}
                  to="/coupon"
                  className={classes.listIcon}
                  selected={selectedIndex[0] === 3}
                  onClick={(e) => handleSelected(e, 3, "Campanhas")}
                >
                  <ListItemIcon className={classes.listIcon}>
                    <LocalActivityOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Campanhas" />
                </ListItem>
                {/* END PACKAGE BUTTON */}
              </>
            )}
            {cookie.userInfo[0].role === "store" && (
              <>
                <ListItem
                  button
                  component={Link}
                  to="/coupon"
                  className={classes.listIcon}
                  selected={selectedIndex[0] === 4}
                  onClick={(e) => handleSelected(e, 4, "Recarregar créditos")}
                >
                  <ListItemIcon>
                    <MonetizationOnOutlinedIcon className={classes.listIcon} />
                  </ListItemIcon>
                  <ListItemText primary="Recarregar créditos" />
                </ListItem>

                <ListItem
                  button
                  key="campaign"
                  className={classes.listIcon}
                  selected={selectedIndex[0] === 6}
                  onClick={handleExpandCampaing}
                >
                  <ListItemIcon className={classes.listIcon}>
                    <LocalActivityOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Campanhas" />
                  {openCampaign ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={openCampaign} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItemButton
                      sx={{ pl: 4 }}
                      component={Link}
                      to="/campaign"
                      selected={
                        selectedIndex[0] === 6 &&
                        selectedIndex[1] === "Consultar Cadastros"
                      }
                      onClick={(e) =>
                        handleSelected(e, 6, "Consultar Cadastros")
                      }
                    >
                      <ListItemIcon className={classes.listIcon}>
                        <SearchOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary="Consultar Cadastros"
                        className={classes.listIcon}
                      />
                    </ListItemButton>
                    <ListItemButton
                      sx={{ pl: 4 }}
                      component={Link}
                      to="/consult-clients"
                      selected={
                        selectedIndex[0] === 6 &&
                        selectedIndex[1] === "Consultar Clientes"
                      }
                      onClick={(e) =>
                        handleSelected(e, 6, "Consultar Clientes")
                      }
                    >
                      <ListItemIcon className={classes.listIcon}>
                        <ContentPasteSearchIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary="Consultar Clientes"
                        className={classes.listIcon}
                      />
                    </ListItemButton>
                    <ListItemButton
                      sx={{ pl: 4 }}
                      component={Link}
                      to="/campaign"
                      selected={
                        selectedIndex[0] === 6 &&
                        selectedIndex[1] === "Cadastrar Campanha"
                      }
                      onClick={(e) =>
                        handleSelected(e, 6, "Cadastrar Campanha", 1)
                      }
                    >
                      <ListItemIcon className={classes.listIcon}>
                        <AddOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary="Cadastrar"
                        className={classes.listIcon}
                      />
                    </ListItemButton>
                  </List>
                </Collapse>
              </>
            )}
            {cookie.userInfo[0].role === "partner" && (
              <>
                <ListItem
                  button
                  component={Link}
                  to="/create-seller"
                  className={classes.listIcon}
                  selected={selectedIndex[0] === 7}
                  onClick={(e) => handleSelected(e, 7, "Cadastrar Vendedor")}
                >
                  <ListItemIcon>
                    <GroupAddOutlinedIcon className={classes.listIcon} />
                  </ListItemIcon>
                  <ListItemText primary="Cadastrar Vendedor" />
                </ListItem>

                <ListItem
                  button
                  component={Link}
                  to="/sellers"
                  className={classes.listIcon}
                  selected={selectedIndex[0] === 8}
                  onClick={(e) => handleSelected(e, 8, "Meus Vendedores")}
                >
                  <ListItemIcon>
                    <GroupsOutlinedIcon className={classes.listIcon} />
                  </ListItemIcon>
                  <ListItemText primary="Meus Vendedores" />
                </ListItem>
              </>
            )}

            {cookie.userInfo[0].role === "seller" && (
              <>
                <ListItem
                  button
                  component={Link}
                  to="/create-store"
                  className={classes.listIcon}
                  selected={selectedIndex[0] === 9}
                  onClick={(e) => handleSelected(e, 9, "Cadastrar Lojista")}
                >
                  <ListItemIcon>
                    <GroupAddOutlinedIcon className={classes.listIcon} />
                  </ListItemIcon>
                  <ListItemText primary="Cadastrar Lojista" />
                </ListItem>

                <ListItem
                  button
                  component={Link}
                  to="/stores"
                  className={classes.listIcon}
                  selected={selectedIndex[0] === 10}
                  onClick={(e) => handleSelected(e, 10, "Meus Lojistas")}
                >
                  <ListItemIcon>
                    <StorefrontOutlinedIcon className={classes.listIcon} />
                  </ListItemIcon>
                  <ListItemText primary="Meus Lojistas" />
                </ListItem>
              </>
            )}
            {/* START LOGOUT BUTTON */}
            <ListItem
              button
              key="logout"
              component={Link}
              to="/login"
              onClick={() => handleHelpLogout()}
            >
              <ListItemIcon className={classes.listIcon}>
                <LogoutOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Sair" className={classes.listIcon} />
            </ListItem>
            {/* END LOGOUT BUTTON */}
          </List>
        </SwipeableDrawer>

        {selectedIndex[0] === 1 && cookie.userInfo[0].role === "store" && (
          <StoreMetrics />
        )}

        {selectedIndex[0] === 1 && cookie.userInfo[0].role === "partner" && (
          <PartnerMetrics />
        )}

        {selectedIndex[0] === 1 && cookie.userInfo[0].role === "seller" && (
          <SellerMetrics />
        )}

        {selectedIndex[0] === 1 && cookie.userInfo[0].role === "admin" && (
          <AdminMetrics />
        )}
      </div>
    </>
  );
};

export default MainPage;

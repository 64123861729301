import React, { useContext, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  CardActionArea,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from "@material-ui/core";

import { Context } from "../../context";

import CupomzitoSvg from "../../assets/cupomzito.svg";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

import Api from "../../service";

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    width: 30,
    height: 30,
  },
  avatarIconSize: {
    maxWidth: 18,
    maxHeight: 18,
  },
  cardContainer: {
    width: 180,
    height: 260,
    minHeight: 260,
    backgroundColor: theme.palette.primary.light,
  },
  cardActionAreaContainer: {
    minHeight: 260,
  },
  subheaderColor: {
    fontSize: 13,
    color: theme.palette.primary.mainSecondary,
  },
  contentTitle: {
    fontSize: 15,
    fontWeight: "bold",
    position: "absolute",
    top: 125,
    paddingRight: 10,
    wordBreak: "break-word",
    color: theme.palette.primary.main,
  },
  contentContact: {
    wordBreak: "break-word",
    position: "absolute",
    bottom: 10,
    color: theme.palette.primary.mainSecondary,
    display: "flex",
    paddingRight: 15,
    alignContent: "center",
  },
  cardActions: {
    display: "flex",
    justifyContent: "center",
  },
  contentSubtitle: {
    wordBreak: "break-word",
    position: "absolute",
    bottom: 50,
    color: theme.palette.primary.mainSecondary,
    display: "flex",
    alignContent: "center",
  },
  cardMediaContainer: {
    position: "absolute",
    top: 0,
    height: 120,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    left: "50%",
    transform: "translateX(-50%)",
    backgroundColor: theme.palette.primary.main,
  },
}));

export default function CouponItem(props) {
  const classes = useStyles();
  const { item } = props;
  const { cookie } = useContext(Context);

  const [openMaximizedPreviewDialog, setOpenMaximizedPreviewDialog] =
    useState(false);

  useEffect(() => {
    const token = cookie.token.accessToken;
    Api.defaults.headers.common["x-access-token"] = token;

    setOpenMaximizedPreviewDialog(false);
  }, []);

  const handleClickOnCardArea = () => {
    setOpenMaximizedPreviewDialog(true);
  };

  const handleCloseModal = () => {
    setOpenMaximizedPreviewDialog(false);
  };

  const formatPhoneNumber = (number) => {
    let cleaned = ("" + number).replace(/\D/g, "");

    if (cleaned.length <= 11) {
      cleaned = "55" + cleaned;
    }

    if (cleaned.length > 11) {
      return cleaned;
    }

    return cleaned.length === 13 ? cleaned : "";
  };

  const handleTalkWithStore = () => {
    const rawPhoneNumber = item.campaignId.contact;
    const phoneNumber = formatPhoneNumber(rawPhoneNumber);

    const message = `Olá! Me chamo ${cookie.userInfo[0].fullName}. Estou vindo do CupomZito.com EU RESGATEI o cupom com desconto de ${item.campaignId.fullName}.`;

    const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
      message
    )}`;

    window.open(whatsappURL, "_blank");
    Api.put("/api/package/useCoupon", { packageId: item._id }).then(() => {
      window.location.reload();
    });
  };

  return (
    <>
      <Card className={classes.cardContainer}>
        <CardActionArea
          className={classes.cardActionAreaContainer}
          onClick={handleClickOnCardArea}
        >
          <div className={classes.cardMediaContainer}>
            <CardMedia
              component="img"
              alt="bannerImage"
              src={
                item.campaignId.banner !== "default"
                  ? item.campaignId.banner
                  : CupomzitoSvg
              }
              style={{
                padding: item.campaignId.banner !== "default" ? 0 : 10,
                maxHeight: "100%",
                width: "100%",
              }}
            />
          </div>
          <CardContent>
            <Typography variant="body1" className={classes.contentTitle}>
              {item.campaignId.fullName.toUpperCase()}
            </Typography>
            <Typography variant="body2" className={classes.contentSubtitle}>
              Clique para ver detalhes
            </Typography>
            <Typography variant="body2" className={classes.contentContact}>
              {item.storeId.fullName}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
      <Dialog
        open={openMaximizedPreviewDialog}
        onClose={handleCloseModal}
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle>
          <strong>
            <em>Atenção:</em>
          </strong>
        </DialogTitle>
        <DialogContent style={{ textAlign: "center" }}>
          {item.status !== "used" ? (
            <h3 style={{ color: "gray" }}>
              Ao clicar em "Validar Cupom", você será redirecionado para o
              contato com o comerciante via WhatsApp e seu cupom será
              <strong> consumido</strong>. Esta ação é irreversível.
            </h3>
          ) : (
            <h3 style={{ color: "gray" }}>
              Este cupom já foi resgatado! <br></br>Em caso de dúvidas, fale com
              o comerciante: {item.campaignId.contact}
            </h3>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} style={{ color: "#808080" }}>
            Fechar
          </Button>
          <Button
            variant={"contained"}
            color="secondary"
            disabled={item.status === "used"}
            style={{ color: "white", fontWeight: "bold" }}
            onClick={handleTalkWithStore}
            startIcon={<WhatsAppIcon />}
          >
            Validar Cupom
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

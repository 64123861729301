import React, { useContext, useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Paper,
  Grid,
  IconButton,
  Avatar,
  TextField,
  Button,
  InputAdornment,
  Tooltip,
  Typography,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "@material-ui/core";
import { Context } from "../../context";
import Api from "../../service";
import history from "../../routes/history";

import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import AssignmentIndOutlinedIcon from "@mui/icons-material/AssignmentIndOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import SignpostOutlinedIcon from "@mui/icons-material/SignpostOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ApartmentOutlinedIcon from "@mui/icons-material/ApartmentOutlined";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import PhoneFormatter from "../../utils/PhoneFormatter";
import DocumentFormatter from "../../utils/DocumentFormatter";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
  },
  general: {
    marginTop: "100px",
    scrollbarWidth: "thin",
    [theme.breakpoints.up("xxs")]: {
      width: "85%",
    },
    [theme.breakpoints.up("xs")]: {
      width: "85%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "85%",
    },
    [theme.breakpoints.up("md")]: {
      width: "85%",
    },
    [theme.breakpoints.up("lg")]: {
      width: "60vw",
    },
    [theme.breakpoints.up("xl")]: {
      width: "60vw",
    },
    marginBottom: 30,
  },
  leftContainer: {
    padding: 30,
    boxShadow: "8px 0px 25px -13px rgba(66, 68, 90, 1)",
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.up("xxs")]: {
      borderRight: "hidden",
    },
    [theme.breakpoints.up("xs")]: {
      borderRight: "hidden",
    },
    [theme.breakpoints.up("sm")]: {
      borderRight: "hidden",
    },
    [theme.breakpoints.up("md")]: {
      borderRight: "hidden",
    },
    [theme.breakpoints.up("lg")]: {
      borderRight: "1px solid rgba(0,0,0,0.19)",
    },
    [theme.breakpoints.up("xl")]: {
      borderRight: "1px solid rgba(0,0,0,0.19)",
    },
  },
  rightContainer: {
    padding: 30,
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.primary.light,
    [theme.breakpoints.up("xxs")]: {
      backgroundColor: theme.palette.primary.main,
    },
    [theme.breakpoints.up("xs")]: {
      backgroundColor: theme.palette.primary.main,
    },
    [theme.breakpoints.up("sm")]: {
      backgroundColor: theme.palette.primary.main,
    },
    [theme.breakpoints.up("md")]: {
      backgroundColor: theme.palette.primary.main,
    },
    [theme.breakpoints.up("lg")]: {
      backgroundColor: theme.palette.primary.light,
    },
    [theme.breakpoints.up("xl")]: {
      backgroundColor: theme.palette.primary.light,
    },
  },
  avatarColor: {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
  avatarBorder: {
    height: 84,
    width: 84,
    borderWidth: 2,
    borderColor: theme.palette.secondary.light,
    borderStyle: "solid",
    display: "inline-block",
    borderRadius: "50%",
    boxSizing: "inherit",
  },
  avatarSize: {
    height: 60,
    width: 60,
    fontSize: 25,
    margin: "10px 0px 0px 10px",
    textAlign: "center",
    boxShadow: "0px 8px 10px -7px #000000",
  },
  avatarContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    marginBottom: 20,
    marginTop: 20,
    width: "100%",
  },
  userName: {
    fontWeight: "bold",
    fontSize: 20,
    textAlign: "center",
    color: theme.palette.primary.light,
  },
  rightContainerTitle: {
    fontWeight: "bold",
    fontSize: 20,
    textAlign: "left",
    marginBottom: 20,
    [theme.breakpoints.up("xxs")]: {
      color: theme.palette.primary.light,
    },
    [theme.breakpoints.up("xs")]: {
      color: theme.palette.primary.light,
    },
    [theme.breakpoints.up("sm")]: {
      color: theme.palette.primary.light,
    },
    [theme.breakpoints.up("md")]: {
      color: theme.palette.primary.light,
    },
    [theme.breakpoints.up("lg")]: {
      color: theme.palette.primary.main,
    },
    [theme.breakpoints.up("xl")]: {
      color: theme.palette.primary.main,
    },
  },
  divider: {
    borderBottom: "1px solid rgb(253, 245, 230)",
  },
  infoItems: {
    fontSize: 15,
    padding: 10,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    color: theme.palette.primary.light,
  },
  infoItemsIcon: {
    color: theme.palette.primary.light,
  },
  inputMaior: {
    minWidth: "100%",
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        [theme.breakpoints.up("xxs")]: {
          borderColor: theme.palette.primary.lightSecondary,
        },
        [theme.breakpoints.up("xs")]: {
          borderColor: theme.palette.primary.lightSecondary,
        },
        [theme.breakpoints.up("sm")]: {
          borderColor: theme.palette.primary.lightSecondary,
        },
        [theme.breakpoints.up("md")]: {
          borderColor: theme.palette.primary.lightSecondary,
        },
        [theme.breakpoints.up("lg")]: {
          borderColor: theme.palette.primary.main,
        },
        [theme.breakpoints.up("xl")]: {
          borderColor: theme.palette.primary.main,
        },
      },
      "&:hover fieldset": {
        [theme.breakpoints.up("xxs")]: {
          borderColor: theme.palette.primary.lightSecondary,
        },
        [theme.breakpoints.up("xs")]: {
          borderColor: theme.palette.primary.lightSecondary,
        },
        [theme.breakpoints.up("sm")]: {
          borderColor: theme.palette.primary.lightSecondary,
        },
        [theme.breakpoints.up("md")]: {
          borderColor: theme.palette.primary.lightSecondary,
        },
        [theme.breakpoints.up("lg")]: {
          borderColor: theme.palette.primary.main,
        },
        [theme.breakpoints.up("xl")]: {
          borderColor: theme.palette.primary.main,
        },
      },
      "&.Mui-disabled fieldset": {
        [theme.breakpoints.up("xxs")]: {
          borderColor: "gray",
        },
        [theme.breakpoints.up("xs")]: {
          borderColor: "gray",
        },
        [theme.breakpoints.up("sm")]: {
          borderColor: "gray",
        },
        [theme.breakpoints.up("md")]: {
          borderColor: "gray",
        },
        [theme.breakpoints.up("lg")]: {
          borderColor: "gray",
        },
        [theme.breakpoints.up("xl")]: {
          borderColor: "gray",
        },
      },
    },
  },
  buttonInput: {
    width: "100%",
  },
  backButton: {
    width: "100%",
    marginTop: 10,
    marginBottom: 10,
  },
  buttonEdit: {
    marginTop: 10,
    color: "white",
    borderColor: "white",
  },
  inputColor: {
    [theme.breakpoints.up("xxs")]: {
      color: theme.palette.primary.light,
    },
    [theme.breakpoints.up("xs")]: {
      color: theme.palette.primary.light,
    },
    [theme.breakpoints.up("sm")]: {
      color: theme.palette.primary.light,
    },
    [theme.breakpoints.up("md")]: {
      color: theme.palette.primary.light,
    },
    [theme.breakpoints.up("lg")]: {
      color: theme.palette.primary.main,
    },
    [theme.breakpoints.up("xl")]: {
      color: theme.palette.primary.main,
    },
  },
  notchedOutline: {
    backgroundColor: "transparent",
    [theme.breakpoints.up("xxs")]: {
      borderColor: theme.palette.primary.light,
      textColor: theme.palette.primary.light,
    },
    [theme.breakpoints.up("xs")]: {
      borderColor: theme.palette.primary.light,
      textColor: theme.palette.primary.light,
    },
    [theme.breakpoints.up("sm")]: {
      borderColor: theme.palette.primary.light,
      textColor: theme.palette.primary.light,
    },
    [theme.breakpoints.up("md")]: {
      borderColor: theme.palette.primary.light,
      textColor: theme.palette.primary.light,
    },
    [theme.breakpoints.up("lg")]: {
      borderColor: theme.palette.primary.main,
      textColor: theme.palette.primary.main,
    },
    [theme.breakpoints.up("xl")]: {
      borderColor: theme.palette.primary.main,
      textColor: theme.palette.primary.main,
    },
  },
  hideOrShowPasswordIcon: {
    color: theme.palette.primary.light,
  },
}));

function DefineAvatar({ name }) {
  const trimmedName = name.trim();
  const firstLetter = trimmedName[0].toUpperCase();
  const lastLetter = trimmedName.split(" ").pop()[0].toUpperCase();

  return (
    <>
      {firstLetter}
      {lastLetter}
    </>
  );
}

function addZeroToDecimal(num) {
  let numStr = num.toString();

  if (numStr.indexOf(".") !== -1) {
    let parts = numStr.split(".");

    if (parts[1].length === 1) {
      return parts[0] + "." + parts[1] + "0";
    }
  }

  return numStr;
}

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export default function UserDetails() {
  const classes = useStyles();
  const {
    cookie,
    setPage,
    setUserInfos,
    setCookie,
    removeCookie,
    setSelectedIndex,
  } = useContext(Context);

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [cep, setCEP] = useState("");
  const [street, setStreet] = useState("");
  const [number, setNumber] = useState("");
  const [neighborhood, setNeighborhood] = useState("");
  const [complement, setComplement] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [showPassConfirm, setShowPassConfirm] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [errorName, setErrorName] = useState(false);
  const [errorPhone, setErrorPhone] = useState(false);
  const [errorCity, setErrorCity] = useState(false);
  const [errorState, setErrorState] = useState(false);
  const [errorStreet, setErrorStreet] = useState(false);
  const [errorNumber, setErrorNumber] = useState(false);
  const [errorNeighborhood, setErrorNeighborhood] = useState(false);
  const [errorPassword, setErrorPassword] = useState(false);
  const [errorConfirmPass, setErrorConfirmPass] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const [showMobileContent, setShowMobileContent] = useState(false);
  const [hideLeftContainer, setHideLeftContainer] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    const newWindowDimensions = getWindowDimensions();
    function handleResize() {
      setWindowDimensions(newWindowDimensions);
    }

    newWindowDimensions.width >= 1280
      ? setShowMobileContent(false)
      : setShowMobileContent(true);

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [windowDimensions]); // eslint-disable-next-line react-hooks/exhaustive-deps

  useEffect(() => {
    const token = cookie.token.accessToken;

    Api.defaults.headers.common["x-access-token"] = token;

    setPage("Editar perfil");
    setSelectedIndex([5, "Editar perfil"]);
    setName(cookie.userInfo[0].fullName);
    setEmail(cookie.userInfo[0].email);
    setPhone(cookie.userInfo[0].phone);
    setCEP(cookie.userInfo[0].address.cep);
    setStreet(cookie.userInfo[0].address.street);
    setNeighborhood(cookie.userInfo[0].address.neighborhood);
    setCity(cookie.userInfo[0].address.city);
    setState(cookie.userInfo[0].address.state);
    setNumber(cookie.userInfo[0].address.number);
    setComplement(cookie.userInfo[0].address.complement);
    setHideLeftContainer(false);
  }, []);

  const handleClickShowPass = () => {
    setShowPass(!showPass);
  };

  const handleClickShowPassConfirm = () => {
    setShowPassConfirm(!showPassConfirm);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (password === passwordConfirm && password !== "") {
      const data = {
        _id: cookie.userInfo[0]._id,
        fullName: name.trim(),
        password: password,
        role: cookie.userInfo[0].role,
        credit: cookie.userInfo[0].credit,
        document: cookie.userInfo[0].document,
        manager: cookie.userInfo[0].manager,
        phone: phone.includes("_") ? phone.slice(0, phone.length - 1) : phone,
        email: email,
        address: {
          cep: cep,
          street: street,
          neighborhood: neighborhood,
          city: city,
          state: state,
          complement: complement,
          number: number,
        },
        status: cookie.userInfo[0].status,
        confirmationCode: cookie.userInfo[0].confirmationCode,
      };

      Api.put("/api/user", data)
        .then((res) => {
          setErrorMessage(res.data.message);
          setOpenModal(true);
        })
        .catch((err) => {
          setErrorMessage(err.response.data.message);
          setOpenModal(true);
        });

      removeCookie("userInfo");
      setCookie("userInfo", [data], { expire: 1, path: "/" });
      setUserInfos([data]);
    } else if (password === passwordConfirm && password === "") {
      const data = {
        _id: cookie.userInfo[0]._id,
        fullName: name.trim(),
        phone: phone.includes("_") ? phone.slice(0, phone.length - 1) : phone,
        email: email,
        role: cookie.userInfo[0].role,
        credit: cookie.userInfo[0].credit,
        document: cookie.userInfo[0].document,
        manager: cookie.userInfo[0].manager,
        address: {
          cep: cep,
          street: street,
          neighborhood: neighborhood,
          city: city,
          state: state,
          complement: complement,
          number: number,
        },
        status: cookie.userInfo[0].status,
        confirmationCode: cookie.userInfo[0].confirmationCode,
      };

      Api.put("/api/user", data)
        .then((res) => {
          setErrorMessage(res.data.message);
          setOpenModal(true);
        })
        .catch((err) => {
          setErrorMessage(err.response.data.message);
          setOpenModal(true);
        });

      setCookie("userInfo", [data], { expire: 1, path: "/" });
      setUserInfos([data]);
    } else {
      setErrorMessage("As senhas não coincidem");
      setOpenModal(true);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    history.push("/main");
    window.location.reload();
  };

  const handleShowRightContainer = () => {
    setShowMobileContent(!showMobileContent);
    setHideLeftContainer(!hideLeftContainer);
  };

  return (
    <div className={classes.root}>
      <Paper elevation={3} className={classes.general}>
        <Grid container>
          {!hideLeftContainer && (
            <Grid
              item
              xl={5}
              lg={5}
              md={12}
              sm={12}
              xs={12}
              className={classes.leftContainer}
            >
              <div className={classes.avatarContainer}>
                <IconButton disableRipple disableFocusRipple>
                  <div className={`${classes.avatarBorder}`}>
                    <Avatar
                      className={`${classes.avatarColor} ${classes.avatarSize}`}
                    >
                      <DefineAvatar name={cookie.userInfo[0].fullName} />
                    </Avatar>
                  </div>
                </IconButton>
                <div style={{ whiteSpace: "pre-wrap" }}>
                  <div className={classes.userName}>
                    {cookie.userInfo[0].fullName}
                  </div>
                </div>
                {showMobileContent && (
                  <Button
                    variant="outlined"
                    className={`${classes.buttonEdit}`}
                    onClick={handleShowRightContainer}
                  >
                    <EditOutlinedIcon style={{ marginRight: 10 }} />
                    Editar
                  </Button>
                )}
              </div>

              <div className={classes.divider} />

              {cookie.userInfo[0].role === "store" && (
                <>
                  <Grid container>
                    <Grid
                      item
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className={classes.infoItems}
                    >
                      <MonetizationOnOutlinedIcon
                        className={classes.infoItemsIcon}
                      />
                      <div style={{ marginLeft: 10 }}>
                        <strong>
                          Créditos<br></br>
                        </strong>{" "}
                        {addZeroToDecimal(cookie.userInfo[0].credit)}
                      </div>
                    </Grid>
                  </Grid>

                  <div className={classes.divider} />
                </>
              )}

              <Grid container>
                <Grid
                  item
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  className={classes.infoItems}
                >
                  <EmailOutlinedIcon className={classes.infoItemsIcon} />
                  <div style={{ marginLeft: 10 }}>
                    <strong>
                      E-mail:<br></br>
                    </strong>{" "}
                    {cookie.userInfo[0].email}
                  </div>
                </Grid>
              </Grid>

              <div className={classes.divider} />

              <Grid container>
                <Grid
                  item
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  className={classes.infoItems}
                >
                  <AssignmentIndOutlinedIcon
                    className={classes.infoItemsIcon}
                  />
                  <div style={{ marginLeft: 10 }}>
                    <strong>
                      {cookie.userInfo[0].role === "store" ? "CNPJ: " : "CPF:"}
                      <br></br>
                    </strong>{" "}
                    {cookie.userInfo[0].document}
                  </div>
                </Grid>
              </Grid>

              <div className={classes.divider} />

              <Grid container>
                <Grid
                  item
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  className={classes.infoItems}
                >
                  <LocalPhoneOutlinedIcon className={classes.infoItemsIcon} />
                  <div style={{ marginLeft: 10 }}>
                    <strong>
                      Contato:<br></br>
                    </strong>{" "}
                    {cookie.userInfo[0].phone}
                  </div>
                </Grid>
              </Grid>

              <div className={classes.divider} />

              <Grid container>
                <Grid
                  item
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  className={classes.infoItems}
                >
                  <SignpostOutlinedIcon className={classes.infoItemsIcon} />
                  <div style={{ marginLeft: 10 }}>
                    <strong>
                      Logradouro:<br></br>
                    </strong>{" "}
                    {cookie.userInfo[0].address.street},{" "}
                    {cookie.userInfo[0].address.number}
                    {cookie.userInfo[0].address.complement !== "" &&
                      `, ${cookie.userInfo[0].address.complement}`}
                  </div>
                </Grid>
              </Grid>

              <div className={classes.divider} />

              <Grid container>
                <Grid
                  item
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  className={classes.infoItems}
                >
                  <HomeOutlinedIcon className={classes.infoItemsIcon} />
                  <div style={{ marginLeft: 10 }}>
                    <strong>
                      Bairro:<br></br>
                    </strong>{" "}
                    {cookie.userInfo[0].address.neighborhood}
                  </div>
                </Grid>
              </Grid>

              <div className={classes.divider} />

              <Grid container>
                <Grid
                  item
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  className={classes.infoItems}
                >
                  <ApartmentOutlinedIcon className={classes.infoItemsIcon} />
                  <div style={{ marginLeft: 10 }}>
                    <strong>
                      Cidade:<br></br>
                    </strong>{" "}
                    {cookie.userInfo[0].address.city} -{" "}
                    {cookie.userInfo[0].address.state}
                  </div>
                </Grid>
              </Grid>
            </Grid>
          )}
          {!showMobileContent && (
            <Grid
              item
              xl={7}
              lg={7}
              md={12}
              sm={12}
              xs={12}
              className={classes.rightContainer}
            >
              <Typography className={classes.rightContainerTitle}>
                Editar Usuário
              </Typography>
              <form onSubmit={(e) => handleSubmit(e)}>
                <Grid container spacing={2} style={{ marginBottom: 2 }}>
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <TextField
                      id="fullName"
                      required
                      value={name || ""}
                      error={!!errorName}
                      onInvalid={(e) => {
                        setErrorName(true);
                      }}
                      onInput={(e) => {
                        setName(e.target.value);
                        setErrorName(false);
                      }}
                      InputProps={{
                        classes: {
                          input: classes.inputColor,
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          color:
                            windowDimensions.width >= 0 &&
                            windowDimensions.width <= 300
                              ? theme.palette.primary.light
                              : windowDimensions.width >= 300 &&
                                windowDimensions.width <= 600
                              ? theme.palette.primary.light
                              : windowDimensions.width >= 600 &&
                                windowDimensions.width <= 900
                              ? theme.palette.primary.light
                              : windowDimensions.width >= 900 &&
                                windowDimensions.width <= 1280
                              ? theme.palette.primary.light
                              : windowDimensions.width >= 1280 &&
                                windowDimensions.width <= 1920
                              ? theme.palette.primary.main
                              : theme.palette.primary.main,
                        },
                      }}
                      label="Nome"
                      variant="outlined"
                      className={classes.inputMaior}
                    />
                  </Grid>
                </Grid>
                {/* END NAME AND CPF FIELD CONTAINER */}
                {/* START EMAIL AND PHONE FIELD CONTAINER */}
                <Grid container spacing={2} style={{ marginBottom: 2 }}>
                  <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
                    <TextField
                      id="email"
                      value={email || ""}
                      disabled={true}
                      type="email"
                      InputProps={{
                        classes: {
                          input: classes.inputColor,
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          color:
                            windowDimensions.width >= 0 &&
                            windowDimensions.width <= 300
                              ? theme.palette.primary.light
                              : windowDimensions.width >= 300 &&
                                windowDimensions.width <= 600
                              ? theme.palette.primary.light
                              : windowDimensions.width >= 600 &&
                                windowDimensions.width <= 900
                              ? theme.palette.primary.light
                              : windowDimensions.width >= 900 &&
                                windowDimensions.width <= 1280
                              ? theme.palette.primary.light
                              : windowDimensions.width >= 1280 &&
                                windowDimensions.width <= 1920
                              ? theme.palette.primary.main
                              : theme.palette.primary.main,
                        },
                      }}
                      label="E-mail"
                      variant="outlined"
                      className={classes.inputMaior}
                    />
                  </Grid>
                  <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                    <TextField
                      id="document"
                      value={
                        DocumentFormatter.formatDocument(
                          cookie.userInfo[0].document
                        ) || ""
                      }
                      disabled={true}
                      type="document"
                      InputProps={{
                        classes: {
                          input: classes.inputColor,
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          color:
                            windowDimensions.width >= 0 &&
                            windowDimensions.width <= 300
                              ? theme.palette.primary.light
                              : windowDimensions.width >= 300 &&
                                windowDimensions.width <= 600
                              ? theme.palette.primary.light
                              : windowDimensions.width >= 600 &&
                                windowDimensions.width <= 900
                              ? theme.palette.primary.light
                              : windowDimensions.width >= 900 &&
                                windowDimensions.width <= 1280
                              ? theme.palette.primary.light
                              : windowDimensions.width >= 1280 &&
                                windowDimensions.width <= 1920
                              ? theme.palette.primary.main
                              : theme.palette.primary.main,
                        },
                      }}
                      label={
                        cookie.userInfo[0].role === "store" ? "CNPJ" : "CPF"
                      }
                      variant="outlined"
                      className={classes.inputMaior}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginBottom: 2 }}>
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <TextField
                      id="phone"
                      required
                      value={PhoneFormatter.formatPhoneNumber(phone)}
                      error={!!errorPhone}
                      onChange={(e) => {
                        setPhone(
                          PhoneFormatter.formatPhoneNumber(e.target.value)
                        );
                        setErrorPhone(false);
                      }}
                      type="phone"
                      onInvalid={() => setErrorPhone(true)}
                      InputProps={{
                        classes: {
                          input: classes.inputColor,
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          color:
                            windowDimensions.width >= 0 &&
                            windowDimensions.width <= 300
                              ? theme.palette.primary.light
                              : windowDimensions.width >= 300 &&
                                windowDimensions.width <= 600
                              ? theme.palette.primary.light
                              : windowDimensions.width >= 600 &&
                                windowDimensions.width <= 900
                              ? theme.palette.primary.light
                              : windowDimensions.width >= 900 &&
                                windowDimensions.width <= 1280
                              ? theme.palette.primary.light
                              : windowDimensions.width >= 1280 &&
                                windowDimensions.width <= 1920
                              ? theme.palette.primary.main
                              : theme.palette.primary.main,
                        },
                      }}
                      label="Telefone"
                      variant="outlined"
                      inputProps={{ maxLength: 15 }}
                      className={classes.inputMaior}
                    />
                  </Grid>
                </Grid>
                {/* END EMAIL AND PHONE FIELD CONTAINER */}

                <Grid container spacing={2} style={{ marginBottom: 2 }}>
                  <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                    <TextField
                      id="cep"
                      type="cep"
                      value={cep || ""}
                      onInput={(e) => {
                        setCEP(e.target.value);
                      }}
                      InputProps={{
                        classes: {
                          input: classes.inputColor,
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          color:
                            windowDimensions.width >= 0 &&
                            windowDimensions.width <= 300
                              ? theme.palette.primary.light
                              : windowDimensions.width >= 300 &&
                                windowDimensions.width <= 600
                              ? theme.palette.primary.light
                              : windowDimensions.width >= 600 &&
                                windowDimensions.width <= 900
                              ? theme.palette.primary.light
                              : windowDimensions.width >= 900 &&
                                windowDimensions.width <= 1280
                              ? theme.palette.primary.light
                              : windowDimensions.width >= 1280 &&
                                windowDimensions.width <= 1920
                              ? theme.palette.primary.main
                              : theme.palette.primary.main,
                        },
                      }}
                      label="CEP"
                      variant="outlined"
                      className={classes.inputMaior}
                    />
                  </Grid>

                  <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                    <TextField
                      id="city"
                      value={city || ""}
                      required
                      error={errorCity}
                      onInvalid={(e) => {
                        setErrorCity(true);
                      }}
                      onInput={(e) => {
                        setCity(e.target.value);
                        setErrorCity(false);
                      }}
                      InputProps={{
                        classes: {
                          input: classes.inputColor,
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          color:
                            windowDimensions.width >= 0 &&
                            windowDimensions.width <= 300
                              ? theme.palette.primary.light
                              : windowDimensions.width >= 300 &&
                                windowDimensions.width <= 600
                              ? theme.palette.primary.light
                              : windowDimensions.width >= 600 &&
                                windowDimensions.width <= 900
                              ? theme.palette.primary.light
                              : windowDimensions.width >= 900 &&
                                windowDimensions.width <= 1280
                              ? theme.palette.primary.light
                              : windowDimensions.width >= 1280 &&
                                windowDimensions.width <= 1920
                              ? theme.palette.primary.main
                              : theme.palette.primary.main,
                        },
                      }}
                      label="Cidade"
                      variant="outlined"
                      className={classes.inputMaior}
                    />
                  </Grid>

                  <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                    <TextField
                      id="state"
                      required
                      value={state || ""}
                      error={errorState}
                      onInvalid={(e) => {
                        setErrorState(true);
                      }}
                      onInput={(e) => {
                        e.target.setCustomValidity("");
                        setState(e.target.value);
                        setErrorState(false);
                      }}
                      InputProps={{
                        classes: {
                          input: classes.inputColor,
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          color:
                            windowDimensions.width >= 0 &&
                            windowDimensions.width <= 300
                              ? theme.palette.primary.light
                              : windowDimensions.width >= 300 &&
                                windowDimensions.width <= 600
                              ? theme.palette.primary.light
                              : windowDimensions.width >= 600 &&
                                windowDimensions.width <= 900
                              ? theme.palette.primary.light
                              : windowDimensions.width >= 900 &&
                                windowDimensions.width <= 1280
                              ? theme.palette.primary.light
                              : windowDimensions.width >= 1280 &&
                                windowDimensions.width <= 1920
                              ? theme.palette.primary.main
                              : theme.palette.primary.main,
                        },
                      }}
                      label="Estado"
                      variant="outlined"
                      className={classes.inputMaior}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2} style={{ marginBottom: 2 }}>
                  <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <TextField
                      id="neighborhood"
                      required
                      value={neighborhood || ""}
                      error={errorNeighborhood}
                      onInvalid={(e) => {
                        setErrorNeighborhood(true);
                      }}
                      onInput={(e) => {
                        setNeighborhood(e.target.value);
                        setErrorNeighborhood(false);
                      }}
                      InputProps={{
                        classes: {
                          input: classes.inputColor,
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          color:
                            windowDimensions.width >= 0 &&
                            windowDimensions.width <= 300
                              ? theme.palette.primary.light
                              : windowDimensions.width >= 300 &&
                                windowDimensions.width <= 600
                              ? theme.palette.primary.light
                              : windowDimensions.width >= 600 &&
                                windowDimensions.width <= 900
                              ? theme.palette.primary.light
                              : windowDimensions.width >= 900 &&
                                windowDimensions.width <= 1280
                              ? theme.palette.primary.light
                              : windowDimensions.width >= 1280 &&
                                windowDimensions.width <= 1920
                              ? theme.palette.primary.main
                              : theme.palette.primary.main,
                        },
                      }}
                      label="Bairro"
                      variant="outlined"
                      className={classes.inputMaior}
                    />
                  </Grid>
                  <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <TextField
                      id="street"
                      required
                      value={street || ""}
                      error={errorStreet}
                      onInvalid={(e) => {
                        setErrorStreet(true);
                      }}
                      onInput={(e) => {
                        setStreet(e.target.value);
                        setErrorStreet(false);
                      }}
                      InputProps={{
                        classes: {
                          input: classes.inputColor,
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          color:
                            windowDimensions.width >= 0 &&
                            windowDimensions.width <= 300
                              ? theme.palette.primary.light
                              : windowDimensions.width >= 300 &&
                                windowDimensions.width <= 600
                              ? theme.palette.primary.light
                              : windowDimensions.width >= 600 &&
                                windowDimensions.width <= 900
                              ? theme.palette.primary.light
                              : windowDimensions.width >= 900 &&
                                windowDimensions.width <= 1280
                              ? theme.palette.primary.light
                              : windowDimensions.width >= 1280 &&
                                windowDimensions.width <= 1920
                              ? theme.palette.primary.main
                              : theme.palette.primary.main,
                        },
                      }}
                      label="Rua"
                      variant="outlined"
                      className={classes.inputMaior}
                    />
                  </Grid>
                </Grid>

                <Grid container style={{ marginBottom: 2 }} spacing={2}>
                  <Grid item xl={5} lg={5} md={5} sm={12} xs={12}>
                    <TextField
                      id="number"
                      required
                      value={number || ""}
                      error={errorNumber}
                      onInvalid={(e) => {
                        setErrorNumber(true);
                      }}
                      onInput={(e) => {
                        setNumber(e.target.value);
                        setErrorNumber(false);
                      }}
                      InputProps={{
                        classes: {
                          input: classes.inputColor,
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          color:
                            windowDimensions.width >= 0 &&
                            windowDimensions.width <= 300
                              ? theme.palette.primary.light
                              : windowDimensions.width >= 300 &&
                                windowDimensions.width <= 600
                              ? theme.palette.primary.light
                              : windowDimensions.width >= 600 &&
                                windowDimensions.width <= 900
                              ? theme.palette.primary.light
                              : windowDimensions.width >= 900 &&
                                windowDimensions.width <= 1280
                              ? theme.palette.primary.light
                              : windowDimensions.width >= 1280 &&
                                windowDimensions.width <= 1920
                              ? theme.palette.primary.main
                              : theme.palette.primary.main,
                        },
                      }}
                      label="Número"
                      variant="outlined"
                      className={classes.inputMaior}
                    />
                  </Grid>
                  <Grid item xl={7} lg={7} md={7} sm={12} xs={12}>
                    <TextField
                      id="complement"
                      value={complement || ""}
                      onInput={(e) => {
                        setComplement(e.target.value);
                      }}
                      InputProps={{
                        classes: {
                          input: classes.inputColor,
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          color:
                            windowDimensions.width >= 0 &&
                            windowDimensions.width <= 300
                              ? theme.palette.primary.light
                              : windowDimensions.width >= 300 &&
                                windowDimensions.width <= 600
                              ? theme.palette.primary.light
                              : windowDimensions.width >= 600 &&
                                windowDimensions.width <= 900
                              ? theme.palette.primary.light
                              : windowDimensions.width >= 900 &&
                                windowDimensions.width <= 1280
                              ? theme.palette.primary.light
                              : windowDimensions.width >= 1280 &&
                                windowDimensions.width <= 1920
                              ? theme.palette.primary.main
                              : theme.palette.primary.main,
                        },
                      }}
                      label="Complemento"
                      variant="outlined"
                      className={classes.inputMaior}
                    />
                  </Grid>
                </Grid>

                {/* START PASSWORD FIELD CONTAINER */}

                <Grid container spacing={2} style={{ marginBottom: 2 }}>
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <TextField
                      id="password"
                      value={password || ""}
                      type={showPass ? "text" : "password"}
                      label="Nova Senha"
                      onInput={(e) => {
                        e.target.setCustomValidity("");
                        setErrorPassword(false);
                        setPassword(e.target.value);
                      }}
                      variant="outlined"
                      className={classes.inputMaior}
                      autoComplete="new-password"
                      InputLabelProps={{
                        style: {
                          color:
                            windowDimensions.width >= 0 &&
                            windowDimensions.width <= 300
                              ? theme.palette.primary.light
                              : windowDimensions.width >= 300 &&
                                windowDimensions.width <= 600
                              ? theme.palette.primary.light
                              : windowDimensions.width >= 600 &&
                                windowDimensions.width <= 900
                              ? theme.palette.primary.light
                              : windowDimensions.width >= 900 &&
                                windowDimensions.width <= 1280
                              ? theme.palette.primary.light
                              : windowDimensions.width >= 1280 &&
                                windowDimensions.width <= 1920
                              ? theme.palette.primary.main
                              : theme.palette.primary.main,
                        },
                      }}
                      InputProps={{
                        endAdornment: (
                          <Tooltip
                            title={
                              !showPass ? "Visualizar senha" : "Esconder Senha"
                            }
                          >
                            <InputAdornment position="end">
                              <IconButton
                                className={`${classes.hover} ${classes.hideOrShowPasswordIcon}`}
                                onClick={handleClickShowPass}
                              >
                                {showPass ? (
                                  <Visibility
                                    style={{
                                      color: errorPassword ? "red" : "24323d",
                                    }}
                                  />
                                ) : (
                                  <VisibilityOff
                                    style={{
                                      color: errorPassword ? "red" : "24323d",
                                    }}
                                  />
                                )}
                              </IconButton>
                            </InputAdornment>
                          </Tooltip>
                        ),
                        classes: {
                          input: classes.inputColor,
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <TextField
                      id="passwordConfirm"
                      value={passwordConfirm || ""}
                      error={!!errorConfirmPass}
                      type={showPassConfirm ? "text" : "password"}
                      label="Confirmar Nova Senha"
                      onInvalid={(e) => {
                        setErrorConfirmPass(true);
                      }}
                      onInput={(e) => {
                        setPasswordConfirm(e.target.value);
                        if (e.target.value !== password) {
                          setErrorConfirmPass(true);
                        } else {
                          setErrorConfirmPass(false);
                        }
                      }}
                      variant="outlined"
                      className={classes.inputMaior}
                      autoComplete="new-password"
                      InputLabelProps={{
                        style: {
                          color:
                            windowDimensions.width >= 0 &&
                            windowDimensions.width <= 300
                              ? theme.palette.primary.light
                              : windowDimensions.width >= 300 &&
                                windowDimensions.width <= 600
                              ? theme.palette.primary.light
                              : windowDimensions.width >= 600 &&
                                windowDimensions.width <= 900
                              ? theme.palette.primary.light
                              : windowDimensions.width >= 900 &&
                                windowDimensions.width <= 1280
                              ? theme.palette.primary.light
                              : windowDimensions.width >= 1280 &&
                                windowDimensions.width <= 1920
                              ? theme.palette.primary.main
                              : theme.palette.primary.main,
                        },
                      }}
                      InputProps={{
                        endAdornment: (
                          <Tooltip
                            title={
                              !showPassConfirm
                                ? "Visualizar senha"
                                : "Esconder Senha"
                            }
                          >
                            <InputAdornment position="end">
                              <IconButton
                                onClick={handleClickShowPassConfirm}
                                className={`${classes.hover} ${classes.hideOrShowPasswordIcon}`}
                              >
                                {showPassConfirm ? (
                                  <Visibility
                                    style={{
                                      color: errorPassword ? "red" : "24323d",
                                    }}
                                  />
                                ) : (
                                  <VisibilityOff
                                    style={{
                                      color: errorPassword ? "red" : "24323d",
                                    }}
                                  />
                                )}
                              </IconButton>
                            </InputAdornment>
                          </Tooltip>
                        ),
                        classes: {
                          input: classes.inputColor,
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                    />
                  </Grid>
                </Grid>
                {hideLeftContainer && (
                  <Button
                    variant="contained"
                    className={`${classes.buttonInput} ${classes.backButton}`}
                    onClick={handleShowRightContainer}
                  >
                    <ArrowBackOutlinedIcon style={{ marginRight: 10 }} />
                    voltar
                  </Button>
                )}
                <Button
                  type="submit"
                  variant="contained"
                  color="secondary"
                  className={`${classes.buttonInput}`}
                >
                  <CheckOutlinedIcon style={{ marginRight: 10 }} />
                  salvar
                </Button>
              </form>
            </Grid>
          )}
        </Grid>
      </Paper>
      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle>
          <strong>
            <em>Atenção:</em>
          </strong>
        </DialogTitle>
        <DialogContent style={{ textAlign: "center" }}>
          <Typography variant="h6" style={{ color: "gray" }}>
            {errorMessage}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseModal}
            style={{ color: "#808080" }}
            autoFocus
          >
            <strong>fechar</strong>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

import React, { useEffect, useContext, useState, useCallback } from "react";
import { Typography, Grid, CircularProgress, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import Api from "../../service";
import { Context } from "../../context";
import CouponItem from "./couponItem";

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up("xxs")]: {
      marginBottom: "30px",
      marginTop: 100,
    },
    [theme.breakpoints.up("xs")]: {
      marginBottom: "30px",
      marginTop: 100,
    },
    [theme.breakpoints.up("sm")]: {
      marginBottom: "30px",
      marginTop: 100,
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: "30px",
      marginTop: 100,
    },
    [theme.breakpoints.up("lg")]: {
      marginTop: 150,
    },
    [theme.breakpoints.up("xl")]: {
      marginTop: 150,
    },
    scrollbarWidth: "thin",
  },
  title: {
    color: theme.palette.primary.light,
    textAlign: "center",
    marginTop: 20,
  },
  campaigns: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.up("xxs")]: {
      width: "100%",
    },
    [theme.breakpoints.up("xs")]: {
      width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
    [theme.breakpoints.up("lg")]: {
      maxWidth: "70vw",
    },
    [theme.breakpoints.up("xl")]: {
      maxWidth: "70vw",
    },
  },
  campaignsSubContainer: {
    display: "flex",
    [theme.breakpoints.up("xxs")]: {
      justifyContent: "space-evenly",
    },
    [theme.breakpoints.up("xs")]: {
      justifyContent: "space-evenly",
    },
    [theme.breakpoints.up("sm")]: {
      justifyContent: "space-evenly",
    },
    [theme.breakpoints.up("md")]: {
      justifyContent: "space-evenly",
    },
    [theme.breakpoints.up("lg")]: {
      justifyContent: "center",
    },
    [theme.breakpoints.up("xl")]: {
      justifyContent: "center",
    },
  },
  motionDiv: {
    position: "relative",
    marginTop: 5,
    [theme.breakpoints.up("xxs")]: {
      paddingLeft: 0,
      paddingRight: 0,
      marginTop: 5,
    },
    [theme.breakpoints.up("xs")]: {
      paddingLeft: 0,
      paddingRight: 0,
      marginTop: 5,
    },
    [theme.breakpoints.up("sm")]: {
      paddingLeft: 0,
      paddingRight: 0,
      marginTop: 5,
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: 0,
      paddingRight: 0,
      marginTop: 5,
    },
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 10,
      paddingRight: 10,
      marginTop: 20,
    },
    [theme.breakpoints.up("xl")]: {
      paddingLeft: 10,
      paddingRight: 10,
      marginTop: 20,
    },
  },
  buttonSeeAll: {
    margin: 20,
    marginTop: 0,
    fontWeight: "bold",
    [theme.breakpoints.up("xxs")]: {
      width: "65vw",
    },
    [theme.breakpoints.up("xs")]: {
      width: "65vw",
    },
    [theme.breakpoints.up("sm")]: {
      width: "65vw",
    },
    [theme.breakpoints.up("md")]: {
      width: "65vw",
    },
    [theme.breakpoints.up("lg")]: {
      width: "25vw",
    },
    [theme.breakpoints.up("xl")]: {
      width: "25vw",
    },
  },
}));

export default function MyCoupons() {
  const classes = useStyles();
  const { cookie } = useContext(Context);
  const [loading, setLoading] = useState(false);
  const [packages, setPackages] = useState([]);
  const [page, setPage] = useState(1);
  const [itemsCounter, setItemsCounter] = useState(0);
  const [reloadUseCallback, setReloadUseCallback] = useState(false);

  const fetchPackages = useCallback(async () => {
    if (cookie.userInfo && Object.keys(cookie).length !== 0) {
      const token = cookie.token.accessToken;
      Api.defaults.headers.common["x-access-token"] = token;
    }

    if (itemsCounter < 20) {
      try {
        const res = await Api.get(
          `/api/package/detail/user?page=${page}&userId=${cookie.userInfo[0]._id}`
        );

        if (res.data.length > 0) {
          setPackages((prevPackage) => {
            const newData = res.data.filter(
              (newItem) =>
                !prevPackage.some(
                  (existingItem) => existingItem._id === newItem._id
                )
            );
            return [...prevPackage, ...newData];
          });
          setItemsCounter(itemsCounter + 1);
          setPage((prevPage) => prevPage + 1);
        }
        setLoading(false);
      } catch (error) {
        console.error("Erro ao buscar packages:", error);
      }
    }
  }, [page, reloadUseCallback]);

  useEffect(() => {
    fetchPackages();
  }, [fetchPackages, reloadUseCallback]);

  useEffect(() => {
    setLoading(true);
  }, []);

  const handleSeeMoreButton = () => {
    setItemsCounter(0);
    setReloadUseCallback(!reloadUseCallback);
    setLoading(true);
  };

  return (
    <>
      <Grid container className={classes.root}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Typography className={classes.title} variant="h5" gutterBottom>
            Meus cupons
          </Typography>
          <Typography
            className={classes.title}
            variant="body1"
            gutterBottom
            style={{ paddingLeft: 30, paddingRight: 30 }}
          >
            Não perca tempo, fale com o fornecedor e aproveite seu cupom!
          </Typography>
          <Typography
            className={classes.title}
            variant="body1"
            gutterBottom
            style={{ paddingLeft: 30, paddingRight: 30, marginBottom: 40, marginTop: 0 }}
          >
            Aqui, você encontra todo seu histórico de cupons da Cupomzito!
          </Typography>
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Grid container className={classes.campaignsSubContainer}>
            {!loading ? (
              packages.map((item, index) => (
                <div key={index} className={classes.motionDiv}>
                  <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                    <CouponItem item={item} />
                  </Grid>
                </div>
              ))
            ) : (
              <div
                style={{
                  width: "100%",
                  height: "30vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress style={{ color: "white" }} size={54} />
              </div>
            )}
            {!loading && packages.length === 0 && (
              <Typography
                variant="h6"
                style={{
                  textAlign: "center",
                  color: "white",
                  marginTop: 21,
                  paddingLeft: 10,
                  paddingRight: 10,
                }}
              >
                Ops! Você ainda não resgatou cupons.
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
      {itemsCounter >= 20 && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            variant="outlined"
            color="secondary"
            className={classes.buttonSeeAll}
            onClick={handleSeeMoreButton}
          >
            Ver mais
          </Button>
        </div>
      )}
    </>
  );
}

import React, { useState, useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  Grid,
  Box,
  Tabs,
  Tab,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Typography,
} from "@material-ui/core";

import { Context } from "../../context";
import history from "../../routes/history";
import Api from "../../service";

import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";

import CreateSeller from "./createSeller";
import GenerateCurrentTime from "../../utils/GenerateCurrentTime";

const useStyles = makeStyles((theme) => ({
  "@global": {
    "input:-webkit-autofill": {
      "-webkit-text-fill-color": "black !important",
      transition: "background-color 5000s ease-in-out 0s",
    },
    "input:-webkit-autofill:hover": {
      "-webkit-text-fill-color": "transparent",
      transition: "background-color 5000s ease-in-out 0s",
    },
    "input:-webkit-autofill:focus": {
      "-webkit-text-fill-color": "transparent",
      transition: "background-color 5000s ease-in-out 0s",
    },
    "input:-webkit-autofill:active": {
      "-webkit-text-fill-color": "transparent",
      transition: "background-color 5000s ease-in-out 0s",
    },
  },
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    justifyContent: "center",
    marginLeft: "30px",
    marginRight: "30px",
    marginTop: "100px",
    height: "85vh",
    [theme.breakpoints.up("xxs")]: {
      height: "100%",
      marginBottom: "30px",
    },
    [theme.breakpoints.up("xs")]: {
      height: "100%",
      marginBottom: "30px",
    },
    [theme.breakpoints.up("sm")]: {
      height: "85vh",
    },
    [theme.breakpoints.up("md")]: {
      height: "85vh",
    },
    [theme.breakpoints.up("lg")]: {
      height: "85vh",
    },
    [theme.breakpoints.up("xl")]: {
      height: "85vh",
    },
    scrollbarWidth: "thin",
  },
  buttonInput: {
    margin: "19px",
    height: "35px",
    width: "12vw",
    [theme.breakpoints.up("xxs")]: {
      width: "35vw",
    },
    [theme.breakpoints.up("xs")]: {
      width: "35vw",
    },
    [theme.breakpoints.up("sm")]: {
      width: "12vw",
    },
    [theme.breakpoints.up("md")]: {
      width: "12vw",
    },
    [theme.breakpoints.up("lg")]: {
      width: "12vw",
    },
    [theme.breakpoints.up("xl")]: {
      width: "12vw",
    },
    color: "white",
  },
  tabs: {
    borderRight: `1px solid rgba(0, 0, 0, .1)`,
    height: "85vh",
    [theme.breakpoints.up("xxs")]: {
      height: "100%",
      borderRight: "none",
      minWidth: "100%",
    },
    [theme.breakpoints.up("xs")]: {
      height: "100%",
      borderRight: "none",
      minWidth: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      height: "100%",
      borderRight: "none",
      minWidth: "100%",
    },
    [theme.breakpoints.up("md")]: {
      height: "100%",
      borderRight: "none",
      minWidth: "100%",
    },
    [theme.breakpoints.up("lg")]: {
      height: "85vh",
      borderRight: `1px solid rgba(0, 0, 0, .1)`,
      minWidth: "8vw",
    },
    [theme.breakpoints.up("xl")]: {
      height: "85vh",
      borderRight: `1px solid rgba(0, 0, 0, .1)`,
      minWidth: "8vw",
    },
  },
  formGrid: {
    display: "flex",
    justifyContent: "space-between",
    height: "100%",
    [theme.breakpoints.up("xxs")]: {
      width: "83vw",
      height: "100%",
      flexDirection: "column",
    },
    [theme.breakpoints.up("xs")]: {
      width: "83vw",
      height: "100%",
      flexDirection: "column",
    },
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      height: "100%",
      flexDirection: "row",
    },
    [theme.breakpoints.up("md")]: {
      width: "100%",
      height: "100%",
      flexDirection: "row",
    },
    [theme.breakpoints.up("lg")]: {
      width: "100%",
      height: "100%",
      flexDirection: "row",
    },
    [theme.breakpoints.up("xl")]: {
      width: "100%",
      height: "100%",
      flexDirection: "row",
    },
  },
  backAndConfirmButton: {
    borderLeft: `1px solid rgba(0, 0, 0, .1)`,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.up("xxs")]: {
      borderLeft: "none",
      flexDirection: "row",
      alignItems: "space-between",
    },
    [theme.breakpoints.up("xs")]: {
      borderLeft: "none",
      flexDirection: "row",
      alignItems: "space-between",
    },
    [theme.breakpoints.up("sm")]: {
      borderLeft: `1px solid rgba(0, 0, 0, .1)`,
      flexDirection: "row",
      alignItems: "space-between",
    },
    [theme.breakpoints.up("md")]: {
      borderLeft: `1px solid rgba(0, 0, 0, .1)`,
      flexDirection: "row",
      alignItems: "space-between",
    },
    [theme.breakpoints.up("lg")]: {
      borderLeft: `1px solid rgba(0, 0, 0, .1)`,
      flexDirection: "column",
      alignItems: "center",
    },
    [theme.breakpoints.up("xl")]: {
      borderLeft: `1px solid rgba(0, 0, 0, .1)`,
      flexDirection: "column",
      alignItems: "center",
    },
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

export default function CreateSellerFromPartner() {
  const {
    cookie,
    setPage,
    setOpenUsers,
    name,
    email,
    city,
    cep,
    street,
    number,
    userStatus,
    neighborhood,
    complement,
    phone,
    setIsFromEdit,
    setSelectedIndex,
    isFromEdit,
    valueEdit,
    document,
    errorDocument,
  } = useContext(Context);

  const classes = useStyles();

  const [errorMessage, setErrorMessage] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [loadingCreateView, setLoadingCreateView] = useState(true);

  useEffect(() => {
    setPage("Cadastrar Vendedor");
    setOpenUsers(true);

    const token = cookie.token.accessToken;
    Api.defaults.headers.common["x-access-token"] = token;

    setLoadingCreateView(false);
  }, []); // eslint-disable-line

  const handleCloseModal = (props) => {
    if (props === "fechar") {
      if (
        errorMessage === "Falhou! E-mail já cadastrado!" ||
        errorMessage === "As senhas não coincidem" ||
        errorMessage === "Insira um documento válido."
      ) {
        setOpenModal(false);
        setButtonDisable(true);
      } else {
        setOpenModal(false);
        setPage("Meus Vendedores");
        setSelectedIndex([8, "Meus Vendedores"]);
        history.push("/sellers");
        window.location.reload();
        setButtonDisable(false);
      }
    } else {
      setOpenModal(false);
      window.location.reload(true);
    }
  };

  const handleBack = (e) => {
    setPage("Meus Vendedores");
    setSelectedIndex([8, "Meus Vendedores"]);
    setIsFromEdit(false);
    history.push("/sellers");
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!city) {
      setErrorMessage(`O campo "cidade" é obrigatório.`);
      setOpenModal(true);
      setButtonDisable(true);
    } else if (errorDocument) {
      setErrorMessage(`Insira um documento válido.`);
      setOpenModal(true);
      setButtonDisable(true);
    } else {
      if (!isFromEdit) {
        Api.post("/api/auth/signup/partner", {
          fullName: name.trim(),
          manager: cookie.userInfo[0]._id,
          phone: phone.includes("_") ? phone.slice(0, phone.length - 1) : phone,
          email: email,
          document: document.includes("_")
            ? document.slice(0, document.length - 1)
            : document,
          address: {
            cep: cep,
            city: city.split(" / ")[0],
            state: city.split(" / ")[1],
            street: street,
            number: number,
            neighborhood: neighborhood,
            complement: complement,
          },
          role: "seller",
        })
          .then((res) => {
            registerLog(cookie.userInfo[0]._id, "partner_create_seller");
            setErrorMessage(res.data.message);
            setOpenModal(true);
          })
          .catch((err) => {
            setErrorMessage(err.response.data.message);
            setOpenModal(true);
          });
      } else {
        Api.put("/api/user", {
          _id: valueEdit,
          fullName: name.trim(),
          phone: phone.includes("_") ? phone.slice(0, phone.length - 1) : phone,
          address: {
            cep: cep,
            city: city.split(" / ")[0],
            state: city.split(" / ")[1],
            street: street,
            number: number,
            neighborhood: neighborhood,
            complement: complement,
          },
          status: userStatus,
        })
          .then((res) => {
            registerLog(valueEdit, "partner_edit_seller");
            setErrorMessage(res.data.message);
            setOpenModal(true);
          })
          .catch((err) => {
            setErrorMessage(err.response.data.message);
            setOpenModal(true);
          });
      }
    }
  };

  function registerLog(valueEdit, action) {
    Api.post("/api/log", {
      userId: cookie.userInfo[0]._id,
      action: action,
      data: `
        userId: ${valueEdit},
        fullName: ${name.trim()},
        phone: ${phone.includes("_") ? phone.slice(0, phone.length - 1) : phone}
        city: ${city.split(" / ")[0]},
        state: ${city.split(" / ")[1]},
        street: ${street},
        number: ${number},
        neighborhood: ${neighborhood},
        complement: ${complement},
      `,
      timestamp: GenerateCurrentTime.getCurrentTime(),
    })
      .then(() => {
        //success
      })
      .catch((err) => {
        console.error(err);
      });
  }

  return loadingCreateView ? (
    <Paper elevation={2} className={classes.root}>
      <Grid
        container
        style={{
          width: "78vw",
          height: "80vh",
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
        }}
      >
        <CircularProgress />
      </Grid>
    </Paper>
  ) : (
    <Paper elevation={2} className={classes.root}>
      <form onSubmit={(e) => handleSubmit(e)}>
        <div className={classes.formGrid}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={0}
            className={classes.tabs}
            indicatorColor="secondary"
            textColor="secondary"
          >
            <Tab
              id="person"
              key="person"
              value={0}
              label={
                <Grid
                  container
                  direction="row"
                  justifycontent="center"
                  alignItems="center"
                >
                  <span>Usuário</span>
                </Grid>
              }
            />
          </Tabs>

          <TabPanel value={0} index={0}>
            <CreateSeller />
          </TabPanel>

          <div className={classes.backAndConfirmButton}>
            <Button
              variant="contained"
              style={{ backgroundColor: "#4B565C", color: "white" }}
              className={`${classes.buttonInput}`}
              onClick={(e) => handleBack(e)}
            >
              <ArrowBackIosNewOutlinedIcon style={{ marginRight: 10 }} />
              Voltar
            </Button>
            <Button
              type="submit"
              disabled={isFromEdit && userStatus === "inactive"}
              variant="contained"
              color="secondary"
              className={`${classes.buttonInput}`}
            >
              <CheckOutlinedIcon style={{ marginRight: 10 }} />
              salvar
            </Button>
          </div>
        </div>
      </form>
      <Dialog
        open={openModal}
        onClose={() => handleCloseModal("value")}
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle>
          <strong>
            <em>Atenção:</em>
          </strong>
        </DialogTitle>
        <DialogContent style={{ textAlign: "center" }}>
          <Typography variant="h6" style={{ color: "gray" }}>
            {errorMessage}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleCloseModal("fechar")}
            style={{ color: "#808080" }}
            autoFocus
          >
            <strong>fechar</strong>
          </Button>
          {!isFromEdit && (
            <Button
              onClick={() => handleCloseModal("outro")}
              color="secondary"
              autoFocus
              disabled={buttonDisable}
            >
              <strong>Criar outro vendedor</strong>
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </Paper>
  );
}

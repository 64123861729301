import React, { useContext, useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  IconButton,
  TableCell,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Grid,
  Tooltip,
  CircularProgress,
  Typography,
} from "@material-ui/core";

import MenuBookOutlinedIcon from "@material-ui/icons/MenuBookOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import DoneAllOutlinedIcon from "@mui/icons-material/DoneAllOutlined";
import BlockIcon from "@mui/icons-material/Block";
import CheckIcon from "@mui/icons-material/Check";
import CheckroomOutlinedIcon from "@mui/icons-material/CheckroomOutlined";
import LocalHospitalOutlinedIcon from "@mui/icons-material/LocalHospitalOutlined";
import AutoAwesomeOutlinedIcon from "@mui/icons-material/AutoAwesomeOutlined";
import RestaurantOutlinedIcon from "@mui/icons-material/RestaurantOutlined";
import CottageOutlinedIcon from "@mui/icons-material/CottageOutlined";
import PetsOutlinedIcon from "@mui/icons-material/PetsOutlined";
import AppShortcutOutlinedIcon from "@mui/icons-material/AppShortcutOutlined";
import DirectionsCarOutlinedIcon from "@mui/icons-material/DirectionsCarOutlined";
import ChildFriendlyOutlinedIcon from "@mui/icons-material/ChildFriendlyOutlined";
import Diversity1OutlinedIcon from "@mui/icons-material/Diversity1Outlined";

import { Context } from "../../../context";
import Api from "../../../service";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    justifyContent: "center",
    marginLeft: "30px",
    marginRight: "30px",
    marginTop: "100px",
    height: "85vh",
    [theme.breakpoints.up("xxs")]: {
      height: "100%",
      marginBottom: "30px",
    },
    [theme.breakpoints.up("xs")]: {
      height: "100%",
      marginBottom: "30px",
    },
    [theme.breakpoints.up("sm")]: {
      minHeight: "85vh",
      height: "100%",
    },
    [theme.breakpoints.up("md")]: {
      minHeight: "85vh",
      height: "100%",
    },
    [theme.breakpoints.up("lg")]: {
      minHeight: "85vh",
      height: "100%",
    },
    [theme.breakpoints.up("xl")]: {
      minHeight: "85vh",
      height: "100%",
    },
    scrollbarWidth: "thin",
  },
  general: {
    display: "flex",
    width: "90vw",
    [theme.breakpoints.up("xxs")]: {
      width: "80vw",
    },
    [theme.breakpoints.up("xs")]: {
      width: "80vw",
    },
    [theme.breakpoints.up("sm")]: {
      width: "90vw",
    },
    [theme.breakpoints.up("md")]: {
      width: "90vw",
    },
    [theme.breakpoints.up("lg")]: {
      width: "90vw",
    },
    [theme.breakpoints.up("xl")]: {
      width: "90vw",
    },
  },
  delete: {
    marginRight: 30,
  },
  modalDialog: {
    textAlign: "left",
  },
  modalText: {
    color: "gray",
    fontSize: "18px",
    fontWeight: "bold",
  },
  large: {
    height: 30,
    width: 30,
    fontSize: 15,
    marginTop: 0,
    textAlign: "center",
  },
  fontIcon: {
    fontSize: 28,
  },
  hover: {
    "&:hover": {
      color: "#211f58",
    },
  },
  input: {
    width: "90%",
    marginBottom: "30px",
  },
  hideAvatar: {
    [theme.breakpoints.up("xxs")]: {
      visibility: "visible",
      margin: "5px 10px 5px 5px",
    },
    [theme.breakpoints.up("xs")]: {
      visibility: "visible",
      margin: "5px 10px 5px 5px",
    },
    [theme.breakpoints.up("sm")]: {
      visibility: "visible",
      marginRight: 15,
    },
    [theme.breakpoints.up("md")]: {
      visibility: "visible",
      marginRight: 15,
    },
    [theme.breakpoints.up("lg")]: {
      visibility: "visible",
      marginRight: 15,
    },
    [theme.breakpoints.up("xl")]: {
      visibility: "visible",
      marginRight: 15,
    },
  },
  userNameRow: {
    display: "flex",
    alignItems: "center",
  },
  divider: {
    borderBottom: "1px solid #cbcbcb",
    marginBottom: 10,
    marginTop: 10,
    width: "100%",
  },
}));

const ConsultClients = () => {
  const { cookie, setPage, setSelectedIndex } = useContext(Context);

  const classes = useStyles();

  const [campaignList, setCampaignList] = useState([]);
  const [loadingCampaign, setLoadingCampaign] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [loadingSelectedCampaign, setLoadingSelectedCampaign] = useState(false);

  var newCampaignList;

  const handleSeeCampaign = (id) => {
    const selectedPackage = campaignList.filter((item) => item._id === id);
    setSelectedCampaign(selectedPackage);
    setLoadingSelectedCampaign(true);

    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  // Code snippet that avoid warning in MUIDataTable under 4.1.12v
  const oldRender = TableCell.render;

  TableCell.render = function (...args) {
    const [props, ...otherArgs] = args;
    if (typeof props === "object" && props && "isEmpty" in props) {
      const { isEmpty, ...propsWithoutEmpty } = props;
      return oldRender.apply(this, [propsWithoutEmpty, ...otherArgs]);
    } else {
      return oldRender.apply(this, args);
    }
  };
  // Code snippet that avoid warning in MUIDataTable under 4.1.12v

  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      fontSize: "1.2rem",
    },
  }))(TableCell);

  useEffect(() => {
    const token = cookie.token.accessToken;

    Api.defaults.headers.common["x-access-token"] = token;

    setPage("Consultar Clientes");
    setSelectedIndex([6, "Consultar Clientes"]);
    setSelectedCampaign(null);
    setOpenDialog(false);
    setLoadingSelectedCampaign(false);

    Api.get("/api/package/list/byStore", {
      params: {
        storeId: cookie.userInfo[0]._id,
      },
    })
      .then((res) => {
        const newUpdatedCampaignList =
          res.data.length > 0
            ? res.data.map((campaign) => {
                return {
                  status: campaign.status,
                  _id: campaign._id,
                  storeId: campaign.storeId,
                  campaignCategory:
                    campaign.campaignId.category === "health"
                      ? "Saúde"
                      : campaign.campaignId.category === "beauty"
                      ? "Beleza"
                      : campaign.campaignId.category === "cloth"
                      ? "Vestuário"
                      : campaign.campaignId.category === "food"
                      ? "Comida"
                      : campaign.campaignId.category === "home"
                      ? "Casa"
                      : campaign.campaignId.category === "pets"
                      ? "Pets"
                      : campaign.campaignId.category === "electronic"
                      ? "Eletrônicos"
                      : campaign.campaignId.category === "automotive"
                      ? "Automotivo"
                      : campaign.campaignId.category === "kid"
                      ? "Kids"
                      : campaign.campaignId.category === "book"
                      ? "Livraria"
                      : "Diversos",
                  campaignId: campaign.campaignId._id,
                  campaignCity: `${campaign.campaignId.city} / ${campaign.campaignId.state}`,
                  campaignDescription: campaign.campaignId.description,
                  campaignFullName: campaign.campaignId.fullName,
                  userId: campaign.userId._id,
                  userCity: `${campaign.userId.address.city} / ${campaign.userId.address.state}`,
                  userFullName: campaign.userId.fullName,
                  userPhone: campaign.userId.phone,
                  userEmail: campaign.userId.email,
                };
              })
            : res.data;

        setCampaignList(newUpdatedCampaignList);
        setLoadingCampaign(false);
      })
      .catch((err) => {
        console.error(err);
        setLoadingCampaign(false);
      });
  }, []); // eslint-disable-line

  newCampaignList = campaignList && campaignList;

  var countStatus = 0;
  var totalRows = 0;

  newCampaignList &&
    newCampaignList.forEach((data) => {
      totalRows += 1;
      data.status === "inactive" && (countStatus += 1);
    });

  const columns = [
    {
      name: "_id",
      label: "ID",
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "status",
      label: "status",
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "userId",
      label: "userId",
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "userId",
      label: "userId",
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "userFullName",
      label: "Cliente",
      options: {
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return (
            <StyledTableCell key={columnMeta.index}>
              {columnMeta.label}
            </StyledTableCell>
          );
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Grid
                item
                xs={10}
                style={{
                  marginTop: 5,
                  color: tableMeta.rowData[1] === "inactive" ? "gray" : "black",
                }}
              >
                {value}
              </Grid>
            </>
          );
        },
      },
    },
    {
      name: "userPhone",
      label: "Contato",
      options: {
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return (
            <StyledTableCell key={columnMeta.index}>
              {columnMeta.label}
            </StyledTableCell>
          );
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Grid
                item
                xs={10}
                style={{
                  marginTop: 5,
                  color: tableMeta.rowData[1] === "inactive" ? "gray" : "black",
                }}
              >
                {value}
              </Grid>
            </>
          );
        },
      },
    },
    {
      name: "userEmail",
      label: "E-mail",
      options: {
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return (
            <StyledTableCell key={columnMeta.index}>
              {columnMeta.label}
            </StyledTableCell>
          );
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Grid
                item
                xs={10}
                style={{
                  marginTop: 5,
                  color: tableMeta.rowData[1] === "inactive" ? "gray" : "black",
                }}
              >
                {value}
              </Grid>
            </>
          );
        },
      },
    },
    {
      name: "userCity",
      label: "Cidade do Cliente",
      options: {
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return (
            <StyledTableCell key={columnMeta.index}>
              {columnMeta.label}
            </StyledTableCell>
          );
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Grid
                item
                xs={10}
                style={{
                  marginTop: 5,
                  color: tableMeta.rowData[1] === "inactive" ? "gray" : "black",
                }}
              >
                {value}
              </Grid>
            </>
          );
        },
      },
    },
    {
      name: "campaignFullName",
      label: "Campanha",
      options: {
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return (
            <StyledTableCell key={columnMeta.index}>
              {columnMeta.label}
            </StyledTableCell>
          );
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Grid
                item
                xs={10}
                style={{
                  marginTop: 5,
                  color: tableMeta.rowData[1] === "inactive" ? "gray" : "black",
                }}
              >
                {value}
              </Grid>
            </>
          );
        },
      },
    },
    {
      name: "campaignCity",
      label: "Cidade da Campanha",
      options: {
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return (
            <StyledTableCell key={columnMeta.index}>
              {columnMeta.label}
            </StyledTableCell>
          );
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Grid
                item
                xs={10}
                style={{
                  marginTop: 5,
                  color: tableMeta.rowData[1] === "inactive" ? "gray" : "black",
                }}
              >
                {value}
              </Grid>
            </>
          );
        },
      },
    },
    {
      name: "campaignCategory",
      label: "Categoria",
      options: {
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return (
            <StyledTableCell key={columnMeta.index}>
              {columnMeta.label}
            </StyledTableCell>
          );
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Grid
              item
              xs={10}
              style={{
                marginTop: 5,
                color: tableMeta.rowData[1] === "inactive" ? "gray" : "black",
                display: "flex",
                alignItems: "center",
                alignContent: "center",
              }}
            >
              {value === "Saúde" ? (
                <LocalHospitalOutlinedIcon />
              ) : value === "Beleza" ? (
                <AutoAwesomeOutlinedIcon />
              ) : value === "Vestuário" ? (
                <CheckroomOutlinedIcon />
              ) : value === "Comida" ? (
                <RestaurantOutlinedIcon />
              ) : value === "Casa" ? (
                <CottageOutlinedIcon />
              ) : value === "Pets" ? (
                <PetsOutlinedIcon />
              ) : value === "Eletrônicos" ? (
                <AppShortcutOutlinedIcon />
              ) : value === "Automotivo" ? (
                <DirectionsCarOutlinedIcon />
              ) : value === "Kids" ? (
                <ChildFriendlyOutlinedIcon />
              ) : value === "Livraria" ? (
                <MenuBookOutlinedIcon />
              ) : (
                <Diversity1OutlinedIcon />
              )}
              <div style={{ marginLeft: 10, marginTop: 3 }}>{value}</div>
            </Grid>
          );
        },
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return (
            <StyledTableCell key={columnMeta.index}>
              {columnMeta.label}
            </StyledTableCell>
          );
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Grid
              item
              xs={10}
              style={{
                marginTop: 5,
                color: tableMeta.rowData[1] === "inactive" ? "gray" : "black",
              }}
            >
              {value === "active" ? (
                <Typography style={{ display: "flex", alignItems: "center" }}>
                  <CheckIcon
                    style={{
                      color: "green",
                      marginRight: 10,
                    }}
                  />
                  Resgatado
                </Typography>
              ) : value === "used" ? (
                <Typography style={{ display: "flex", alignItems: "center" }}>
                  <DoneAllOutlinedIcon
                    style={{
                      color: "green",
                      marginRight: 10,
                    }}
                  />
                  Consumido
                </Typography>
              ) : (
                <Typography style={{ display: "flex", alignItems: "center" }}>
                  <BlockIcon
                    style={{
                      color: "gray",
                      marginRight: 10,
                    }}
                  />
                  Desativado
                </Typography>
              )}
            </Grid>
          );
        },
      },
    },
    {
      name: "open",
      label: "Editar",
      options: {
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return <StyledTableCell key={columnMeta.index}></StyledTableCell>;
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Grid container>
                <Grid item xs={10}>
                  <IconButton
                    onClick={() => handleSeeCampaign(tableMeta.rowData[0])}
                  >
                    <Tooltip title="Ver Detalhes">
                      <RemoveRedEyeOutlinedIcon
                        className={classes.fontIcon}
                        style={{
                          color:
                            tableMeta.rowData[1] === "inactive"
                              ? "gray"
                              : "#ffa101",
                        }}
                      />
                    </Tooltip>
                  </IconButton>
                </Grid>
              </Grid>
            </>
          );
        },
      },
    },
  ];

  return loadingCampaign ? (
    <Grid
      container
      style={{
        width: "78vw",
        height: "80vh",
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
      }}
    >
      <CircularProgress />
    </Grid>
  ) : (
    <div>
      <Paper elevation={2} className={classes.root}>
        <Paper elevation={0} className={classes.general}>
          <div style={{ width: "1000vw" }}>
            <MUIDataTable
              id={"table"}
              title={"Clientes"}
              data={newCampaignList}
              pagination="true"
              columns={columns}
              options={{
                sort: true,
                rowsPerPage: 10,
                rowsPerPageOptions: [5, 10, 15, 20],
                filter: false,
                download: false,
                responsive: "simple",
                selectableRowsOnClick: false,
                selectableRows: "none",
                print: true,
                viewColumns: true,
                elevation: 0,
                textLabels: {
                  body: {
                    noMatch: "Nenhuma campanha encontrada!",
                    toolTip: "Ordenar",
                    columnHeaderTooltip: (column) =>
                      `Ordenar por ${column.label}`,
                  },
                  pagination: {
                    next: "Próxima página",
                    previous: "Página anterior",
                    rowsPerPage: "Linhas por página:",
                    displayRows: "de",
                  },
                  toolbar: {
                    search: "Procurar",
                    viewColumns: "Filtrar colunas",
                    print: "Imprimir tabela",
                  },
                  viewColumns: {
                    title: "Filtrar colunas",
                  },
                  selectedRows: {
                    text: "linha(s) selecionada(s)",
                  },
                },
              }}
            />
          </div>
        </Paper>
      </Paper>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle>
          <strong>
            <em>Informações da Campanha Resgatada</em>
          </strong>
        </DialogTitle>
        <DialogContent style={{ textAlign: "center" }}>
          {!loadingSelectedCampaign ? (
            <CircularProgress />
          ) : (
            <Grid
              container
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignContent: "flex-start",
                alignItems: "flex-start",
              }}
            >
              <Typography
                variant="body2"
                style={{ fontWeight: "bold" }}
                gutterBottom
              >
                Cliente:
              </Typography>
              <Typography variant="body1">
                {selectedCampaign[0].userFullName}
              </Typography>

              <div className={classes.divider} />

              <Typography
                variant="body2"
                style={{ fontWeight: "bold" }}
                gutterBottom
              >
                Contato do Cliente:
              </Typography>
              <Typography variant="body1">
                {selectedCampaign[0].userPhone}
              </Typography>

              <div className={classes.divider} />

              <Typography
                variant="body2"
                style={{ fontWeight: "bold" }}
                gutterBottom
              >
                E-mail do Cliente:
              </Typography>
              <Typography variant="body1">
                {selectedCampaign[0].userEmail}
              </Typography>

              <div className={classes.divider} />

              <Typography
                variant="body2"
                style={{ fontWeight: "bold" }}
                gutterBottom
              >
                Cidade do Cliente:
              </Typography>
              <Typography variant="body1">
                {selectedCampaign[0].userCity}
              </Typography>

              <div className={classes.divider} />

              <Typography
                variant="body2"
                style={{ fontWeight: "bold" }}
                gutterBottom
              >
                Campanha:
              </Typography>
              <Typography variant="body1">
                {selectedCampaign[0].campaignFullName}
              </Typography>

              <div className={classes.divider} />

              <Typography
                variant="body2"
                style={{ fontWeight: "bold" }}
                gutterBottom
              >
                Cidade da Campanha:
              </Typography>
              <Typography variant="body1">
                {selectedCampaign[0].campaignCity}
              </Typography>

              <div className={classes.divider} />

              <Typography
                variant="body2"
                style={{ fontWeight: "bold" }}
                gutterBottom
              >
                Descrição da Campanha:
              </Typography>
              <Typography variant="body1">
                {selectedCampaign[0].campaignDescription}
              </Typography>

              <div className={classes.divider} />

              <Typography
                variant="body2"
                style={{ fontWeight: "bold" }}
                gutterBottom
              >
                Categoria da Campanha:
              </Typography>
              <Typography
                variant="body1"
                style={{ display: "flex", alignItems: "center" }}
              >
                {selectedCampaign[0].campaignCategory === "Saúde" ? (
                  <LocalHospitalOutlinedIcon />
                ) : selectedCampaign[0].campaignCategory === "Beleza" ? (
                  <AutoAwesomeOutlinedIcon />
                ) : selectedCampaign[0].campaignCategory === "Vestuário" ? (
                  <CheckroomOutlinedIcon />
                ) : selectedCampaign[0].campaignCategory === "Comida" ? (
                  <RestaurantOutlinedIcon />
                ) : selectedCampaign[0].campaignCategory === "Casa" ? (
                  <CottageOutlinedIcon />
                ) : selectedCampaign[0].campaignCategory === "Pets" ? (
                  <PetsOutlinedIcon />
                ) : selectedCampaign[0].campaignCategory === "Eletrônicos" ? (
                  <AppShortcutOutlinedIcon />
                ) : selectedCampaign[0].campaignCategory === "Automotivo" ? (
                  <DirectionsCarOutlinedIcon />
                ) : selectedCampaign[0].campaignCategory === "Kids" ? (
                  <ChildFriendlyOutlinedIcon />
                ) : selectedCampaign[0].campaignCategory === "Livraria" ? (
                  <MenuBookOutlinedIcon />
                ) : (
                  <Diversity1OutlinedIcon />
                )}
                <span style={{ marginLeft: 10 }}>
                  {selectedCampaign[0].campaignCategory}
                </span>
              </Typography>

              <div className={classes.divider} />

              <Typography
                variant="body2"
                style={{ fontWeight: "bold" }}
                gutterBottom
              >
                Status do Cupom:
              </Typography>

              {selectedCampaign[0].status === "active" ? (
                <Typography
                  variant="body1"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <CheckIcon
                    style={{
                      color: "green",
                      marginRight: 10,
                    }}
                  />
                  Resgatado
                </Typography>
              ) : selectedCampaign[0].status === "used" ? (
                <Typography
                  variant="body1"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <DoneAllOutlinedIcon
                    style={{
                      color: "green",
                      marginRight: 10,
                    }}
                  />
                  Consumido
                </Typography>
              ) : (
                <Typography
                  variant="body1"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <BlockIcon
                    style={{
                      color: "gray",
                      marginRight: 10,
                    }}
                  />
                  Desativado
                </Typography>
              )}
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} style={{ color: "#808080" }}>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ConsultClients;

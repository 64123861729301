import { useState } from "react";

export default function StoreUserCampaignHook() {
  const [campaignList, setCampaignList] = useState("");
  const [campaignName, setCampaignName] = useState("");
  const [campaignCouponsAvailable, setCampaignCouponsAvailable] = useState("");
  const [campaignContact, setCampaignContact] = useState("");
  const [campaignBanner, setCampaignBanner] = useState("default");
  const [campaignCategory, setCampaignCategory] = useState("");
  const [campaignDescription, setCampaignDescription] = useState("");
  const [errorCampaignName, setErrorCampaignName] = useState(false);
  const [errorCampaignCouponsAvailable, setErrorCampaignCouponsAvailable] =
    useState(false);
  const [errorCampaignContact, setErrorCampaignContact] = useState(false);
  const [errorCampaignDescription, setErrorCampaignDescription] =
    useState(false);
  const [campaignStatus, setCampaignStatus] = useState("active");
  const [campaignCity, setCampaignCity] = useState("");
  const [loadingCampaign, setLoadingCampaign] = useState(true);
  const [isFromCreateCampaign, setIsFromCreateCampaign] = useState(false);
  const [isFromEditCampaign, setIsFromEditCampaign] = useState(false);
  const [isLoadingCampaign, setIsLoadingCampaign] = useState(false);

  return {
    campaignList,
    setCampaignList,
    loadingCampaign,
    setLoadingCampaign,
    campaignName,
    campaignStatus,
    setCampaignStatus,
    setCampaignName,
    campaignCouponsAvailable,
    setCampaignCouponsAvailable,
    campaignContact,
    setCampaignContact,
    campaignBanner,
    setCampaignBanner,
    campaignCity,
    setCampaignCity,
    campaignCategory,
    setCampaignCategory,
    campaignDescription,
    setCampaignDescription,
    errorCampaignName,
    setErrorCampaignName,
    errorCampaignCouponsAvailable,
    setErrorCampaignCouponsAvailable,
    errorCampaignContact,
    setErrorCampaignContact,
    errorCampaignDescription,
    setErrorCampaignDescription,
    isFromCreateCampaign,
    setIsFromCreateCampaign,
    isFromEditCampaign,
    setIsFromEditCampaign,
    isLoadingCampaign,
    setIsLoadingCampaign,
  };
}

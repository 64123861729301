import React, { createContext } from "react";

import useAuthHook from "./hooks/authHook";
import selectedPageHook from "./hooks/selectedPageHook";
import userHook from "./hooks/userHook";
import editHook from "./hooks/editHook";
import packageHook from "./hooks/packageHook";
import storeUserCampaignHook from "./hooks/storeUserCampaignHook";
import loginHook from "./hooks/loginHook";
import clientHook from "./hooks/clientHook";

const Context = createContext();

function ContextHooks({ children }) {
  const {
    authenticated,
    userInfos,
    loading,
    setLoading,
    handleLogin,
    handleLogout,
    setErrorMsg,
    errorMsg,
    cookie,
    setCookie,
    removeCookie,
    setUserInfos,
  } = useAuthHook();

  const {
    setSelectedIndex,
    selectedIndex,
    page,
    setPage,
    barLeftOpen,
    setBarLeftOpen,
    openUsers,
    setOpenUsers,
    openPackage,
    setOpenPackage,
    openCampaign,
    setOpenCampaign,
    tabIndex,
    setTabIndex,
  } = selectedPageHook();

  const {
    userList,
    setUserList,
    showPassConfirm,
    setShowPassConfirm,
    loadingUser,
    setLoadingUser,
    name,
    userStatus,
    setUserStatus,
    setName,
    city,
    setCity,
    cep,
    setCEP,
    street,
    setStreet,
    number,
    setNumber,
    neighborhood,
    setNeighborhood,
    complement,
    setComplement,
    email,
    setEmail,
    phone,
    setPhone,
    userRole,
    setUserRole,
    password,
    setPassword,
    passwordConfirm,
    setPasswordConfirm,
    errorName,
    setErrorName,
    errorStreet,
    setErrorStreet,
    errorNumber,
    setErrorNumber,
    errorNeighborhood,
    setErrorNeighborhood,
    errorEmail,
    setErrorEmail,
    errorPhone,
    setErrorPhone,
    errorPassword,
    setErrorPassword,
    errorConfirmPass,
    setErrorConfirmPass,
    showPass,
    setShowPass,
    isFromHome,
    setIsFromHome,
    document,
    setDocument,
    errorDocument,
    setErrorDocument,
    credits,
    setCredits,
    availableManagers,
    setAvailableManagers,
    manager,
    setManager,
  } = userHook();

  const { valueEdit, setValueEdit, isFromEdit, setIsFromEdit } = editHook();

  const {
    clientBarLeftOpen,
    setClientBarLeftOpen,
    cityFilter,
    setCityFilter,
    isFromMyProfile,
    setIsFromMyProfile,
  } = clientHook();

  const {
    loadingLogin,
    setLoadingLogin,
    isForgetPasswordVisible,
    setIsForgetPasswordVisible,
  } = loginHook();

  const {
    packageList,
    setPackageList,
    loadingPackage,
    setLoadingPackage,
    packageUserId,
    packageStatus,
    setPackageStatus,
    setPackageUserId,
    filteredStore,
    setFilteredStore,
    packageCouponValue,
    setPackageCouponValue,
    packageIsApproved,
    setPackageIsApproved,
    errorPackageUserId,
    setErrorPackageUserId,
    errorPackageCouponValue,
    setErrorPackageCouponValue,
  } = packageHook();

  const {
    campaignList,
    setCampaignList,
    loadingCampaign,
    setLoadingCampaign,
    campaignName,
    campaignStatus,
    setCampaignStatus,
    setCampaignName,
    campaignCouponsAvailable,
    setCampaignCouponsAvailable,
    campaignContact,
    setCampaignContact,
    campaignBanner,
    setCampaignBanner,
    campaignCategory,
    setCampaignCategory,
    campaignDescription,
    setCampaignDescription,
    errorCampaignName,
    setErrorCampaignName,
    errorCampaignCouponsAvailable,
    setErrorCampaignCouponsAvailable,
    errorCampaignContact,
    setErrorCampaignContact,
    errorCampaignDescription,
    setErrorCampaignDescription,
    isFromCreateCampaign,
    setIsFromCreateCampaign,
    isFromEditCampaign,
    setIsFromEditCampaign,
    campaignCity,
    setCampaignCity,
    isLoadingCampaign,
    setIsLoadingCampaign,
  } = storeUserCampaignHook();

  return (
    <Context.Provider
      value={{
        authenticated,
        userInfos,
        loading,
        setLoading,
        handleLogin,
        handleLogout,
        setErrorMsg,
        errorMsg,
        cookie,
        setCookie,
        removeCookie,
        setUserInfos,
        setSelectedIndex,
        selectedIndex,
        page,
        setPage,
        barLeftOpen,
        setBarLeftOpen,
        openUsers,
        setOpenUsers,
        openPackage,
        setOpenPackage,
        openCampaign,
        setOpenCampaign,
        isFromEditCampaign,
        setIsFromEditCampaign,
        tabIndex,
        setTabIndex,
        userList,
        setUserList,
        showPassConfirm,
        setShowPassConfirm,
        loadingUser,
        setLoadingUser,
        name,
        userStatus,
        setUserStatus,
        setName,
        city,
        setCity,
        cep,
        setCEP,
        street,
        setStreet,
        number,
        setNumber,
        neighborhood,
        setNeighborhood,
        complement,
        setComplement,
        email,
        setEmail,
        phone,
        setPhone,
        userRole,
        setUserRole,
        password,
        setPassword,
        passwordConfirm,
        setPasswordConfirm,
        errorName,
        setErrorName,
        errorStreet,
        setErrorStreet,
        errorNumber,
        setErrorNumber,
        errorNeighborhood,
        setErrorNeighborhood,
        errorEmail,
        setErrorEmail,
        errorPhone,
        setErrorPhone,
        errorPassword,
        setErrorPassword,
        errorConfirmPass,
        setErrorConfirmPass,
        showPass,
        setShowPass,
        isFromHome,
        setIsFromHome,
        document,
        setDocument,
        errorDocument,
        setErrorDocument,
        valueEdit,
        setValueEdit,
        isFromEdit,
        setIsFromEdit,
        clientBarLeftOpen,
        setClientBarLeftOpen,
        cityFilter,
        setCityFilter,
        isFromMyProfile,
        setIsFromMyProfile,
        isForgetPasswordVisible,
        setIsForgetPasswordVisible,
        loadingLogin,
        setLoadingLogin,
        packageList,
        setPackageList,
        loadingPackage,
        setLoadingPackage,
        packageUserId,
        packageStatus,
        setPackageStatus,
        setPackageUserId,
        filteredStore,
        setFilteredStore,
        packageCouponValue,
        setPackageCouponValue,
        packageIsApproved,
        setPackageIsApproved,
        errorPackageUserId,
        setErrorPackageUserId,
        errorPackageCouponValue,
        setErrorPackageCouponValue,
        campaignList,
        setCampaignList,
        loadingCampaign,
        setLoadingCampaign,
        campaignName,
        campaignStatus,
        setCampaignStatus,
        setCampaignName,
        campaignCouponsAvailable,
        setCampaignCouponsAvailable,
        campaignContact,
        setCampaignContact,
        campaignBanner,
        setCampaignBanner,
        campaignCategory,
        setCampaignCategory,
        campaignDescription,
        setCampaignDescription,
        errorCampaignName,
        setErrorCampaignName,
        errorCampaignCouponsAvailable,
        setErrorCampaignCouponsAvailable,
        errorCampaignContact,
        setErrorCampaignContact,
        errorCampaignDescription,
        setErrorCampaignDescription,
        isFromCreateCampaign,
        setIsFromCreateCampaign,
        campaignCity,
        setCampaignCity,
        isLoadingCampaign,
        setIsLoadingCampaign,
        credits,
        setCredits,
        availableManagers,
        setAvailableManagers,
        manager,
        setManager,
      }}
    >
      {children}
    </Context.Provider>
  );
}

export { Context, ContextHooks };

import React, { useState, useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  Grid,
  Box,
  Tabs,
  Tab,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  CardMedia,
  Typography,
  TextField,
} from "@material-ui/core";

import { Context } from "../../context";
import history from "../../routes/history";
import Api2 from "../../service";
import Api from "../../service/gatewayApi";

import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import PixOutlinedIcon from "@mui/icons-material/PixOutlined";

import { motion } from "framer-motion";

import CreateStore from "./createStore";
import GenerateCurrentTime from "../../utils/GenerateCurrentTime";

const useStyles = makeStyles((theme) => ({
  "@global": {
    "input:-webkit-autofill": {
      "-webkit-text-fill-color": "black !important",
      transition: "background-color 5000s ease-in-out 0s",
    },
    "input:-webkit-autofill:hover": {
      "-webkit-text-fill-color": "transparent",
      transition: "background-color 5000s ease-in-out 0s",
    },
    "input:-webkit-autofill:focus": {
      "-webkit-text-fill-color": "transparent",
      transition: "background-color 5000s ease-in-out 0s",
    },
    "input:-webkit-autofill:active": {
      "-webkit-text-fill-color": "transparent",
      transition: "background-color 5000s ease-in-out 0s",
    },
  },
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    justifyContent: "center",
    marginLeft: "30px",
    marginRight: "30px",
    marginTop: "100px",
    height: "85vh",
    [theme.breakpoints.up("xxs")]: {
      height: "100%",
      marginBottom: "30px",
    },
    [theme.breakpoints.up("xs")]: {
      height: "100%",
      marginBottom: "30px",
    },
    [theme.breakpoints.up("sm")]: {
      height: "85vh",
    },
    [theme.breakpoints.up("md")]: {
      height: "85vh",
    },
    [theme.breakpoints.up("lg")]: {
      height: "85vh",
    },
    [theme.breakpoints.up("xl")]: {
      height: "85vh",
    },
    scrollbarWidth: "thin",
  },
  buttonInput: {
    margin: "19px",
    height: "35px",
    width: "12vw",
    [theme.breakpoints.up("xxs")]: {
      width: "35vw",
    },
    [theme.breakpoints.up("xs")]: {
      width: "35vw",
    },
    [theme.breakpoints.up("sm")]: {
      width: "12vw",
    },
    [theme.breakpoints.up("md")]: {
      width: "12vw",
    },
    [theme.breakpoints.up("lg")]: {
      width: "12vw",
    },
    [theme.breakpoints.up("xl")]: {
      width: "12vw",
    },
    color: "white",
  },
  tabs: {
    borderRight: `1px solid rgba(0, 0, 0, .1)`,
    height: "85vh",
    [theme.breakpoints.up("xxs")]: {
      height: "100%",
      borderRight: "none",
      minWidth: "100%",
    },
    [theme.breakpoints.up("xs")]: {
      height: "100%",
      borderRight: "none",
      minWidth: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      height: "100%",
      borderRight: "none",
      minWidth: "100%",
    },
    [theme.breakpoints.up("md")]: {
      height: "100%",
      borderRight: "none",
      minWidth: "100%",
    },
    [theme.breakpoints.up("lg")]: {
      height: "85vh",
      borderRight: `1px solid rgba(0, 0, 0, .1)`,
      minWidth: "8vw",
    },
    [theme.breakpoints.up("xl")]: {
      height: "85vh",
      borderRight: `1px solid rgba(0, 0, 0, .1)`,
      minWidth: "8vw",
    },
  },
  formGrid: {
    display: "flex",
    justifyContent: "space-between",
    height: "100%",
    [theme.breakpoints.up("xxs")]: {
      width: "83vw",
      height: "100%",
      flexDirection: "column",
    },
    [theme.breakpoints.up("xs")]: {
      width: "83vw",
      height: "100%",
      flexDirection: "column",
    },
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      height: "100%",
      flexDirection: "row",
    },
    [theme.breakpoints.up("md")]: {
      width: "100%",
      height: "100%",
      flexDirection: "row",
    },
    [theme.breakpoints.up("lg")]: {
      width: "100%",
      height: "100%",
      flexDirection: "row",
    },
    [theme.breakpoints.up("xl")]: {
      width: "100%",
      height: "100%",
      flexDirection: "row",
    },
  },
  backAndConfirmButton: {
    borderLeft: `1px solid rgba(0, 0, 0, .1)`,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.up("xxs")]: {
      borderLeft: "none",
      flexDirection: "row",
      alignItems: "space-between",
    },
    [theme.breakpoints.up("xs")]: {
      borderLeft: "none",
      flexDirection: "row",
      alignItems: "space-between",
    },
    [theme.breakpoints.up("sm")]: {
      borderLeft: `1px solid rgba(0, 0, 0, .1)`,
      flexDirection: "row",
      alignItems: "space-between",
    },
    [theme.breakpoints.up("md")]: {
      borderLeft: `1px solid rgba(0, 0, 0, .1)`,
      flexDirection: "row",
      alignItems: "space-between",
    },
    [theme.breakpoints.up("lg")]: {
      borderLeft: `1px solid rgba(0, 0, 0, .1)`,
      flexDirection: "column",
      alignItems: "center",
    },
    [theme.breakpoints.up("xl")]: {
      borderLeft: `1px solid rgba(0, 0, 0, .1)`,
      flexDirection: "column",
      alignItems: "center",
    },
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

export default function CreateStoreFromSeller() {
  const {
    cookie,
    setPage,
    setOpenUsers,
    name,
    email,
    city,
    cep,
    street,
    number,
    userStatus,
    neighborhood,
    complement,
    phone,
    setIsFromEdit,
    setSelectedIndex,
    isFromEdit,
    document,
    valueEdit,
    errorDocument,
  } = useContext(Context);

  const classes = useStyles();

  const [errorMessage, setErrorMessage] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [loadingCreateView, setLoadingCreateView] = useState(true);
  const [loading, setLoading] = useState(true);
  const [apiResponse, setApiResponse] = useState("");
  const [copySuccess, setCopySuccess] = useState("");

  useEffect(() => {
    setPage("Cadastrar Lojista");
    setOpenUsers(true);

    const token = cookie.token.accessToken;
    Api.defaults.headers.common["x-access-token"] = token;

    setLoadingCreateView(false);
  }, []); // eslint-disable-line

  const handleCloseModal = (props) => {
    if (props === "fechar") {
      if (
        errorMessage === "Falhou! E-mail já cadastrado!" ||
        errorMessage === "As senhas não coincidem" ||
        errorMessage === "Insira um documento válido."
      ) {
        setOpenModal(false);
        setButtonDisable(false);
      } else {
        setOpenModal(false);
        setPage("Meus Lojistas");
        setSelectedIndex([10, "Meus Lojistas"]);
        history.push("/stores");
        window.location.reload();
        setButtonDisable(false);
      }
    } else {
      setOpenModal(false);
      window.location.reload(true);
    }
  };

  const handleBack = (e) => {
    setPage("Meus Lojistas");
    setSelectedIndex([10, "Meus Lojistas"]);
    setIsFromEdit(false);
    history.push("/stores");
  };

  function getCurrentDay() {
    var today = new Date();

    var year = today.getFullYear();
    var month = (today.getMonth() + 1).toString().padStart(2, "0");
    var day = today.getDate().toString().padStart(2, "0");

    return year + "-" + month + "-" + day;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!city) {
      setErrorMessage(`O campo "cidade" é obrigatório.`);
      setOpenModal(true);
      setButtonDisable(true);
    } else if (errorDocument) {
      setErrorMessage(`Insira um documento válido.`);
      setOpenModal(true);
      setButtonDisable(true);
    } else {
      if (!isFromEdit) {
        try {
          const pixCode = await requestQRCode();

          const signupMessage = await createUser();

          setErrorMessage(signupMessage);

          await createTransaction({
            userDocument: document.includes("_")
              ? document.slice(0, document.length - 1)
              : document,
            pixCode: pixCode,
            managerId: cookie.userInfo[0]._id,
          });

          setOpenModal(true);
        } catch (err) {
          setOpenModal(true);
        }
      } else {
        Api2.put("/api/user", {
          _id: valueEdit,
          fullName: name.trim(),
          phone: phone.includes("_") ? phone.slice(0, phone.length - 1) : phone,
          address: {
            cep: cep,
            city: city.split(" / ")[0],
            state: city.split(" / ")[1],
            street: street,
            number: number,
            neighborhood: neighborhood,
            complement: complement,
          },
          status: userStatus,
        })
          .then((res) => {
            setErrorMessage(res.data.message);
            setOpenModal(true);
          })
          .catch((err) => {
            setErrorMessage(err.response.data.message);
            setOpenModal(true);
          });
      }
    }
  };

  const requestQRCode = async () => {
    try {
      let requestNumber = Math.random().toString(36).slice(-8);
      let currentDay = getCurrentDay();

      const response = await Api.post(
        "/api/v1/gateway/request-qrcode",
        {
          requestNumber: requestNumber,
          dueDate: currentDay,
          amount: 50,
          callbackUrl: "https://api.cupomzito.com/api/log/usercreated",
          usernameCheckout: "user_create_acount",
          client: {
            name: name,
            document: document,
            email: email,
          },
        },
        {
          headers: {
            ci: process.env.REACT_APP_CI,
            cs: process.env.REACT_APP_CS,
          },
        }
      );
      setLoading(false);
      setApiResponse(response.data);
      return response.data.paymentCode;
    } catch (err) {
      setLoading(false);
      console.error(err.response.data);
      throw err;
    }
  };

  const createUser = async () => {
    try {
      const response = await Api2.post("/api/auth/signup/seller", {
        fullName: name.trim(),
        manager: cookie.userInfo[0]._id,
        document: document.includes("_")
          ? document.slice(0, document.length - 1)
          : document,
        phone: phone.includes("_") ? phone.slice(0, phone.length - 1) : phone,
        email: email,
        address: {
          cep: cep,
          city: city.split(" / ")[0],
          state: city.split(" / ")[1],
          street: street,
          number: number,
          neighborhood: neighborhood,
          complement: complement,
        },
        role: "store",
      });

      return response.data.message;
    } catch (err) {
      setErrorMessage(err.response.data.message);
      throw err;
    }
  };

  const createTransaction = async ({ userDocument, pixCode, managerId }) => {
    try {
      await Api2.post("/api/transaction", {
        userDocument: userDocument,
        pixCode: pixCode,
        managerId: managerId,
        credits: 500,
        oldCredit: 0,
        timestamp: GenerateCurrentTime.getCurrentTime(),
      });
    } catch (err) {
      console.error(err);
    }
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard
      .writeText(apiResponse.paymentCode)
      .then(() => {
        setCopySuccess("Copiado com sucesso!");
      })
      .catch((err) => {
        console.error("Erro ao copiar para o clipboard:", err);
        setCopySuccess("Erro ao copiar!");
      });
  };

  return loadingCreateView ? (
    <Paper elevation={2} className={classes.root}>
      <Grid
        container
        style={{
          width: "78vw",
          height: "80vh",
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
        }}
      >
        <CircularProgress />
      </Grid>
    </Paper>
  ) : (
    <Paper elevation={2} className={classes.root}>
      <form onSubmit={(e) => handleSubmit(e)}>
        <div className={classes.formGrid}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={0}
            className={classes.tabs}
            indicatorColor="secondary"
            textColor="secondary"
          >
            <Tab
              id="person"
              key="person"
              value={0}
              label={
                <Grid
                  container
                  direction="row"
                  justifycontent="center"
                  alignItems="center"
                >
                  <span>Usuário</span>
                </Grid>
              }
            />
          </Tabs>

          <TabPanel value={0} index={0}>
            <CreateStore />
          </TabPanel>

          <div className={classes.backAndConfirmButton}>
            <Button
              variant="contained"
              style={{ backgroundColor: "#4B565C", color: "white" }}
              className={`${classes.buttonInput}`}
              onClick={(e) => handleBack(e)}
            >
              <ArrowBackIosNewOutlinedIcon style={{ marginRight: 10 }} />
              Voltar
            </Button>

            <Button
              type="submit"
              disabled={isFromEdit && userStatus === "inactive"}
              variant="contained"
              color="secondary"
              className={`${classes.buttonInput}`}
            >
              <CheckOutlinedIcon style={{ marginRight: 10 }} />
              salvar
            </Button>
          </div>
        </div>
      </form>
      <Dialog
        open={openModal}
        onClose={() => handleCloseModal("value")}
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle>
          <strong>
            <em>Lojista criado</em>
          </strong>
        </DialogTitle>
        <DialogContent style={{ textAlign: "center" }}>
          <Typography variant="h6" style={{ color: "gray" }}>
            {errorMessage}
          </Typography>
          <div className={classes.dialogContent}>
            {loading ? (
              <CircularProgress />
            ) : (
              <Grid container>
                <Grid
                  item
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{
                    textAlign: "left",
                  }}
                >
                  <Typography variant="body1" gutterBottom>
                    Informe o Código do Pix abaixo para o lojista. Após a
                    validação do pagamento, o mesmo será notificado por e-mail e
                    seu acesso ao sistema será liberado.
                  </Typography>
                  <Typography variant="body1">
                    Valor da compra: R$ 50,00
                  </Typography>
                </Grid>
                <Grid
                  item
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CardMedia
                    component="img"
                    alt="qrCode"
                    src={
                      "data:image/png;base64," + apiResponse.paymentCodeBase64
                    }
                    style={{
                      maxHeight: "80%",
                      maxWidth: "80%",
                    }}
                  />
                </Grid>

                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <TextField
                    label="Código do QR Code"
                    variant="outlined"
                    value={apiResponse.paymentCode}
                    multiline
                    disabled
                    maxRows={12}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      margin: 15,
                    }}
                  />
                </Grid>

                <Grid
                  item
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: 15,
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={handleCopyToClipboard}
                    startIcon={<PixOutlinedIcon />}
                  >
                    Copiar códido do Pix
                  </Button>
                </Grid>

                <Grid
                  item
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ textAlign: "center" }}
                >
                  {copySuccess && (
                    <motion.div
                      className="box"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                    >
                      <Typography>{copySuccess}</Typography>
                    </motion.div>
                  )}
                </Grid>
              </Grid>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleCloseModal("fechar")}
            style={{ color: "#808080" }}
            autoFocus
          >
            <strong>fechar</strong>
          </Button>
          <Button
            onClick={() => handleCloseModal("outro")}
            color="secondary"
            autoFocus
            disabled={buttonDisable}
          >
            <strong>Criar outro lojista</strong>
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
}

import React, { useState, useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  Grid,
  Box,
  Tabs,
  Tab,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Typography,
} from "@material-ui/core";

import { Context } from "../../../context";
import Api from "../../../service";

import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";

import CreateCampaignItems from "./createCampaignItems";
import CampaignPreview from "./campaignPreview";
import GenerateCurrentTime from "../../../utils/GenerateCurrentTime";

const useStyles = makeStyles((theme) => ({
  "@global": {
    "input:-webkit-autofill": {
      "-webkit-text-fill-color": "black !important",
      transition: "background-color 5000s ease-in-out 0s",
    },
    "input:-webkit-autofill:hover": {
      "-webkit-text-fill-color": "transparent",
      transition: "background-color 5000s ease-in-out 0s",
    },
    "input:-webkit-autofill:focus": {
      "-webkit-text-fill-color": "transparent",
      transition: "background-color 5000s ease-in-out 0s",
    },
    "input:-webkit-autofill:active": {
      "-webkit-text-fill-color": "transparent",
      transition: "background-color 5000s ease-in-out 0s",
    },
  },
  buttonInput: {
    margin: "19px",
    height: "35px",
    width: "12vw",
    [theme.breakpoints.up("xxs")]: {
      width: "35vw",
    },
    [theme.breakpoints.up("xs")]: {
      width: "35vw",
    },
    [theme.breakpoints.up("sm")]: {
      width: "12vw",
    },
    [theme.breakpoints.up("md")]: {
      width: "12vw",
    },
    [theme.breakpoints.up("lg")]: {
      width: "12vw",
    },
    [theme.breakpoints.up("xl")]: {
      width: "12vw",
    },
    color: "white",
  },
  tabs: {
    borderRight: `1px solid rgba(0, 0, 0, .1)`,
    height: "85vh",
    [theme.breakpoints.up("xxs")]: {
      height: "100%",
      borderRight: "none",
      width: "100%",
      maxWidth: "100%",
    },
    [theme.breakpoints.up("xs")]: {
      height: "100%",
      borderRight: "none",
      width: "100%",
      maxWidth: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      height: "85vh",
      borderRight: `1px solid rgba(0, 0, 0, .1)`,
      width: "100%",
      maxWidth: "100%",
    },
    [theme.breakpoints.up("md")]: {
      height: "85vh",
      borderRight: `1px solid rgba(0, 0, 0, .1)`,
      width: "100%",
      maxWidth: "100%",
    },
    [theme.breakpoints.up("lg")]: {
      height: "85vh",
      borderRight: `1px solid rgba(0, 0, 0, .1)`,
      width: "12vw",
      maxWidth: "12vw",
    },
    [theme.breakpoints.up("xl")]: {
      height: "85vh",
      borderRight: `1px solid rgba(0, 0, 0, .1)`,
      width: "12vw",
      maxWidth: "12vw",
    },
  },
  formGrid: {
    display: "flex",
    height: "100%",
    [theme.breakpoints.up("xxs")]: {
      width: "83vw",
      height: "100%",
      flexDirection: "column",
    },
    [theme.breakpoints.up("xs")]: {
      width: "83vw",
      height: "100%",
      flexDirection: "column",
    },
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      height: "100%",
      flexDirection: "row",
    },
    [theme.breakpoints.up("md")]: {
      width: "100%",
      height: "100%",
      flexDirection: "row",
    },
    [theme.breakpoints.up("lg")]: {
      width: "100%",
      height: "100%",
      flexDirection: "row",
    },
    [theme.breakpoints.up("xl")]: {
      width: "100%",
      height: "100%",
      flexDirection: "row",
    },
  },
  backAndConfirmButton: {
    borderLeft: `1px solid rgba(0, 0, 0, .1)`,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",

    [theme.breakpoints.up("xxs")]: {
      borderLeft: "none",
      flexDirection: "row",
      alignItems: "space-between",
    },
    [theme.breakpoints.up("xs")]: {
      borderLeft: "none",
      flexDirection: "row",
      alignItems: "space-between",
    },
    [theme.breakpoints.up("sm")]: {
      borderLeft: `1px solid rgba(0, 0, 0, .1)`,
      flexDirection: "column",
      alignItems: "flex-start",
    },
    [theme.breakpoints.up("md")]: {
      borderLeft: `1px solid rgba(0, 0, 0, .1)`,
      flexDirection: "column",
      alignItems: "flex-start",
    },
    [theme.breakpoints.up("lg")]: {
      borderLeft: `1px solid rgba(0, 0, 0, .1)`,
      flexDirection: "column",
      alignItems: "flex-start",
    },
    [theme.breakpoints.up("xl")]: {
      borderLeft: `1px solid rgba(0, 0, 0, .1)`,
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

export default function CreateCampaign() {
  const {
    cookie,
    setPage,
    setIsFromCreateCampaign,
    campaignName,
    campaignStatus,
    campaignCouponsAvailable,
    campaignContact,
    campaignBanner,
    campaignCategory,
    campaignCity,
    campaignDescription,
    isFromEditCampaign,
    setIsFromEditCampaign,
    valueEdit,
    setCookie,
    removeCookie,
    setUserInfos,
    isLoadingCampaign,
    setIsLoadingCampaign,
  } = useContext(Context);

  const classes = useStyles();

  const [errorMessage, setErrorMessage] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);

  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  useEffect(() => {
    setPage("Cadastrar Campanha");

    const token = cookie.token.accessToken;
    Api.defaults.headers.common["x-access-token"] = token;

    setIsLoadingCampaign(isFromEditCampaign);
  }, [tabIndex]); // eslint-disable-line

  const handleCloseModal = (props) => {
    if (props === "fechar") {
      setOpenModal(false);
      setIsFromCreateCampaign(false);
    }
  };

  const handleBack = (e) => {
    e.preventDefault();
    setPage("Campanhas");
    setIsFromCreateCampaign(false);
    setIsFromEditCampaign(false);
  };

  function getBase64SizeInMB(base64String) {
    const cleanedBase64 = base64String.split(",")[1] || base64String;

    const byteSize =
      cleanedBase64.length * (3 / 4) -
      (cleanedBase64.endsWith("==") ? 2 : cleanedBase64.endsWith("=") ? 1 : 0);

    const sizeInMB = byteSize / (1024 * 1024);

    return sizeInMB;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (campaignCity) {
      console.log(getBase64SizeInMB(campaignBanner));

      if (!isFromEditCampaign) {
        const newCredit = cookie.userInfo[0].credit - campaignCouponsAvailable;
        if (newCredit < 0) {
          setErrorMessage(
            "Saldo insuficiente para a quantidade de cupons. Faça uma recarga!"
          );
          setOpenModal(true);
        } else if (getBase64SizeInMB(campaignBanner) > 50) {
          setErrorMessage(
            `O tamanho da imagem (${Number(
              getBase64SizeInMB(campaignBanner)
            ).toFixed(2)}MB) excede o limite de 50MB.`
          );
          setOpenModal(true);
        } else {
          Api.post("/api/campaign", {
            userId: cookie.userInfo[0]._id,
            fullName: campaignName.trim(),
            couponsAvailable: campaignCouponsAvailable,
            remainingCouponsAvailable: campaignCouponsAvailable,
            contact: campaignContact.includes("_")
              ? campaignContact.slice(0, campaignContact.length - 1)
              : campaignContact,
            banner: campaignBanner || "default",
            city: campaignCity.split(" / ")[0],
            state: campaignCity.split(" / ")[1],
            description: campaignDescription,
            category: campaignCategory,
            status: campaignStatus,
          })
            .then((res) => {
              Api.put("/api/user", {
                _id: cookie.userInfo[0]._id,
                credit: newCredit,
              })
                .then(() => {
                  setErrorMessage(res.data.message);
                  setOpenModal(true);
                })
                .catch(() => {
                  setErrorMessage(
                    "Impossível criar a campanha. Servidor indisponível."
                  );
                  setOpenModal(true);
                });

              Api.post("/api/log", {
                userId: cookie.userInfo[0]._id,
                action: "store_create_campaign",
                data: `
                    userId: ${valueEdit},
                    fullName: ${campaignName.trim()},
                    campaignCouponsAvailable: ${campaignCouponsAvailable}
                    contact: ${
                      campaignContact.includes("_")
                        ? campaignContact.slice(0, campaignContact.length - 1)
                        : campaignContact
                    },
                    city: ${campaignCity.split(" / ")[0]},
                    state: ${campaignCity.split(" / ")[1]},
                    description: ${campaignDescription},
                    category: ${campaignCategory},
                    status: ${campaignStatus},
                  `,
                timestamp: GenerateCurrentTime.getCurrentTime(),
              })
                .then(() => {
                  //success
                })
                .catch((err) => {
                  console.error(err);
                });
            })
            .catch((err) => {
              setErrorMessage(err.response.data.message);
              setOpenModal(true);
            });

          updateCookie(newCredit);
        }
      } else {
        if (getBase64SizeInMB(campaignBanner) > 50) {
          setErrorMessage(
            `O tamanho da imagem (${Number(
              getBase64SizeInMB(campaignBanner)
            ).toFixed(2)}MB) excede o limite de 50MB.`
          );
          setOpenModal(true);
        } else {
          Api.put("/api/campaign", {
            _id: valueEdit,
            fullName: campaignName.trim(),
            contact: campaignContact.includes("_")
              ? campaignContact.slice(0, campaignContact.length - 1)
              : campaignContact,
            banner: campaignBanner || "",
            description: campaignDescription,
            category: campaignCategory,
            city: campaignCity.split(" / ")[0],
            state: campaignCity.split(" / ")[1],
            status: campaignStatus,
          })
            .then((res) => {
              Api.post("/api/log", {
                userId: cookie.userInfo[0]._id,
                action: "store_update_campaign",
                data: `
                    campaignEdit: ${valueEdit},
                    fullName: ${campaignName.trim()},
                    contact: ${
                      campaignContact.includes("_")
                        ? campaignContact.slice(0, campaignContact.length - 1)
                        : campaignContact
                    },
                    city: ${campaignCity.split(" / ")[0]},
                    state: ${campaignCity.split(" / ")[1]},
                    description: ${campaignDescription},
                    category: ${campaignCategory},
                    status: ${campaignStatus},
                  `,
                timestamp: GenerateCurrentTime.getCurrentTime(),
              })
                .then(() => {
                  //success
                })
                .catch((err) => {
                  console.error(err);
                });
              setErrorMessage(res.data.message);
              setOpenModal(true);
            })
            .catch((err) => {
              setErrorMessage(err.response.data.message);
              setOpenModal(true);
            });
        }
      }
    } else {
      setErrorMessage("O campo Cidade é obrigatório");
      setOpenModal(true);
    }
  };

  function updateCookie(newCredit) {
    const newData = {
      _id: cookie.userInfo[0]._id,
      fullName: cookie.userInfo[0].fullName,
      password: cookie.userInfo[0].password,
      manager: cookie.userInfo[0].manager,
      role: cookie.userInfo[0].role,
      credit: newCredit,
      phone: cookie.userInfo[0].phone,
      email: cookie.userInfo[0].email,
      document: cookie.userInfo[0].document,
      address: cookie.userInfo[0].address,
      status: cookie.userInfo[0].status,
    };

    removeCookie("userInfo");
    setCookie("userInfo", [newData], { expire: 1, path: "/" });
    setUserInfos([newData]);
  }

  return isLoadingCampaign ? (
    <Paper elevation={2} className={classes.root}>
      <Grid
        container
        style={{
          width: "78vw",
          height: "80vh",
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
        }}
      >
        <CircularProgress />
      </Grid>
    </Paper>
  ) : (
    <>
      <form onSubmit={(e) => handleSubmit(e)}>
        <div className={classes.formGrid}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={0}
            onChange={handleChange}
            className={classes.tabs}
            indicatorColor="secondary"
            textColor="secondary"
          >
            <Tab
              id="camapaign"
              key="camapaign"
              value={tabIndex}
              label={
                <Grid container>
                  <span>Campanha</span>
                </Grid>
              }
            />
          </Tabs>

          <TabPanel value={tabIndex} index={0}>
            <Grid container spacing={2}>
              <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
                <CreateCampaignItems />
              </Grid>
              <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    gutterBottom
                    variant="h6"
                    style={{ marginBottom: 20 }}
                  >
                    Pré-visualização da Campanha
                  </Typography>
                  <CampaignPreview
                    description={campaignDescription}
                    title={campaignName}
                    category={
                      campaignCategory === "health"
                        ? "Saúde"
                        : campaignCategory === "beauty"
                        ? "Beleza"
                        : campaignCategory === "cloth"
                        ? "Vestuário"
                        : campaignCategory === "food"
                        ? "Comida"
                        : campaignCategory === "home"
                        ? "Casa"
                        : campaignCategory === "pets"
                        ? "Pets"
                        : campaignCategory === "electronic"
                        ? "Eletrônicos"
                        : campaignCategory === "automotive"
                        ? "Automotivo"
                        : campaignCategory === "kid"
                        ? "Kids"
                        : campaignCategory === "book"
                        ? "Livraria"
                        : "Diversos"
                    }
                    remaining={campaignCouponsAvailable}
                    banner={campaignBanner}
                    city={`${campaignCity}`}
                  />
                </div>
              </Grid>
            </Grid>
          </TabPanel>

          <div className={classes.backAndConfirmButton}>
            <Button
              variant="contained"
              style={{ backgroundColor: "#4B565C", color: "white" }}
              className={`${classes.buttonInput}`}
              onClick={(e) => handleBack(e)}
            >
              <ArrowBackIosNewOutlinedIcon style={{ marginRight: 10 }} />
              Voltar
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              className={`${classes.buttonInput}`}
            >
              <CheckOutlinedIcon style={{ marginRight: 10 }} />
              salvar
            </Button>
          </div>
        </div>
      </form>

      <Dialog
        open={openModal}
        onClose={() => handleCloseModal("value")}
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle>
          <strong>
            <em>Atenção:</em>
          </strong>
        </DialogTitle>
        <DialogContent style={{ textAlign: "center" }}>
          <Typography variant="h6" style={{ color: "gray" }}>
            {errorMessage}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleCloseModal("fechar")}
            style={{ color: "#808080" }}
            autoFocus
          >
            <strong>fechar</strong>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

import { useState } from "react";
import Api from "../../service";

export default function LoginHook() {
  const [isForgetPasswordVisible, setIsForgetPasswordVisible] = useState(false);
  const [loadingLogin, setLoadingLogin] = useState(false);

  return {
    loadingLogin,
    setLoadingLogin,
    isForgetPasswordVisible,
    setIsForgetPasswordVisible,
  };
}

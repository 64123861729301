import React, { useContext, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  CardHeader,
  CardActionArea,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from "@material-ui/core";

import { Context } from "../../../context";

import CupomzitoSvg from "../../../assets/cupomzito.svg";

import MaximizedCampaignPreview from "./maximizedCampaignPreview";

import Api from "../../../service";
import history from "../../../routes/history";
import GenerateCurrentTime from "../../../utils/GenerateCurrentTime";

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    width: 30,
    height: 30,
  },
  avatarIconSize: {
    maxWidth: 18,
    maxHeight: 18,
  },
  cardContainer: {
    width: 180,
    height: 270,
    minHeight: 270,
    backgroundColor: theme.palette.primary.light,
  },
  cardActionAreaContainer: {
    minHeight: 270,
  },
  subheaderColor: {
    fontSize: 13,
    color: theme.palette.primary.main,
    fontWeight: "bold",
    padding: 5,
  },
  contentTitle: {
    fontSize: 15,
    fontWeight: "bold",
    position: "absolute",
    top: 180,
    paddingRight: 10,
    wordBreak: "break-word",
    color: theme.palette.primary.main,
  },
  contentContact: {
    wordBreak: "break-word",
    position: "absolute",
    bottom: 10,
    color: theme.palette.primary.mainSecondary,
    display: "flex",
    paddingRight: 15,
    alignContent: "center",
  },
  contentSubtitle: {
    wordBreak: "break-word",
    position: "absolute",
    bottom: 30,
    color: theme.palette.primary.mainSecondary,
    display: "flex",
    alignContent: "center",
  },
  cardActions: {
    display: "flex",
    justifyContent: "center",
  },
  cardMediaContainer: {
    position: "absolute",
    top: 0,
    height: 170,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    left: "50%",
    transform: "translateX(-50%)",
    backgroundColor: theme.palette.primary.main,
  },
  cardCouponsHeader: {
    position: "absolute",
    top: 0,
    left: 0,
    paddingRight: 10,
    borderRadius: 10,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    backgroundColor: theme.palette.secondary.main,
    zIndex: 9,
  },
  campaignCity: {
    fontSize: 13,
    color: theme.palette.primary.light,
    fontWeight: "bold",
    padding: 5,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  cardCouponsCity: {
    position: "absolute",
    top: 140,
    right: 0,
    maxWidth: "100%",
    borderRadius: 10,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    backgroundColor: theme.palette.primary.main,
    zIndex: 9,
  },
}));

export default function CampaignPreview(props) {
  const classes = useStyles();
  const {
    description,
    title,
    category,
    remaining,
    banner,
    store,
    storeId,
    campaignId,
    city,
  } = props;
  const { cookie } = useContext(Context);

  const [openMaximizedPreviewDialog, setOpenMaximizedPreviewDialog] =
    useState(false);
  const [openResponseDialog, setOpenResponseDialog] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [recoveredCampaignsIds, setRecoveredCampaignsIds] = useState([]);

  useEffect(() => {
    setRecoveredCampaignsIds([]);
    setOpenMaximizedPreviewDialog(false);
    setOpenResponseDialog(false);
  }, []);

  useEffect(() => {
    if (
      cookie.userInfo &&
      storeId &&
      campaignId &&
      openMaximizedPreviewDialog
    ) {
      const token = cookie.token.accessToken;
      Api.defaults.headers.common["x-access-token"] = token;

      Api.get("/api/package/detail/user/short", {
        params: { userId: cookie.userInfo[0]._id },
      })
        .then((res) => {
          setRecoveredCampaignsIds(res.data);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [openMaximizedPreviewDialog]);

  const handleClickOnCardArea = () => {
    setOpenMaximizedPreviewDialog(true);
  };

  const handleCloseModal = () => {
    setOpenMaximizedPreviewDialog(false);
  };

  const handleCloseModalResponse = () => {
    setOpenResponseDialog(false);
  };

  const handlePickCoupon = () => {
    if (cookie.userInfo && storeId && campaignId) {
      Api.post("/api/package/pickup", {
        userId: cookie.userInfo[0]._id,
        storeId: storeId,
        campaignId: campaignId,
      })
        .then((res) => {
          registerLog();
          setResponseMessage(res.data.message);
          setOpenResponseDialog(true);
          handleCloseModal();
        })
        .catch((err) => {
          setResponseMessage(err.response.data.message);
          setOpenResponseDialog(true);
          handleCloseModal();
        });
    } else {
      setResponseMessage("Faça login para resgatar seus cupons!");
      setOpenResponseDialog(true);
      handleCloseModal();
    }
  };

  function registerLog() {
    Api.post("/api/log", {
      userId: cookie.userInfo[0]._id,
      action: "user_pick_coupon",
      data: `
        userId: ${cookie.userInfo[0]._id},
        storeId: ${storeId}
        campaignId: ${campaignId},
      `,
      timestamp: GenerateCurrentTime.getCurrentTime(),
    })
      .then(() => {
        // success
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const handleMakeLogin = () => {
    history.push("/login");
  };

  return (
    <>
      <Card className={classes.cardContainer}>
        <CardActionArea
          className={classes.cardActionAreaContainer}
          onClick={remaining > 0 ? () => handleClickOnCardArea() : null}
        >
          <div className={classes.cardCouponsHeader}>
            <Typography variant="body2" className={classes.subheaderColor}>{`${
              remaining > 0 ? `Disponível: ${remaining}` : "Esgotado"
            }`}</Typography>
          </div>
          <div className={classes.cardMediaContainer}>
            <CardMedia
              component="img"
              alt="bannerImage"
              src={banner !== "default" ? banner : CupomzitoSvg}
              style={{
                padding: banner !== "default" ? 0 : 10,
                height: banner !== "default" && "100%",
                width: "100%",
                objectFit: "cover",
              }}
            />
          </div>
          <div className={classes.cardCouponsCity}>
            <Typography variant="body2" className={classes.campaignCity}>
              {city}
            </Typography>
          </div>
          <CardContent>
            <Typography variant="body1" className={classes.contentTitle}>
              {title.toUpperCase()}
            </Typography>
            <Typography variant="body2" className={classes.contentContact}>
              {store || cookie.userInfo[0].fullName}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
      <Dialog
        open={openMaximizedPreviewDialog}
        onClose={handleCloseModal}
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle>
          <strong>
            <em>
              {store ? "Detalhes do cupom:" : "Pré-visualização maximizada:"}
            </em>
          </strong>
        </DialogTitle>
        <DialogContent style={{ display: "flex", justifyContent: "center" }}>
          <MaximizedCampaignPreview
            description={description}
            title={title}
            category={category}
            remaining={remaining}
            banner={banner}
            store={store || cookie.userInfo[0].fullName}
            city={city}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} style={{ color: "#808080" }}>
            Fechar
          </Button>
          {store && (
            <Button
              variant={"contained"}
              color="secondary"
              style={{ color: "white", fontWeight: "bold" }}
              onClick={handlePickCoupon}
              disabled={
                remaining === 0 || recoveredCampaignsIds.includes(campaignId)
              }
            >
              {recoveredCampaignsIds.includes(campaignId)
                ? "resgatado"
                : "Resgatar"}
            </Button>
          )}
        </DialogActions>
      </Dialog>

      <Dialog
        open={openResponseDialog}
        onClose={handleCloseModalResponse}
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle>
          <strong>
            <em>Atenção:</em>
          </strong>
        </DialogTitle>
        <DialogContent style={{ textAlign: "center" }}>
          <Typography variant="h6" style={{ color: "gray" }}>
            {responseMessage}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseModalResponse}
            style={{ color: "#808080" }}
          >
            Fechar
          </Button>
          {responseMessage === "Faça login para resgatar seus cupons!" && (
            <Button
              variant={"contained"}
              color="secondary"
              style={{ color: "white", fontWeight: "bold" }}
              onClick={handleMakeLogin}
            >
              Fazer login
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}

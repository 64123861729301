import React, { useContext, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  FormControl,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import { Context } from "../../context";
import Autocomplete from "@mui/material/Autocomplete";

import Api from "../../service";

const useStyles = makeStyles((theme) => ({
  autocomplete: {
    "& .MuiAutocomplete-inputRoot": {
      minHeight: "56px",
    },
  },
  inputMaior: {
    [theme.breakpoints.up("xxs")]: {
      width: "85%",
    },
    [theme.breakpoints.up("xs")]: {
      width: "85%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "85%",
    },
    [theme.breakpoints.up("md")]: {
      width: "85%",
    },
    [theme.breakpoints.up("lg")]: {
      width: "30vw",
    },
    [theme.breakpoints.up("xl")]: {
      width: "30vw",
    },
  },
  filterStore: {
    display: "flex",
    justifyContent: "center",
  },
}));

export default function FilterStore() {
  const classes = useStyles();
  const [stores, setStores] = useState([]);
  const [inputValue, setInputValue] = useState("");

  const { filteredStore, setFilteredStore, loadingPackage, setLoadingPackage } =
    useContext(Context);

  useEffect(() => {
    setLoadingPackage(true);
    Api.get("/api/user/list/allstores")
      .then((res) => {
        const storesNames = res.data.map((store) => {
          return `${store._id}!${store.fullName} - ${store.address.city} / ${store.address.state}!${store.credit}`;
        });
        setStores(storesNames);
        setFilteredStore(storesNames[0]);
        setLoadingPackage(false);
      })
      .catch((err) => {
        console.error("Erro na requisição:", err);
      });
  }, []);

  return loadingPackage ? (
    <Grid
      container
      style={{
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
      }}
    >
      <CircularProgress />
    </Grid>
  ) : (
    <Grid container>
      <Grid
        item
        xl={12}
        lg={12}
        md={12}
        sm={12}
        xs={12}
        className={classes.filterStore}
      >
        <FormControl variant="outlined" className={classes.inputMaior}>
          <Autocomplete
            value={filteredStore}
            onChange={(event, newValue) => {
              setFilteredStore(newValue);
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            options={stores}
            getOptionLabel={(option) => option.split("!")[1] || ""}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Lojista"
                variant="outlined"
                required
              />
            )}
            className={classes.autocomplete}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
}

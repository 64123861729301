import { useState } from "react";

export default function PackageHook() {
  const [packageList, setPackageList] = useState("");
  const [packageUserId, setPackageUserId] = useState("");
  const [packageCouponValue, setPackageCouponValue] = useState(0);
  const [packageIsApproved, setPackageIsApproved] = useState(false);
  const [packageStatus, setPackageStatus] = useState("active");
  const [filteredStore, setFilteredStore] = useState("");
  const [errorPackageUserId, setErrorPackageUserId] = useState(false);
  const [errorPackageCouponValue, setErrorPackageCouponValue] = useState(false);
  const [loadingPackage, setLoadingPackage] = useState(true);

  return {
    packageList,
    setPackageList,
    loadingPackage,
    setLoadingPackage,
    packageUserId,
    packageStatus,
    setPackageStatus,
    filteredStore,
    setFilteredStore,
    setPackageUserId,
    packageCouponValue,
    setPackageCouponValue,
    packageIsApproved,
    setPackageIsApproved,
    errorPackageUserId,
    setErrorPackageUserId,
    errorPackageCouponValue,
    setErrorPackageCouponValue,
  };
}

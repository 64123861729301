import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Card, CardContent } from "@material-ui/core";
import { Context } from "../../context";

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    width: 300,
    height: 160,
    borderRadius: 20,
    boxShadow: " 0px 0px 22px 1px rgba(0,0,0,0.74)",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.light,
    textAlign: "center",
  },
}));

export default function TotalCreditMetrics() {
  const classes = useStyles();
  const { cookie } = useContext(Context);

  return (
    <Card className={classes.cardContainer}>
      <CardContent>
        <Typography variant="h5" gutterBottom style={{ paddingBottom: 15 }}>
          Total de créditos
        </Typography>
        <Typography variant="h4">{cookie.userInfo[0].credit}</Typography>
      </CardContent>
    </Card>
  );
}

import React, { useContext, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
  IconButton,
  Tooltip,
} from "@material-ui/core";

import { Context } from "../../context";
import Autocomplete from "@mui/material/Autocomplete";
import Api from "../../service";

import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import { useEffect } from "react";
import PhoneFormatter from "../../utils/PhoneFormatter";
import DocumentFormatter from "../../utils/DocumentFormatter";

const useStyles = makeStyles((theme) => ({
  inputMaior: {
    marginBottom: 20,
    width: "100%",
  },
  inputStyle: {
    minWidth: "71vw",
    [theme.breakpoints.up("xxs")]: {
      minWidth: "64vw",
    },
    [theme.breakpoints.up("xs")]: {
      minWidth: "64vw",
    },
    [theme.breakpoints.up("sm")]: {
      minWidth: "50vw",
    },
    [theme.breakpoints.up("md")]: {
      minWidth: "58vw",
    },
    [theme.breakpoints.up("lg")]: {
      minWidth: "65vw",
    },
    [theme.breakpoints.up("xl")]: {
      minWidth: "71vw",
    },
  },
  hover: {
    "&:hover": {
      color: "#44533f",
    },
  },
  autocomplete: {
    "& .MuiAutocomplete-inputRoot": {
      minHeight: "56px",
    },
  },
}));

export default function CreateUser() {
  const [inputValue, setInputValue] = useState("");
  const [managerInputValue, setManagerInputValue] = useState("");
  const [cities, setCities] = useState([]);

  const {
    name,
    setName,
    city,
    setCity,
    cep,
    setCEP,
    document,
    setDocument,
    errorDocument,
    setErrorDocument,
    street,
    setStreet,
    number,
    setNumber,
    neighborhood,
    setNeighborhood,
    complement,
    setComplement,
    setEmail,
    phone,
    setPhone,
    email,
    userStatus,
    setUserStatus,
    userRole,
    setUserRole,
    passwordConfirm,
    password,
    setPassword,
    setPasswordConfirm,
    errorName,
    setErrorName,
    errorStreet,
    setErrorStreet,
    errorNumber,
    setErrorNumber,
    errorNeighborhood,
    setErrorNeighborhood,
    errorEmail,
    setErrorEmail,
    errorPhone,
    setErrorPhone,
    errorPassword,
    setErrorPassword,
    errorConfirmPass,
    setErrorConfirmPass,
    showPass,
    setShowPass,
    showPassConfirm,
    setShowPassConfirm,
    isFromEdit,
    cookie,
    credits,
    setCredits,
    availableManagers,
    setAvailableManagers,
    manager,
    setManager,
  } = useContext(Context);

  useEffect(() => {
    let isMounted = true; // Flag para verificar se o componente está montado

    if (!isFromEdit) {
      setName("");
      setEmail("");
      setPhone("");
      setUserRole("common");
      setCity(
        `${cookie.userInfo[0].address.city} / ${cookie.userInfo[0].address.state}`
      );
      setCEP("");
      setStreet("");
      setNumber("");
      setNeighborhood("");
      setComplement("");
      setDocument("");
      setCredits(0);
      setManager(
        `${cookie.userInfo[0]._id}-${cookie.userInfo[0].fullName} (${
          cookie.userInfo[0].role === "admin"
            ? "Administrador"
            : cookie.userInfo[0].role === "partner"
            ? "Parceiro"
            : "Vendedor"
        })`
      );
    }
    setPassword("");
    setPasswordConfirm("");
    setErrorName(false);
    setErrorStreet(false);
    setErrorNumber(false);
    setErrorNeighborhood(false);
    setErrorEmail(false);
    setErrorPhone(false);
    setErrorPassword(false);
    setErrorConfirmPass(false);
    setErrorDocument(false);

    const fetchCities = async () => {
      try {
        const response = await fetch(
          `https://servicodados.ibge.gov.br/api/v1/localidades/municipios?orderBy=nome`
        );
        if (response.ok) {
          const data = await response.json();
          if (isMounted) {
            const cityNames = data.map((city) => {
              return `${city.nome} / ${city.microrregiao.mesorregiao.UF.sigla}`;
            });
            setCities(cityNames);
          }
        } else {
          console.error("Erro ao buscar cidades");
        }
      } catch (error) {
        if (isMounted) {
          console.error("Erro na requisição: ", error);
        }
      }
    };

    Api.get("/api/user/list/available/managers")
      .then((res) => {
        const managers = res.data.map((user) => {
          return `${user._id}-${user.fullName} (${user.role})`;
        });

        setAvailableManagers(managers);
      })
      .catch((err) => {
        console.error(err);
      });

    fetchCities();

    return () => {
      isMounted = false; // Define a flag como false quando o componente for desmontado
    };
  }, [isFromEdit, cookie]); // eslint-disable-line

  const classes = useStyles();

  const handleClickShowPass = () => {
    setShowPass(!showPass);
  };

  const handleClickShowPassConfirm = () => {
    setShowPassConfirm(!showPassConfirm);
  };

  return (
    <>
      <Grid container className={classes.inputStyle}>
        <Grid container spacing={1}>
          <Grid item xl={5} lg={5} md={5} sm={12} xs={12}>
            <TextField
              id="fullName"
              required
              value={name || ""}
              error={!!errorName}
              style={{ marginRight: 10 }}
              onInvalid={(e) => {
                setErrorName(true);
              }}
              onInput={(e) => {
                setName(e.target.value);
                setErrorName(false);
              }}
              label="Nome"
              variant="outlined"
              className={classes.inputMaior}
            />
          </Grid>
          <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
            <FormControl variant="outlined" className={classes.inputMaior}>
              <Autocomplete
                value={manager || ""}
                onChange={(event, newValue) => {
                  setManager(newValue);
                }}
                inputValue={managerInputValue}
                onInputChange={(event, newInputValue) => {
                  setManagerInputValue(newInputValue);
                }}
                options={availableManagers}
                getOptionLabel={(option) => option.split("-")[1] || ""}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Responsável"
                    variant="outlined"
                    required
                  />
                )}
                className={classes.autocomplete}
              />
            </FormControl>
          </Grid>
          <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
            <TextField
              id={DocumentFormatter.defineFormatLabel(document)}
              required
              value={DocumentFormatter.formatDocument(document)}
              error={errorDocument}
              onInvalid={(e) => {
                setErrorDocument(true);
              }}
              onInput={(e) => {
                const value = e.target.value;
                setDocument(value);
                const isValid = DocumentFormatter.isValidDocument(value);
                setErrorDocument(!isValid);
              }}
              label={DocumentFormatter.defineFormatLabel(document)}
              variant="outlined"
              className={classes.inputMaior}
            />
          </Grid>
        </Grid>
        {/* END NAME AND CPF FIELD CONTAINER */}

        <Grid container spacing={1}>
          <Grid item xl={5} lg={5} md={5} sm={12} xs={12}>
            <TextField
              id="email"
              required
              value={email || ""}
              error={!!errorEmail}
              type="email"
              onInvalid={(e) => {
                setErrorEmail(true);
              }}
              onInput={(e) => {
                setEmail(e.target.value);
                setErrorEmail(false);
              }}
              label="E-mail"
              variant="outlined"
              className={classes.inputMaior}
            />
          </Grid>
          <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
            <TextField
              id="phone"
              required
              value={PhoneFormatter.formatPhoneNumber(phone)}
              error={errorPhone}
              onChange={(e) => {
                setPhone(PhoneFormatter.formatPhoneNumber(e.target.value));
                setErrorPhone(false);
              }}
              type="tel"
              onInvalid={() => setErrorPhone(true)}
              label="Telefone"
              variant="outlined"
              fullWidth
              inputProps={{ maxLength: 15 }}
              className={classes.inputMaior}
            />
          </Grid>
          <Grid item xl={2} lg={2} md={2} sm={12} xs={12}>
            <FormControl variant="outlined" className={classes.inputMaior}>
              <InputLabel>Função</InputLabel>
              <Select
                id="role"
                value={userRole}
                onChange={(e) => setUserRole(e.target.value)}
                label="Função"
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                  PaperProps: {
                    style: {
                      maxHeight: 200,
                    },
                  },
                }}
              >
                <MenuItem value={"common"}>Comum</MenuItem>
                <MenuItem value={"store"}>Lojista</MenuItem>
                <MenuItem value={"seller"}>Vendedor</MenuItem>
                <MenuItem value={"partner"}>Parceiro</MenuItem>
                <MenuItem value={"admin"}>Administrador</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xl={2} lg={2} md={2} sm={12} xs={12}>
            <TextField
              id="credits"
              value={credits || 0}
              disabled={userRole !== "store"}
              type="number"
              onInput={(e) => {
                setCredits(e.target.value);
              }}
              label="Créditos"
              variant="outlined"
              className={classes.inputMaior}
            />
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item xl={1} lg={1} md={1} sm={12} xs={12}>
            <TextField
              id="cep"
              type="cep"
              value={cep || ""}
              onInput={(e) => {
                setCEP(e.target.value);
              }}
              InputProps={{
                classes: {
                  input: classes.inputColor,
                  notchedOutline: classes.notchedOutline,
                },
              }}
              label="CEP"
              variant="outlined"
              className={classes.inputMaior}
            />
          </Grid>

          <Grid item xl={2} lg={2} md={2} sm={12} xs={12}>
            <FormControl variant="outlined" className={classes.inputMaior}>
              <Autocomplete
                value={city || ""}
                onChange={(event, newValue) => {
                  setCity(newValue);
                }}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                options={cities}
                getOptionLabel={(option) => option || ""}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Cidade"
                    variant="outlined"
                    required
                  />
                )}
                className={classes.autocomplete}
              />
            </FormControl>
          </Grid>

          <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
            <TextField
              id="neighborhood"
              required
              value={neighborhood || ""}
              error={errorNeighborhood}
              onInvalid={(e) => {
                setErrorNeighborhood(true);
              }}
              onInput={(e) => {
                setNeighborhood(e.target.value);
                setErrorNeighborhood(false);
              }}
              InputProps={{
                classes: {
                  input: classes.inputColor,
                  notchedOutline: classes.notchedOutline,
                },
              }}
              label="Bairro"
              variant="outlined"
              className={classes.inputMaior}
            />
          </Grid>
          <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
            <TextField
              id="street"
              required
              value={street || ""}
              error={errorStreet}
              onInvalid={(e) => {
                setErrorStreet(true);
              }}
              onInput={(e) => {
                setStreet(e.target.value);
                setErrorStreet(false);
              }}
              InputProps={{
                classes: {
                  input: classes.inputColor,
                  notchedOutline: classes.notchedOutline,
                },
              }}
              label="Rua"
              variant="outlined"
              className={classes.inputMaior}
            />
          </Grid>

          <Grid item xl={1} lg={1} md={1} sm={12} xs={12}>
            <TextField
              id="number"
              required
              value={number || ""}
              error={errorNumber}
              onInvalid={(e) => {
                setErrorNumber(true);
              }}
              onInput={(e) => {
                setNumber(e.target.value);
                setErrorNumber(false);
              }}
              InputProps={{
                classes: {
                  input: classes.inputColor,
                  notchedOutline: classes.notchedOutline,
                },
              }}
              label="Nº"
              variant="outlined"
              className={classes.inputMaior}
            />
          </Grid>
          <Grid item xl={2} lg={2} md={2} sm={12} xs={12}>
            <TextField
              id="complement"
              value={complement || ""}
              onInput={(e) => {
                setComplement(e.target.value);
              }}
              InputProps={{
                classes: {
                  input: classes.inputColor,
                  notchedOutline: classes.notchedOutline,
                },
              }}
              label="Complemento"
              variant="outlined"
              className={classes.inputMaior}
            />
          </Grid>
        </Grid>
        {/* START ROLE FIELD CONTAINER */}

        <Grid container spacing={1}>
          <Grid item xl={5} lg={5} md={5} sm={12} xs={12}>
            <TextField
              id="password"
              value={password || ""}
              error={!!errorPassword}
              required={!isFromEdit ? true : false}
              type={showPass ? "text" : "password"}
              label="Nova Senha"
              onInvalid={(e) => {
                if (!isFromEdit) {
                  setErrorPassword(true);
                }
              }}
              onInput={(e) => {
                setErrorPassword(false);
                setPassword(e.target.value);
              }}
              variant="outlined"
              className={classes.inputMaior}
              autoComplete="new-password"
              InputProps={{
                endAdornment: (
                  <Tooltip
                    title={!showPass ? "Visualizar senha" : "Esconder Senha"}
                  >
                    <InputAdornment position="end">
                      <IconButton
                        className={classes.hover}
                        onClick={handleClickShowPass}
                      >
                        {showPass ? (
                          <Visibility
                            style={{ color: errorPassword && "red" }}
                          />
                        ) : (
                          <VisibilityOff
                            style={{ color: errorPassword && "red" }}
                          />
                        )}
                      </IconButton>
                    </InputAdornment>
                  </Tooltip>
                ),
              }}
            />
          </Grid>
          <Grid item xl={5} lg={5} md={5} sm={12} xs={12}>
            <TextField
              id="passwordConfirm"
              value={passwordConfirm || ""}
              error={!!errorConfirmPass}
              type={showPassConfirm ? "text" : "password"}
              label="Confirmar Nova Senha"
              onInvalid={(e) => {
                setErrorConfirmPass(true);
              }}
              onInput={(e) => {
                setPasswordConfirm(e.target.value);
                if (e.target.value !== password) {
                  setErrorConfirmPass(true);
                } else {
                  setErrorConfirmPass(false);
                }
              }}
              variant="outlined"
              className={classes.inputMaior}
              autoComplete="new-password"
              InputProps={{
                endAdornment: (
                  <Tooltip
                    title={
                      !showPassConfirm ? "Visualizar senha" : "Esconder Senha"
                    }
                  >
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowPassConfirm}
                        className={classes.hover}
                      >
                        {showPassConfirm ? (
                          <Visibility
                            style={{ color: errorConfirmPass && "red" }}
                          />
                        ) : (
                          <VisibilityOff
                            style={{ color: errorConfirmPass && "red" }}
                          />
                        )}
                      </IconButton>
                    </InputAdornment>
                  </Tooltip>
                ),
              }}
            />
          </Grid>

          <Grid item xl={2} lg={2} md={2} sm={12} xs={12}>
            <FormControl variant="outlined" className={classes.inputMaior}>
              <InputLabel>Status</InputLabel>
              <Select
                id="userStatus"
                value={userStatus}
                onChange={(e) => setUserStatus(e.target.value)}
                label="Status"
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                  PaperProps: {
                    style: {
                      maxHeight: 200,
                    },
                  },
                }}
              >
                <MenuItem value={"active"}>Habilitado</MenuItem>
                <MenuItem value={"inactive"}>Desabilitado</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

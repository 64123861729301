import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid } from "@material-ui/core";

import TotalCreditMetrics from "./totalCreditMetrics";
import TotalCampaignActiveMetrics from "./totalCampaignActive";
import TotalCampaignInactive from "./totalCampaignInactive";
import TotalCampaign from "./totalCampaign";
import TotalCouponsActive from "./totalCouponsActive";
import TotalCouponsUsed from "./totalCouponsUsed";
import ChargeCredits from "./chargeCredits";
import CreateCampaign from "./createCampaign";
import ConsultCampaign from "./consultClients";
import TotalClients from "./totalClients";

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    width: 300,
    height: 160,
    borderRadius: 20,
    boxShadow: " 0px 0px 22px 1px rgba(0,0,0,0.74)",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.light,
    textAlign: "center",
  },
  metricContainer: {
    display: "flex",
    marginTop: 100,
  },
  metricContainerTitle: {
    marginLeft: 30,
    marginBottom: 50,
    [theme.breakpoints.up("xxs")]: {
      marginLeft: 30,
    },
    [theme.breakpoints.up("xs")]: {
      marginLeft: 30,
    },
    [theme.breakpoints.up("sm")]: {
      marginLeft: 30,
    },
    [theme.breakpoints.up("md")]: {
      marginLeft: 30,
    },
    [theme.breakpoints.up("lg")]: {
      marginLeft: 100,
    },
    [theme.breakpoints.up("xl")]: {
      marginLeft: 100,
    },
    textAlign: "start",
  },
  metricContainerItems: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.up("xxs")]: {
      paddingLeft: 0,
      flexDirection: "column",
    },
    [theme.breakpoints.up("xs")]: {
      paddingLeft: 0,
      flexDirection: "column",
    },
    [theme.breakpoints.up("sm")]: {
      paddingLeft: 0,
      flexDirection: "column",
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: 0,
      flexDirection: "column",
    },
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 100,
      flexDirection: "row",
    },
    [theme.breakpoints.up("xl")]: {
      paddingLeft: 100,
      flexDirection: "row",
    },
  },
  cardMetricStyle: {
    [theme.breakpoints.up("xxs")]: {
      marginBottom: 30,
    },
    [theme.breakpoints.up("xs")]: {
      marginBottom: 30,
    },
    [theme.breakpoints.up("sm")]: {
      marginBottom: 30,
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: 30,
    },
    [theme.breakpoints.up("lg")]: {
      marginBottom: 30,
    },
    [theme.breakpoints.up("xl")]: {
      marginBottom: 30,
    },
  },
}));

export default function Metrics() {
  const classes = useStyles();

  return (
    <Grid container className={classes.metricContainer}>
      <Grid
        item
        xl={12}
        lg={12}
        md={12}
        sm={12}
        xs={12}
        className={classes.metricContainerTitle}
      >
        <Typography variant="h4" style={{ fontWeight: "bold" }}>
          Bem-vindo(a)!
        </Typography>
      </Grid>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Grid container className={classes.metricContainerItems}>
          <Grid
            item
            xl={3}
            lg={3}
            md={3}
            sm={12}
            xs={12}
            className={classes.cardMetricStyle}
          >
            <ChargeCredits />
          </Grid>
          <Grid
            item
            xl={3}
            lg={3}
            md={3}
            sm={12}
            xs={12}
            className={classes.cardMetricStyle}
          >
            <CreateCampaign />
          </Grid>
          <Grid
            item
            xl={3}
            lg={3}
            md={3}
            sm={12}
            xs={12}
            className={classes.cardMetricStyle}
          >
            <ConsultCampaign />
          </Grid>
          <Grid
            item
            xl={3}
            lg={3}
            md={3}
            sm={12}
            xs={12}
            className={classes.cardMetricStyle}
          >
            <TotalClients />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Grid container className={classes.metricContainerItems}>
          <Grid
            item
            xl={3}
            lg={3}
            md={3}
            sm={12}
            xs={12}
            className={classes.cardMetricStyle}
          >
            <TotalCreditMetrics />
          </Grid>
          <Grid
            item
            xl={3}
            lg={3}
            md={3}
            sm={12}
            xs={12}
            className={classes.cardMetricStyle}
          >
            <TotalCampaign />
          </Grid>
          <Grid
            item
            xl={3}
            lg={3}
            md={3}
            sm={12}
            xs={12}
            className={classes.cardMetricStyle}
          >
            <TotalCampaignActiveMetrics />
          </Grid>
          <Grid
            item
            xl={3}
            lg={3}
            md={3}
            sm={12}
            xs={12}
            className={classes.cardMetricStyle}
          >
            <TotalCampaignInactive />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Grid container className={classes.metricContainerItems}>
          <Grid
            item
            xl={3}
            lg={3}
            md={3}
            sm={12}
            xs={12}
            className={classes.cardMetricStyle}
          >
            <TotalCouponsActive />
          </Grid>
          <Grid
            item
            xl={3}
            lg={3}
            md={3}
            sm={12}
            xs={12}
            className={classes.cardMetricStyle}
          >
            <TotalCouponsUsed />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

import React, { useEffect } from "react";
import { Router } from "react-router-dom";

import Routes from "./routes";
import Footer from "./pages/footer";
import history from "./routes/history";

import { ContextHooks } from "./context";

import Theme from "./theme";

import { ThemeProvider, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    position: "relative",
  },
  content: {
    flexGrow: 1,
    paddingBottom: theme.spacing(8),
  },
}));

function App() {
  const classes = useStyles();

  useEffect(() => {
    if (window.location.hostname !== "localhost") {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag("js", new Date());
      gtag("config", process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
    } else {
      console.log("Google Analytics not initialized on localhost");
    }
  }, []);

  return (
    <ThemeProvider theme={Theme}>
      <ContextHooks>
        <Router history={history}>
          <div className={classes.root}>
            <div className={classes.content}>
              <Routes />
            </div>
            <Footer />
          </div>
        </Router>
      </ContextHooks>
    </ThemeProvider>
  );
}

export default App;

import { useState } from "react";

export default function UserHook() {
  const [userList, setUserList] = useState("");
  const [name, setName] = useState("");
  const [city, setCity] = useState("");
  const [document, setDocument] = useState("");
  const [cep, setCEP] = useState("");
  const [street, setStreet] = useState("");
  const [number, setNumber] = useState("");
  const [neighborhood, setNeighborhood] = useState("");
  const [complement, setComplement] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [userRole, setUserRole] = useState("common");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [errorName, setErrorName] = useState(false);
  const [errorDocument, setErrorDocument] = useState(false);
  const [errorStreet, setErrorStreet] = useState(false);
  const [errorNumber, setErrorNumber] = useState(false);
  const [errorNeighborhood, setErrorNeighborhood] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);
  const [errorPhone, setErrorPhone] = useState(false);
  const [errorPassword, setErrorPassword] = useState(false);
  const [errorConfirmPass, setErrorConfirmPass] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [showPassConfirm, setShowPassConfirm] = useState(false);
  const [userStatus, setUserStatus] = useState("active");
  const [isFromHome, setIsFromHome] = useState(false);
  const [loadingUser, setLoadingUser] = useState(true);
  const [credits, setCredits] = useState(0);
  const [availableManagers, setAvailableManagers] = useState([]);
  const [manager, setManager] = useState("");

  return {
    userList,
    setUserList,
    showPassConfirm,
    setShowPassConfirm,
    document,
    setDocument,
    errorDocument,
    setErrorDocument,
    loadingUser,
    setLoadingUser,
    name,
    userStatus,
    setUserStatus,
    setName,
    city,
    setCity,
    cep,
    setCEP,
    street,
    setStreet,
    number,
    setNumber,
    neighborhood,
    setNeighborhood,
    complement,
    setComplement,
    email,
    setEmail,
    phone,
    setPhone,
    userRole,
    setUserRole,
    password,
    setPassword,
    passwordConfirm,
    setPasswordConfirm,
    errorName,
    setErrorName,
    errorStreet,
    setErrorStreet,
    errorNumber,
    setErrorNumber,
    errorNeighborhood,
    setErrorNeighborhood,
    errorEmail,
    setErrorEmail,
    errorPhone,
    setErrorPhone,
    errorPassword,
    setErrorPassword,
    errorConfirmPass,
    setErrorConfirmPass,
    showPass,
    setShowPass,
    isFromHome,
    setIsFromHome,
    credits,
    setCredits,
    availableManagers,
    setAvailableManagers,
    manager,
    setManager,
  };
}

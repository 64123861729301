import { useState } from "react";

export default function SelectedIndex() {
  const [selectedIndex, setSelectedIndex] = useState([1, "Página Inicial"]);
  const [page, setPage] = useState("Página Inicial");
  const [barLeftOpen, setBarLeftOpen] = useState(false);
  const [openUsers, setOpenUsers] = useState(false);
  const [openPackage, setOpenPackage] = useState(false);
  const [openCampaign, setOpenCampaign] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);

  return {
    setSelectedIndex,
    selectedIndex,
    page,
    setPage,
    barLeftOpen,
    setBarLeftOpen,
    openUsers,
    setOpenUsers,
    openPackage,
    setOpenPackage,
    openCampaign,
    setOpenCampaign,
    tabIndex,
    setTabIndex,
  };
}

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Card, CardContent, Button } from "@material-ui/core";
import history from "../../routes/history";

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    width: 300,
    height: 160,
    borderRadius: 20,
    boxShadow: " 0px 0px 22px 1px rgba(0,0,0,0.74)",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.light,
    textAlign: "center",
  },
}));

export default function MySellers() {
  const classes = useStyles();

  const handleButton = () => {
    history.push("/sellers");
  };

  return (
    <Card className={classes.cardContainer}>
      <CardContent>
        <Typography variant="h5" gutterBottom style={{ paddingBottom: 25 }}>
          Meus vendedores
        </Typography>
        <Button variant="outlined" color="secondary" onClick={handleButton}>
          Consultar
        </Button>
      </CardContent>
    </Card>
  );
}

import { useState } from "react";

export default function EditHook() {
  const [valueEdit, setValueEdit] = useState("");
  const [isFromEdit, setIsFromEdit] = useState(false);

  return {
    valueEdit,
    setValueEdit,
    isFromEdit,
    setIsFromEdit,
  };
}

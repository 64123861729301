import React, { useState, useContext, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  TextField,
  Button,
  IconButton,
  InputAdornment,
  Paper,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";

import { Context } from "../../context";

import { ReactComponent as Logo } from "../../assets/cupomzito-vertical.svg";
import history from "../../routes/history";

const useStyles = makeStyles((theme) => ({
  logoStyle: {
    height: "200px",
    [theme.breakpoints.up("xxs")]: {
      width: "250px",
      marginBottom: 0,
    },
    [theme.breakpoints.up("xs")]: {
      width: "250px",
      marginBottom: 0,
    },
    [theme.breakpoints.up("sm")]: {
      width: "250px",
      marginBottom: 0,
    },
    [theme.breakpoints.up("md")]: {
      width: "250px",
      marginBottom: 0,
    },
    [theme.breakpoints.up("lg")]: {
      width: "350px",
      marginBottom: 40,
    },
    [theme.breakpoints.up("xl")]: {
      width: "350px",
      marginBottom: 40,
    },
  },
  buttonStyle: {
    width: "100%",
    height: "36px",
    marginBottom: 10,
    borderRadius: 10,
    fontWeight: "bold",
  },
  inputStyle: {
    width: "100%",
    marginBottom: 30,
    [`& fieldset`]: {
      borderRadius: 10,
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        [theme.breakpoints.up("xxs")]: {
          borderColor: theme.palette.primary.lightSecondary,
        },
        [theme.breakpoints.up("xs")]: {
          borderColor: theme.palette.primary.lightSecondary,
        },
        [theme.breakpoints.up("sm")]: {
          borderColor: theme.palette.primary.lightSecondary,
        },
        [theme.breakpoints.up("md")]: {
          borderColor: theme.palette.primary.lightSecondary,
        },
        [theme.breakpoints.up("lg")]: {
          borderColor: theme.palette.primary.lightSecondary,
        },
        [theme.breakpoints.up("xl")]: {
          borderColor: theme.palette.primary.lightSecondary,
        },
      },
      "&:hover fieldset": {
        [theme.breakpoints.up("xxs")]: {
          borderColor: theme.palette.primary.lightSecondary,
        },
        [theme.breakpoints.up("xs")]: {
          borderColor: theme.palette.primary.lightSecondary,
        },
        [theme.breakpoints.up("sm")]: {
          borderColor: theme.palette.primary.lightSecondary,
        },
        [theme.breakpoints.up("md")]: {
          borderColor: theme.palette.primary.lightSecondary,
        },
        [theme.breakpoints.up("lg")]: {
          borderColor: theme.palette.primary.lightSecondary,
        },
        [theme.breakpoints.up("xl")]: {
          borderColor: theme.palette.primary.lightSecondary,
        },
      },
    },
  },
  inputColor: {
    [theme.breakpoints.up("xxs")]: {
      color: theme.palette.primary.light,
    },
    [theme.breakpoints.up("xs")]: {
      color: theme.palette.primary.light,
    },
    [theme.breakpoints.up("sm")]: {
      color: theme.palette.primary.light,
    },
    [theme.breakpoints.up("md")]: {
      color: theme.palette.primary.light,
    },
    [theme.breakpoints.up("lg")]: {
      color: theme.palette.primary.light,
    },
    [theme.breakpoints.up("xl")]: {
      color: theme.palette.primary.light,
    },
  },
  notchedOutline: {
    backgroundColor: "transparent",
    [theme.breakpoints.up("xxs")]: {
      borderColor: theme.palette.primary.light,
      textColor: theme.palette.primary.light,
    },
    [theme.breakpoints.up("xs")]: {
      borderColor: theme.palette.primary.light,
      textColor: theme.palette.primary.light,
    },
    [theme.breakpoints.up("sm")]: {
      borderColor: theme.palette.primary.light,
      textColor: theme.palette.primary.light,
    },
    [theme.breakpoints.up("md")]: {
      borderColor: theme.palette.primary.light,
      textColor: theme.palette.primary.light,
    },
    [theme.breakpoints.up("lg")]: {
      borderColor: theme.palette.primary.light,
      textColor: theme.palette.primary.light,
    },
    [theme.breakpoints.up("xl")]: {
      borderColor: theme.palette.primary.light,
      textColor: theme.palette.primary.light,
    },
  },
  forgetPassword: {
    [theme.breakpoints.up("xxs")]: {
      color: theme.palette.primary.light,
    },
    [theme.breakpoints.up("xs")]: {
      color: theme.palette.primary.light,
    },
    [theme.breakpoints.up("sm")]: {
      color: theme.palette.primary.light,
    },
    [theme.breakpoints.up("md")]: {
      color: theme.palette.primary.light,
    },
    [theme.breakpoints.up("lg")]: {
      color: theme.palette.primary.light,
    },
    [theme.breakpoints.up("xl")]: {
      color: theme.palette.primary.light,
    },
  },
  loginSpan: {
    marginTop: 10,
    color: "#FFA07A",
  },
  hideOrShowPasswordIcon: {
    [theme.breakpoints.up("xxs")]: {
      color: theme.palette.primary.light,
    },
    [theme.breakpoints.up("xs")]: {
      color: theme.palette.primary.light,
    },
    [theme.breakpoints.up("sm")]: {
      color: theme.palette.primary.light,
    },
    [theme.breakpoints.up("md")]: {
      color: theme.palette.primary.light,
    },
    [theme.breakpoints.up("lg")]: {
      color: theme.palette.primary.light,
    },
    [theme.breakpoints.up("xl")]: {
      color: theme.palette.primary.light,
    },
  },
  paper: {
    width: "50%",
    height: "80%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    backgroundColor: "transparent",
    [theme.breakpoints.up("xxs")]: {
      backgroundColor: "transparent",
    },
    [theme.breakpoints.up("xs")]: {
      backgroundColor: "transparent",
    },
    [theme.breakpoints.up("sm")]: {
      backgroundColor: "transparent",
    },
    [theme.breakpoints.up("md")]: {
      backgroundColor: "transparent",
    },
    [theme.breakpoints.up("lg")]: {
      backgroundColor: theme.palette.primary.main,
    },
    [theme.breakpoints.up("xl")]: {
      backgroundColor: theme.palette.primary.main,
    },
  },
  circularProgress: {
    color: theme.palette.primary.main,
    position: "absolute",
    left: "31%",
    [theme.breakpoints.up("xxs")]: {
      left: "25%",
    },
    [theme.breakpoints.up("xs")]: {
      left: "25%",
    },
    [theme.breakpoints.up("sm")]: {
      left: "25%",
    },
    [theme.breakpoints.up("md")]: {
      left: "25%",
    },
    [theme.breakpoints.up("lg")]: {
      left: "31%",
    },
    [theme.breakpoints.up("xl")]: {
      left: "31%",
    },
  },
}));

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export default function MainLogin() {
  const classes = useStyles();
  const theme = useTheme();

  const {
    handleLogin,
    setErrorMsg,
    errorMsg,
    setPage,
    setSelectedIndex,
    setOpenUsers,
    setIsForgetPasswordVisible,
    loadingLogin,
    setLoadingLogin,
  } = useContext(Context);

  const [showPass, setShowPass] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [paperElevation, setPaperElevation] = useState(0);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    const newWindowDimensions = getWindowDimensions();
    function handleResize() {
      setWindowDimensions(newWindowDimensions);
    }

    const newPaperElevation =
      newWindowDimensions.width >= 0 && newWindowDimensions.width <= 300
        ? 0
        : newWindowDimensions.width > 300 && newWindowDimensions.width <= 600
        ? 0
        : newWindowDimensions.width > 600 && newWindowDimensions.width <= 900
        ? 0
        : newWindowDimensions.width > 900 && newWindowDimensions.width <= 1280
        ? 0
        : newWindowDimensions.width > 1280 && newWindowDimensions.width <= 1920
        ? 24
        : 24;

    setPaperElevation(newPaperElevation);

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [windowDimensions]);

  useEffect(() => {
    setLoadingLogin(false);
    setErrorMsg("");
  }, []);

  const onChangePassword = (e) => {
    setPassword(e.target.value);
  };

  const handleShowPassword = () => {
    setShowPass(!showPass);
  };

  const handleHiddePassword = (e) => {
    e.preventDefault();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoadingLogin(true);
    setErrorMsg("");
    setPage("Página Inicial");
    setSelectedIndex([1, "Página Inicial"]);
    setOpenUsers(false);

    if (email !== "" && password !== "") {
      handleLogin(email, password);
    } else {
      setLoadingLogin(false);
      var msg = "E-mail e/ou senha incorretos!";
      setErrorMsg(msg);
    }
  };

  useEffect(() => {
    setLoadingLogin(false);
  }, [errorMsg]);

  const handleForgetPasswordClick = () => {
    setIsForgetPasswordVisible(true);
  };

  const handleCreateAccountClick = () => {
    history.push("/create-account");
  };

  const handleSearchCoupons = () => {
    history.push("/");
  };

  return (
    <Paper elevation={paperElevation} square={false} className={classes.paper}>
      <motion.form
        initial={{ scale: 0 }}
        animate={{ rotate: 360, scale: 1 }}
        transition={{
          type: "spring",
          stiffness: 260,
          damping: 25,
        }}
        noValidate
        onSubmit={handleSubmit}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Link to="/">
          <Logo className={classes.logoStyle}></Logo>
        </Link>

        <TextField
          variant="outlined"
          id="user"
          required
          color="primary"
          label="E-mail"
          className={classes.inputStyle}
          value={email}
          InputProps={{
            classes: {
              input: classes.inputColor,
              notchedOutline: classes.notchedOutline,
            },
          }}
          InputLabelProps={{
            style: {
              color:
                windowDimensions.width >= 0 && windowDimensions.width <= 300
                  ? theme.palette.primary.light
                  : windowDimensions.width >= 300 &&
                    windowDimensions.width <= 600
                  ? theme.palette.primary.light
                  : windowDimensions.width >= 600 &&
                    windowDimensions.width <= 900
                  ? theme.palette.primary.light
                  : windowDimensions.width >= 900 &&
                    windowDimensions.width <= 1280
                  ? theme.palette.primary.light
                  : windowDimensions.width >= 1280 &&
                    windowDimensions.width <= 1920
                  ? theme.palette.primary.light
                  : theme.palette.primary.light,
            },
          }}
          onInput={(e) => setEmail(e.target.value)}
        />

        <TextField
          variant="outlined"
          id="pass"
          required
          label="Senha"
          type={showPass ? "text" : "password"}
          className={classes.inputStyle}
          autoComplete="current-password"
          onChange={onChangePassword}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleShowPassword}
                  onMouseDown={handleHiddePassword}
                >
                  {showPass ? (
                    <Visibility className={classes.hideOrShowPasswordIcon} />
                  ) : (
                    <VisibilityOff className={classes.hideOrShowPasswordIcon} />
                  )}
                </IconButton>
              </InputAdornment>
            ),
            classes: {
              input: classes.inputColor,
              notchedOutline: classes.notchedOutline,
            },
          }}
          InputLabelProps={{
            style: {
              color:
                windowDimensions.width >= 0 && windowDimensions.width <= 300
                  ? theme.palette.primary.light
                  : windowDimensions.width >= 300 &&
                    windowDimensions.width <= 600
                  ? theme.palette.primary.light
                  : windowDimensions.width >= 600 &&
                    windowDimensions.width <= 900
                  ? theme.palette.primary.light
                  : windowDimensions.width >= 900 &&
                    windowDimensions.width <= 1280
                  ? theme.palette.primary.light
                  : windowDimensions.width >= 1280 &&
                    windowDimensions.width <= 1920
                  ? theme.palette.primary.light
                  : theme.palette.primary.light,
            },
          }}
        />
        <Button
          type="submit"
          variant="contained"
          color="secondary"
          className={classes.buttonStyle}
        >
          {loadingLogin && (
            <CircularProgress
              className={`${classes.circularProgress}`}
              size={24}
            />
          )}
          entrar
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          className={classes.buttonStyle}
          onClick={handleCreateAccountClick}
        >
          Cadastre-se
        </Button>

        <Typography
          variant="body1"
          className={classes.forgetPassword}
          gutterBottom
        >
          Esqueceu sua senha?{" "}
          <a
            href="#"
            className={classes.forgetPassword}
            onClick={handleForgetPasswordClick}
          >
            Clique aqui
          </a>
        </Typography>

        <Button
          variant="outlined"
          color="secondary"
          className={classes.buttonStyle}
          onClick={handleSearchCoupons}
          style={{ width: 120, marginTop: 15 }}
        >
          <ArrowBackIosNewOutlinedIcon style={{ marginRight: 10 }} />
          Voltar
        </Button>

        <motion.div
          className="box"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          style={{ position: "relative" }}
        >
          <Typography
            variant="body1"
            style={{
              marginTop: 10,
              minWidth: 350,
              textAlign: "center",
              position: "absolute",
              left: "50%",
              transform: "translate(-50%)",
            }}
            className={classes.loginSpan}
          >
            {errorMsg}
          </Typography>
        </motion.div>
      </motion.form>
    </Paper>
  );
}

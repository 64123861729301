import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Link } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    bottom: 0,
    left: 0,
    right: 0,
    padding: `${theme.spacing(2)}px !important`,
    position: "fixed",
    zIndex: 999,
  },
}));

const Footer = () => {
  const classes = useStyles();

  return (
    <div className={classes.footer}>
      <Typography variant="body2">
        &copy; CupomZito {new Date().getFullYear()}
      </Typography>
    </div>
  );
};

export default Footer;

class GenerateCurrentTime {
  static getCurrentTime() {
    const currentTimestamp = Date.now();

    const offset = -3 * 60;
    
    const adjustedTimestamp = currentTimestamp + offset * 60 * 1000;

    return adjustedTimestamp;
  }
}

export default GenerateCurrentTime;

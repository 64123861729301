import { ptBR } from "@material-ui/core/locale";
import { unstable_createMuiStrictModeTheme as createMuiTheme } from "@material-ui/core";

const theme = createMuiTheme(
  {
    palette: {
      primary: {
        main: "#24323d",
        mainSecondary: "#354e5d",
        light: "#FFFFFF",
        lightSecondary: "#CCCCCC",
      },
      secondary: {
        main: "#FFA500",
        light: "#ffb400",
        //old main: ffa101
      },
      error: {
        main: "#FFA07A",
      },
      warning: {
        main: "#FF9800",
      },
      info: {
        main: "#ffff00",
      },
      success: {
        main: "#4CAF50",
      },
      divider: "#000000",
      background: {
        default: "#FAFAFA",
      },
      text: {
        primary: "#000000",
      },
      dialog: {
        primary: "#808080",
      },
    },
    breakpoints: {
      values: {
        xxs: 0,
        xs: 300, // phone
        sm: 600, // tablets
        md: 960, // small laptop
        lg: 1280, // desktop
        xl: 1920, // large screens
      },
    },
    overrides: {
      MUIDataTableSelectCell: {
        root: {
          backgroundColor: "#e9ecf4",
        },
        expandDisabled: {
          // Soft hide the button.
          visibility: "hidden",
        },
        headerCell: {
          backgroundColor: "#e9ecf4",
        },
      },
      MUIDataTableBodyRow: {
        root: {
          "&:nth-child(odd)": {
            backgroundColor: "#e9ecf4",
          },
        },
      },
      MUIDataTableFilter: {
        root: {
          width: "500px",
        },
      },
      MuiDialog: {
        paper: {
          zIndex: 9999,
        },
      },
    },
  },
  ptBR
);

export default theme;

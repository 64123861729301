import React, { useContext, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  TextField,
  FormControl,
  InputAdornment,
  IconButton,
  Tooltip,
  Typography,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  CircularProgress,
} from "@material-ui/core";

import { Context } from "../../context";
import Autocomplete from "@mui/material/Autocomplete";

import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import ClearIcon from "@material-ui/icons/Clear";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";

import { useEffect } from "react";
import history from "../../routes/history";
import Api from "../../service";
import PhoneFormatter from "../../utils/PhoneFormatter";
import GenerateCurrentTime from "../../utils/GenerateCurrentTime";
import DocumentFormatter from "../../utils/DocumentFormatter";

const useStyles = makeStyles((theme) => ({
  inputMaior: {
    marginBottom: 20,
    width: "100%",
    [`& fieldset`]: {
      borderRadius: 20,
      borderWidth: 2,
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.primary.lightSecondary,
        borderWidth: 2,
      },
      "&:hover fieldset": {
        borderColor: theme.palette.primary.lightSecondary,
        borderWidth: 2,
      },
    },
    "& .MuiOutlinedInput-root.Mui-disabled": {
      "& fieldset": {
        borderColor: "#808080",
      },
      "& input": {
        color: "#808080",
      },
    },
  },
  root: {
    display: "flex",
    justifyContent: "center",
    paddingLeft: 20,
    paddingRight: 20,
    [theme.breakpoints.up("xxs")]: {
      marginBottom: "30px",
      marginTop: 110,
      maxWidth: "100vw",
    },
    [theme.breakpoints.up("xs")]: {
      marginBottom: "30px",
      marginTop: 110,
      maxWidth: "100vw",
    },
    [theme.breakpoints.up("sm")]: {
      marginBottom: "30px",
      marginTop: 110,
      maxWidth: "100vw",
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: "30px",
      marginTop: 110,
      maxWidth: "100vw",
    },
    [theme.breakpoints.up("lg")]: {
      marginBottom: "30px",
      marginTop: 150,
      maxWidth: "40vw",
    },
    [theme.breakpoints.up("xl")]: {
      marginBottom: "30px",
      marginTop: 150,
      maxWidth: "40vw",
    },
  },
  hover: {
    "&:hover": {
      color: "#44533f",
    },
    color: "white",
  },
  autocomplete: {
    "& .MuiAutocomplete-inputRoot": {
      minHeight: "56px",
    },
  },
  inputColor: {
    color: theme.palette.primary.light,
  },
  notchedOutline: {
    backgroundColor: "transparent",
    borderColor: theme.palette.primary.light,
    textColor: theme.palette.primary.light,
  },
  clearIndicator: {
    color: theme.palette.primary.light, // Cor do botão "x"
  },
  popupIndicator: {
    color: theme.palette.primary.light, // Cor da seta
  },
  title: {
    color: theme.palette.primary.light,
    textAlign: "center",
    marginTop: 20,
  },
  buttonInput: {
    width: "70%",
    marginBottom: 10,
    fontWeight: "bold",
    [theme.breakpoints.up("xxs")]: {
      width: "100%",
    },
    [theme.breakpoints.up("xs")]: {
      width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
    [theme.breakpoints.up("lg")]: {
      width: "70%",
    },
    [theme.breakpoints.up("xl")]: {
      width: "70%",
    },
  },
}));

export default function CreateAccount() {
  const [inputValue, setInputValue] = useState("");
  const [cities, setCities] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [confirmEmail, setConfirmEmail] = useState("");
  const [confirmEmailError, setConfirmEmailError] = useState("");
  const [loadingConfirmResend, setLoadingConfirmResend] = useState(false);
  const [hasError, setHasError] = useState(false);

  const {
    name,
    setName,
    city,
    setCity,
    cep,
    setCEP,
    document,
    setDocument,
    errorDocument,
    setErrorDocument,
    street,
    setStreet,
    number,
    setNumber,
    neighborhood,
    setNeighborhood,
    complement,
    setComplement,
    setEmail,
    phone,
    setPhone,
    email,
    passwordConfirm,
    password,
    setPassword,
    setPasswordConfirm,
    errorName,
    setErrorName,
    errorStreet,
    setErrorStreet,
    errorNumber,
    setErrorNumber,
    errorNeighborhood,
    setErrorNeighborhood,
    errorEmail,
    setErrorEmail,
    errorPhone,
    setErrorPhone,
    errorPassword,
    setErrorPassword,
    errorConfirmPass,
    setErrorConfirmPass,
    showPass,
    setShowPass,
    showPassConfirm,
    setShowPassConfirm,
    isFromMyProfile,
    cookie,
    setIsFromMyProfile,
    setCookie,
    setUserInfos,
  } = useContext(Context);

  useEffect(() => {
    let isMounted = true; // Flag para verificar se o componente está montado
    if (cookie.userInfo && Object.keys(cookie).length !== 0) {
      setIsFromMyProfile(true);
      setName(cookie.userInfo[0].fullName);
      setEmail(cookie.userInfo[0].email);
      setPhone(cookie.userInfo[0].phone);
      setCity(
        `${cookie.userInfo[0].address.city} / ${cookie.userInfo[0].address.state}`
      );
      setCEP(cookie.userInfo[0].address.cep);
      setStreet(cookie.userInfo[0].address.street);
      setNumber(cookie.userInfo[0].address.number);
      setNeighborhood(cookie.userInfo[0].address.neighborhood);
      setComplement(cookie.userInfo[0].address.complement);
      setDocument(cookie.userInfo[0].document);
      setPassword("");
      setPasswordConfirm("");
    } else {
      setName("");
      setEmail("");
      setPhone("");
      setCity("");
      setCEP("");
      setStreet("");
      setNumber("");
      setNeighborhood("");
      setComplement("");
      setDocument("");
      setPassword("");
      setPasswordConfirm("");
    }

    setConfirmEmail("");
    setErrorMessage("");
    setErrorName(false);
    setErrorStreet(false);
    setErrorNumber(false);
    setErrorNeighborhood(false);
    setErrorEmail(false);
    setErrorPhone(false);
    setErrorPassword(false);
    setErrorConfirmPass(false);
    setErrorDocument(false);
    setConfirmEmailError(false);
    setLoadingConfirmResend(false);
    setHasError(false);

    const fetchCities = async () => {
      try {
        const response = await fetch(
          `https://servicodados.ibge.gov.br/api/v1/localidades/municipios?orderBy=nome`
        );
        if (response.ok) {
          const data = await response.json();
          if (isMounted) {
            const cityNames = data.map((city) => {
              return `${city.nome} / ${city.microrregiao.mesorregiao.UF.sigla}`;
            });
            setCities(cityNames);
          }
        } else {
          console.error("Erro ao buscar cidades");
        }
      } catch (error) {
        if (isMounted) {
          console.error("Erro na requisição: ", error);
        }
      }
    };

    fetchCities();

    return () => {
      isMounted = false; // Define a flag como false quando o componente for desmontado
    };
  }, []); // eslint-disable-line

  const classes = useStyles();

  const handleClickShowPass = () => {
    setShowPass(!showPass);
  };

  const handleClickShowPassConfirm = () => {
    setShowPassConfirm(!showPassConfirm);
  };

  const handleCloseModal = (value) => {
    if (value === "fechar") {
      setOpenModal(false);
      if (!hasError) {
        history.push("/");
      }
    } else if (value === "continue") {
      setOpenModal(false);
      history.push("/confirm-account");
    } else if (value === "resend") {
      setLoadingConfirmResend(true);
      Api.put("/api/auth/confirmation", {
        email: email,
        fullName: name.trim(),
      })
        .then((res) => {
          setErrorMessage(res.data.message);
          setLoadingConfirmResend(false);
        })
        .catch((err) => {
          if (err.response && err.response.data && err.response.data.message) {
            setErrorMessage(err.response.data.message);
          } else {
            setErrorMessage("Um erro inexperado aconteceu.");
          }
          setLoadingConfirmResend(false);
        });
    } else if ("so-fechar") {
      setOpenModal(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (errorDocument) {
      setErrorMessage(`Insira um documento válido.`);
      setHasError(true);
      setOpenModal(true);
    } else if (email !== confirmEmail && !isFromMyProfile) {
      setErrorMessage(`Os E-mails não coincidem.`);
      setConfirmEmailError(true);
      setHasError(true);
      setOpenModal(true);
    } else {
      setHasError(false);
      if (!isFromMyProfile) {
        if (password === passwordConfirm && password !== "") {
          Api.post("/api/auth/signup/client", {
            fullName: name.trim(),
            password: password,
            manager: null,
            document: document.includes("_")
              ? document.slice(0, document.length - 1)
              : document,
            phone: phone.includes("_")
              ? phone.slice(0, phone.length - 1)
              : phone,
            email: email,
            address: {
              cep: cep,
              city: city.split(" / ")[0],
              state: city.split(" / ")[1],
              street: street,
              number: number,
              neighborhood: neighborhood,
              complement: complement,
            },
            timestamp: GenerateCurrentTime.getCurrentTime(),
          })
            .then((res) => {
              setErrorMessage(res.data.message);
              setOpenModal(true);
              setHasError(false);
            })
            .catch((err) => {
              if (
                err.response &&
                err.response.data &&
                err.response.data.message
              ) {
                setErrorMessage(err.response.data.message);
              } else {
                setErrorMessage("Um erro inexperado aconteceu.");
              }
              setOpenModal(true);
              setHasError(true);
            });
        } else if (password === passwordConfirm && password === "") {
          Api.post("/api/auth/signup/client", {
            fullName: name.trim(),
            manager: null,
            document: document.includes("_")
              ? document.slice(0, document.length - 1)
              : document,
            phone: phone.includes("_")
              ? phone.slice(0, phone.length - 1)
              : phone,
            email: email,
            address: {
              cep: cep,
              city: city.split(" / ")[0],
              state: city.split(" / ")[1],
              street: street,
              number: number,
              neighborhood: neighborhood,
              complement: complement,
            },
            timestamp: GenerateCurrentTime.getCurrentTime(),
          })
            .then((res) => {
              setErrorMessage(res.data.message);
              setOpenModal(true);
              setHasError(false);
            })
            .catch((err) => {
              if (
                err.response &&
                err.response.data &&
                err.response.data.message
              ) {
                setErrorMessage(err.response.data.message);
              } else {
                setErrorMessage("Um erro inexperado aconteceu.");
              }
              setOpenModal(true);
              setHasError(true);
            });
        } else {
          setErrorMessage("As senhas não coincidem");
          setOpenModal(true);
          setHasError(true);
        }
      } else {
        if (password === passwordConfirm && password !== "") {
          const data = {
            _id: cookie.userInfo[0]._id,
            fullName: name.trim(),
            password: password,
            role: cookie.userInfo[0].role,
            credit: cookie.userInfo[0].credit,
            document: cookie.userInfo[0].document,
            phone: phone.includes("_")
              ? phone.slice(0, phone.length - 1)
              : phone,
            email: email,
            address: {
              cep: cep,
              street: street,
              neighborhood: neighborhood,
              city: city.split(" / ")[0],
              state: city.split(" / ")[1],
              complement: complement,
              number: number,
            },
          };

          Api.put("/api/user", data)
            .then((res) => {
              registerLog(true, "client_update_user");
              setErrorMessage(res.data.message);
              setOpenModal(true);
              setHasError(false);
            })
            .catch((err) => {
              setErrorMessage(err.response.data.message);
              setOpenModal(true);
              setHasError(true);
            });

          setCookie("userInfo", [data], { expire: 1, path: "/" });
          setUserInfos([data]);
        } else if (password === passwordConfirm && password === "") {
          const data = {
            _id: cookie.userInfo[0]._id,
            fullName: name.trim(),
            phone: phone.includes("_")
              ? phone.slice(0, phone.length - 1)
              : phone,
            email: email,
            role: cookie.userInfo[0].role,
            credit: cookie.userInfo[0].credit,
            document: cookie.userInfo[0].document,
            address: {
              cep: cep,
              street: street,
              neighborhood: neighborhood,
              city: city.split(" / ")[0],
              state: city.split(" / ")[1],
              complement: complement,
              number: number,
            },
          };

          Api.put("/api/user", data)
            .then((res) => {
              registerLog(false, "client_update_user");
              setErrorMessage(res.data.message);
              setOpenModal(true);
              setHasError(false);
            })
            .catch((err) => {
              setErrorMessage(err.response.data.message);
              setOpenModal(true);
              setHasError(true);
            });

          setCookie("userInfo", [data], { expire: 1, path: "/" });
          setUserInfos([data]);
        } else {
          setErrorMessage("As senhas não coincidem");
          setOpenModal(true);
          setHasError(true);
        }
      }
    }
  };

  function registerLog(passwordChanged, action) {
    Api.post("/api/log", {
      userId: cookie.userInfo[0]._id,
      action: action,
      data: `
        userId: ${cookie.userInfo[0]._id},
        fullName: ${name.trim()},
        phone: ${phone.includes("_") ? phone.slice(0, phone.length - 1) : phone}
        city: ${city.split(" / ")[0]},
        state: ${city.split(" / ")[1]},
        street: ${street},
        number: ${number},
        neighborhood: ${neighborhood},
        complement: ${complement},
        passwordChanged: ${passwordChanged},
      `,
      timestamp: GenerateCurrentTime.getCurrentTime(),
    })
      .then(() => {
        //success
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const handleBack = (e) => {
    history.push("/");
  };

  return (
    <div style={{ justifyContent: "center", display: "flex" }}>
      <form onSubmit={(e) => handleSubmit(e)}>
        <Grid container spacing={1} className={classes.root}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Typography className={classes.title} variant="h5" gutterBottom>
              {cookie.userInfo && Object.keys(cookie).length !== 0
                ? "Meu Perfil"
                : "Cadastre-se"}
            </Typography>
            <Typography
              className={classes.title}
              variant="body1"
              gutterBottom
              style={{ paddingLeft: 30, paddingRight: 30, marginBottom: 40 }}
            >
              {cookie.userInfo && Object.keys(cookie).length !== 0
                ? "Mantenha-se atualizado para não perder nenhuma novidade!"
                : "Garanta seus cupons a qualquer momento e de qualquer lugar"}
            </Typography>
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <TextField
              id="fullName"
              required
              value={name || ""}
              error={!!errorName}
              style={{ marginRight: 10 }}
              onInvalid={(e) => {
                e.target.setCustomValidity("O campo Nome é obrigatório");
                setErrorName(true);
              }}
              onInput={(e) => {
                e.target.setCustomValidity("");
                setName(e.target.value);
                setErrorName(false);
              }}
              InputProps={{
                classes: {
                  input: classes.inputColor,
                  notchedOutline: classes.notchedOutline,
                },
              }}
              InputLabelProps={{
                style: {
                  color: "white",
                },
              }}
              label="Nome"
              variant="outlined"
              className={classes.inputMaior}
            />
          </Grid>

          <Grid item xl={3} lg={3} md={3} sm={6} xs={6}>
            <TextField
              id={DocumentFormatter.defineFormatLabel(document)}
              required
              value={DocumentFormatter.formatDocument(document)}
              error={errorDocument}
              disabled={isFromMyProfile}
              onInvalid={(e) => {
                e.target.setCustomValidity("O documento inserido não é válido");
                setErrorDocument(true);
              }}
              onInput={(e) => {
                e.target.setCustomValidity("");
                const value = e.target.value;
                setDocument(value);
                const isValid = DocumentFormatter.isValidDocument(value);
                setErrorDocument(!isValid);
              }}
              InputProps={{
                classes: {
                  input: classes.inputColor,
                  notchedOutline: classes.notchedOutline,
                },
              }}
              InputLabelProps={{
                style: {
                  color: "white",
                },
              }}
              label={DocumentFormatter.defineFormatLabel(document)}
              variant="outlined"
              className={classes.inputMaior}
            />
          </Grid>
          <Grid item xl={3} lg={3} md={3} sm={6} xs={6}>
            <TextField
              id="phone"
              required
              value={PhoneFormatter.formatPhoneNumber(phone)}
              error={!!errorPhone}
              onChange={(e) => {
                setPhone(PhoneFormatter.formatPhoneNumber(e.target.value));
                setErrorPhone(false);
              }}
              type="tel"
              onInvalid={() => setErrorPhone(true)}
              InputProps={{
                classes: {
                  input: classes.inputColor,
                  notchedOutline: classes.notchedOutline,
                },
              }}
              InputLabelProps={{
                style: {
                  color: "white",
                },
              }}
              label="Telefone"
              variant="outlined"
              fullWidth
              inputProps={{ maxLength: 15 }}
              className={classes.inputMaior}
            />
          </Grid>
          <Grid
            item
            xl={!isFromMyProfile ? 6 : 12}
            lg={!isFromMyProfile ? 6 : 12}
            md={!isFromMyProfile ? 6 : 12}
            sm={12}
            xs={12}
          >
            <TextField
              id="email"
              required
              value={email || ""}
              error={!!errorEmail}
              disabled={isFromMyProfile}
              type="email"
              onInvalid={(e) => {
                e.target.setCustomValidity("O campo E-mail é obrigatório");
                setErrorEmail(true);
              }}
              onInput={(e) => {
                e.target.setCustomValidity("");
                setEmail(e.target.value);
                setErrorEmail(false);
              }}
              InputProps={{
                classes: {
                  input: classes.inputColor,
                  notchedOutline: classes.notchedOutline,
                },
              }}
              InputLabelProps={{
                style: {
                  color: "white",
                },
              }}
              label="E-mail"
              variant="outlined"
              className={classes.inputMaior}
            />
          </Grid>
          {!isFromMyProfile && (
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <TextField
                id="confirmEmail"
                required
                value={confirmEmail || ""}
                error={!!confirmEmailError}
                type="email"
                onInvalid={(e) => {
                  e.target.setCustomValidity("Os e-mails não coincidem");
                  setConfirmEmailError(true);
                }}
                onInput={(e) => {
                  e.target.setCustomValidity("");
                  setConfirmEmail(e.target.value);
                  if (e.target.value !== email) {
                    setConfirmEmailError(true);
                  } else {
                    e.target.setCustomValidity("");
                    setConfirmEmailError(false);
                  }
                }}
                InputProps={{
                  classes: {
                    input: classes.inputColor,
                    notchedOutline: classes.notchedOutline,
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: "white",
                  },
                }}
                label="Confirmar E-mail"
                variant="outlined"
                className={classes.inputMaior}
              />
            </Grid>
          )}

          <Grid item xl={2} lg={2} md={2} sm={4} xs={4}>
            <TextField
              id="cep"
              type="cep"
              value={cep || ""}
              onInput={(e) => {
                setCEP(e.target.value);
              }}
              InputProps={{
                classes: {
                  input: classes.inputColor,
                  notchedOutline: classes.notchedOutline,
                },
              }}
              InputLabelProps={{
                style: {
                  color: "white",
                },
              }}
              label="CEP"
              variant="outlined"
              className={classes.inputMaior}
            />
          </Grid>

          <Grid item xl={4} lg={4} md={4} sm={8} xs={8}>
            <FormControl variant="outlined" className={classes.inputMaior}>
              <Autocomplete
                value={city || ""}
                onChange={(event, newValue) => {
                  setCity(newValue);
                }}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                options={cities}
                getOptionLabel={(option) => option || ""}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    label="Cidade"
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      classes: {
                        input: classes.inputColor,
                        notchedOutline: classes.notchedOutline,
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        color: "white",
                      },
                    }}
                  />
                )}
                className={classes.autocomplete}
                clearOnEscape
                clearIcon={<ClearIcon className={classes.clearIndicator} />}
                popupIcon={
                  <ExpandMoreIcon className={classes.popupIndicator} />
                }
              />
            </FormControl>
          </Grid>
          <Grid item xl={2} lg={2} md={2} sm={3} xs={3}>
            <TextField
              id="number"
              required
              value={number || ""}
              error={errorNumber}
              onInvalid={(e) => {
                e.target.setCustomValidity("O campo Número é obrigatório");
                setErrorNumber(true);
              }}
              onInput={(e) => {
                e.target.setCustomValidity("");
                setNumber(e.target.value);
                setErrorNumber(false);
              }}
              InputProps={{
                classes: {
                  input: classes.inputColor,
                  notchedOutline: classes.notchedOutline,
                },
              }}
              InputLabelProps={{
                style: {
                  color: "white",
                },
              }}
              label="Nº"
              variant="outlined"
              className={classes.inputMaior}
            />
          </Grid>
          <Grid item xl={4} lg={4} md={4} sm={9} xs={9}>
            <TextField
              id="complement"
              value={complement || ""}
              onInput={(e) => {
                setComplement(e.target.value);
              }}
              InputProps={{
                classes: {
                  input: classes.inputColor,
                  notchedOutline: classes.notchedOutline,
                },
              }}
              InputLabelProps={{
                style: {
                  color: "white",
                },
              }}
              label="Complemento"
              variant="outlined"
              className={classes.inputMaior}
            />
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
            <TextField
              id="neighborhood"
              required
              value={neighborhood || ""}
              error={errorNeighborhood}
              onInvalid={(e) => {
                e.target.setCustomValidity("O campo Bairro é obrigatório");
                setErrorNeighborhood(true);
              }}
              onInput={(e) => {
                e.target.setCustomValidity("");
                setNeighborhood(e.target.value);
                setErrorNeighborhood(false);
              }}
              InputProps={{
                classes: {
                  input: classes.inputColor,
                  notchedOutline: classes.notchedOutline,
                },
              }}
              InputLabelProps={{
                style: {
                  color: "white",
                },
              }}
              label="Bairro"
              variant="outlined"
              className={classes.inputMaior}
            />
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
            <TextField
              id="street"
              required
              value={street || ""}
              error={errorStreet}
              onInvalid={(e) => {
                e.target.setCustomValidity("O campo Rua é obrigatório");
                setErrorStreet(true);
              }}
              onInput={(e) => {
                e.target.setCustomValidity("");
                setStreet(e.target.value);
                setErrorStreet(false);
              }}
              InputProps={{
                classes: {
                  input: classes.inputColor,
                  notchedOutline: classes.notchedOutline,
                },
              }}
              InputLabelProps={{
                style: {
                  color: "white",
                },
              }}
              label="Rua"
              variant="outlined"
              className={classes.inputMaior}
            />
          </Grid>

          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <TextField
              id="password"
              value={password || ""}
              error={!!errorPassword}
              required={!isFromMyProfile ? true : false}
              type={showPass ? "text" : "password"}
              label="Nova Senha"
              onInvalid={(e) => {
                if (!isFromMyProfile) {
                  e.target.setCustomValidity("O campo Senha é obrigatório");
                  setErrorPassword(true);
                }
              }}
              onInput={(e) => {
                e.target.setCustomValidity("");
                setErrorPassword(false);
                setPassword(e.target.value);
              }}
              InputLabelProps={{
                style: {
                  color: "white",
                },
              }}
              variant="outlined"
              className={classes.inputMaior}
              autoComplete="new-password"
              InputProps={{
                endAdornment: (
                  <Tooltip
                    title={!showPass ? "Visualizar senha" : "Esconder Senha"}
                  >
                    <InputAdornment position="end">
                      <IconButton
                        className={classes.hover}
                        onClick={handleClickShowPass}
                      >
                        {showPass ? (
                          <Visibility
                            style={{ color: errorPassword && "red" }}
                          />
                        ) : (
                          <VisibilityOff
                            style={{ color: errorPassword && "red" }}
                          />
                        )}
                      </IconButton>
                    </InputAdornment>
                  </Tooltip>
                ),
                classes: {
                  input: classes.inputColor,
                  notchedOutline: classes.notchedOutline,
                },
              }}
            />
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <TextField
              id="passwordConfirm"
              value={passwordConfirm || ""}
              error={!!errorConfirmPass}
              type={showPassConfirm ? "text" : "password"}
              label="Confirmar Senha"
              onInvalid={(e) => {
                e.target.setCustomValidity("As senhas não coincidem");
                setErrorConfirmPass(true);
              }}
              onInput={(e) => {
                setPasswordConfirm(e.target.value);
                if (e.target.value !== password) {
                  setErrorConfirmPass(true);
                } else {
                  setErrorConfirmPass(false);
                }
              }}
              InputLabelProps={{
                style: {
                  color: "white",
                },
              }}
              variant="outlined"
              className={classes.inputMaior}
              autoComplete="new-password"
              InputProps={{
                endAdornment: (
                  <Tooltip
                    title={
                      !showPassConfirm ? "Visualizar senha" : "Esconder Senha"
                    }
                  >
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowPassConfirm}
                        className={classes.hover}
                      >
                        {showPassConfirm ? (
                          <Visibility
                            style={{ color: errorConfirmPass && "red" }}
                          />
                        ) : (
                          <VisibilityOff
                            style={{ color: errorConfirmPass && "red" }}
                          />
                        )}
                      </IconButton>
                    </InputAdornment>
                  </Tooltip>
                ),
                classes: {
                  input: classes.inputColor,
                  notchedOutline: classes.notchedOutline,
                },
              }}
            />
          </Grid>

          <Grid
            item
            xl={6}
            lg={6}
            md={6}
            sm={12}
            xs={12}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Button
              variant="outlined"
              color="secondary"
              className={`${classes.buttonInput}`}
              onClick={(e) => handleBack(e)}
            >
              <ArrowBackIosNewOutlinedIcon style={{ marginRight: 10 }} />
              Voltar
            </Button>
          </Grid>
          <Grid
            item
            xl={6}
            lg={6}
            md={6}
            sm={12}
            xs={12}
            style={{ display: "flex", justifyContent: "flex-start" }}
          >
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              className={`${classes.buttonInput}`}
              style={{ color: "white" }}
            >
              <CheckOutlinedIcon style={{ marginRight: 10 }} />
              salvar
            </Button>
          </Grid>
        </Grid>

        <Dialog
          open={openModal}
          onClose={() => handleCloseModal("so-fechar")}
          fullWidth={true}
          maxWidth="sm"
        >
          <DialogTitle>
            <strong>
              <em>Atenção:</em>
            </strong>
          </DialogTitle>
          <DialogContent style={{ textAlign: "center", minHeight: 150 }}>
            {loadingConfirmResend ? (
              <div
                style={{
                  height: "150px",
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <div
                style={{
                  height: "150px",
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography variant="h6" style={{ color: "gray" }}>
                  {errorMessage}
                </Typography>
              </div>
            )}
          </DialogContent>
          <DialogActions>
            {isFromMyProfile ? (
              <>
                <Button
                  onClick={() => handleCloseModal("fechar")}
                  style={{ color: "#808080" }}
                >
                  <strong>Fechar</strong>
                </Button>
              </>
            ) : hasError ? (
              <>
                <Button
                  onClick={() => handleCloseModal("fechar")}
                  style={{ color: "#808080" }}
                >
                  <strong>Fechar</strong>
                </Button>
              </>
            ) : (
              <>
                <Button
                  onClick={() => handleCloseModal("resend")}
                  variant="outlined"
                  color="secondary"
                >
                  <strong>Reenviar</strong>
                </Button>
                <Button
                  onClick={() => handleCloseModal("continue")}
                  variant="contained"
                  color="secondary"
                  style={{ color: "white", fontWeight: "bold" }}
                >
                  <strong>Confirmar</strong>
                </Button>
              </>
            )}
          </DialogActions>
        </Dialog>
      </form>
    </div>
  );
}

import React, { useContext } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import CircularProgress from "@material-ui/core/CircularProgress";

import { Context } from "../context";

import Login from "../pages/login";
import Create from "../pages/create";
import Main from "../pages/main";
import Invalid from "../pages/invalid";
import User from "../components/user";
import Package from "../components/package";
import Edit from "../pages/edit";
import UserDetails from "../components/user/userDetails";
import StoreCampaigns from "../components/campaign/store";
import CreateSellerFromPartner from "../components/partner";
import SeeSellers from "../components/partner/seeSellers";
import CreateStoreFromSeller from "../components/seller";
import SeeStores from "../components/seller/seeStores";
import ForgetPassword from "../pages/forgetPassword";
import MainClient from "../pages/mainClient";
import CreateAccount from "../components/clients/createAccount";
import VerifyUser from "../components/clients/verifyUser";
import MyCoupons from "../components/clients/myCoupons";
import ConsultClients from "../components/campaign/store/consultClients";

function CustomRoute({ isPrivate, ...rest }) {
  const { authenticated, loading, cookie } = useContext(Context);
  if (loading) {
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            color: "white",
          }}
        >
          <div style={{ width: "100px", color: "white" }}>
            <h4>Carregando...</h4>
            <CircularProgress size={80} style={{ color: "white" }} />
          </div>
        </div>
      </>
    );
  }

  if (isPrivate && !authenticated) {
    return <Redirect to="/login" />;
  }

  if (
    (rest.path === "/create" ||
      rest.path === "/edit" ||
      rest.path === "/user") &&
    cookie.userInfo[0].role !== "admin"
  ) {
    return <Redirect to="/main" />;
  }

  if (rest.path === "/campaign" && cookie.userInfo[0].role !== "store") {
    return <Redirect to="/main" />;
  }

  if (
    (rest.path === "/create-seller" || rest.path === "/sellers") &&
    cookie.userInfo[0].role !== "partner"
  ) {
    return <Redirect to="/main" />;
  }

  if (
    (rest.path === "/create-store" || rest.path === "/stores") &&
    cookie.userInfo[0].role !== "seller"
  ) {
    return <Redirect to="/main" />;
  }

  if (
    rest.path === "/coupon" &&
    cookie.userInfo[0].role !== "store" &&
    cookie.userInfo[0].role !== "admin"
  ) {
    return <Redirect to="/main" />;
  }

  if (rest.path === "/consult-clients" && cookie.userInfo[0].role !== "store") {
    return <Redirect to="/main" />;
  }

  return rest.path === "/main" ? (
    <>
      <Main />
    </>
  ) : (
    <>
      <Main />
      <Route {...rest} />
    </>
  );
}

function ClientRoute({ isPrivate, ...rest }) {
  const { path } = rest;
  const { cookie } = useContext(Context);

  if (isPrivate && cookie.userInfo && Object.keys(cookie).length === 0) {
    return <Redirect to="/" />;
  }

  return (
    <>
      <MainClient />
      {path !== "/" && <Route {...rest} />}
    </>
  );
}

export default function Routes() {
  return (
    <Switch>
      <Route exact path="/" component={MainClient} />
      <Route exact path="/login" component={Login} />
      <CustomRoute isPrivate exact path="/main" component={Main} />
      <CustomRoute isPrivate exact path="/user" component={User} />
      <CustomRoute isPrivate exact path="/create" component={Create} />
      <CustomRoute isPrivate exact path="/edit" component={Edit} />
      <CustomRoute isPrivate exact path="/coupon" component={Package} />
      <CustomRoute
        isPrivate
        exact
        path="/user-details"
        component={UserDetails}
      />
      <CustomRoute
        isPrivate
        exact
        path="/campaign"
        component={StoreCampaigns}
      />
      <CustomRoute
        isPrivate
        exact
        path="/create-seller"
        component={CreateSellerFromPartner}
      />
      <CustomRoute isPrivate exact path="/sellers" component={SeeSellers} />
      <CustomRoute
        isPrivate
        exact
        path="/create-store"
        component={CreateStoreFromSeller}
      />
      <CustomRoute
        isPrivate
        exact
        path="/consult-clients"
        component={ConsultClients}
      />
      <CustomRoute isPrivate exact path="/stores" component={SeeStores} />
      <Route exact path="/error" component={Invalid} />
      <ClientRoute exact path="/create-account" component={CreateAccount} />
      <ClientRoute exact path="/confirm-account" component={VerifyUser} />
      <ClientRoute isPrivate exact path="/my-coupons" component={MyCoupons} />
      <Route exact path="/forget-password" component={ForgetPassword} />
      <Route render={() => <Redirect to={{ pathname: "/error" }} />} />
    </Switch>
  );
}

import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Card, CardContent, Button } from "@material-ui/core";
import history from "../../routes/history";

import { Context } from "../../context";

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    width: 300,
    height: 160,
    borderRadius: 20,
    boxShadow: " 0px 0px 22px 1px rgba(0,0,0,0.74)",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.light,
    textAlign: "center",
  },
}));

export default function CreateSellerCard() {
  const classes = useStyles();
  const { setIsFromEdit } = useContext(Context);

  const handleCreateButton = () => {
    setIsFromEdit(false);
    history.push("/create-seller");
  };

  return (
    <Card className={classes.cardContainer}>
      <CardContent>
        <Typography variant="h5" gutterBottom style={{ paddingBottom: 25 }}>
          Cadastrar vendedor
        </Typography>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleCreateButton}
        >
          Cadastrar
        </Button>
      </CardContent>
    </Card>
  );
}

class DocumentFormatter {
  static formatDocument(value) {
    const cleaned = value.replace(/\D/g, "");

    if (cleaned.length <= 11) {
      return cleaned.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    } else if (cleaned.length > 11) {
      return cleaned.replace(
        /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
        "$1.$2.$3/$4-$5"
      );
    }

    return cleaned;
  }

  static defineFormatLabel(value) {
    const cleaned = value.replace(/\D/g, "");

    if (cleaned.length <= 11) {
      return "CPF";
    } else if (cleaned.length > 11) {
      return "CNPJ";
    }

    return cleaned;
  }

  static isValidCPF(cpf) {
    cpf = cpf.replace(/\D/g, "");
    if (cpf.length !== 11 || /^(\d)\1{10}$/.test(cpf)) return false;

    for (let i = 9; i < 11; i++) {
      let sum = 0;
      for (let j = 0; j < i; j++) {
        sum += parseInt(cpf.charAt(j)) * (i + 1 - j);
      }
      let mod = (sum * 10) % 11;
      if (mod === 10 || mod === 11) mod = 0;
      if (mod !== parseInt(cpf.charAt(i))) return false;
    }
    return true;
  }

  static isValidCNPJ(cnpj) {
    cnpj = cnpj.replace(/\D/g, "");
    if (cnpj.length !== 14 || /^(\d)\1{13}$/.test(cnpj)) return false;

    let length = cnpj.length - 2;
    let numbers = cnpj.substring(0, length);
    let digits = cnpj.substring(length);
    let sum = 0;
    let pos = length - 7;

    for (let i = length; i >= 1; i--) {
      sum += numbers.charAt(length - i) * pos--;
      if (pos < 2) pos = 9;
    }
    let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
    if (result !== parseInt(digits.charAt(0))) return false;

    length = length + 1;
    numbers = cnpj.substring(0, length);
    sum = 0;
    pos = length - 7;

    for (let i = length; i >= 1; i--) {
      sum += numbers.charAt(length - i) * pos--;
      if (pos < 2) pos = 9;
    }
    result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
    if (result !== parseInt(digits.charAt(1))) return false;

    return true;
  }

  static isValidDocument(value) {
    const cleaned = value.replace(/\D/g, "");
    if (cleaned.length <= 11) {
      return this.isValidCPF(cleaned);
    } else if (cleaned.length > 11) {
      return this.isValidCNPJ(cleaned);
    }
    return false;
  }
}

export default DocumentFormatter;

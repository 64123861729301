import React, { useContext, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, TextField, FormControl } from "@material-ui/core";

import { Context } from "../../context";
import Autocomplete from "@mui/material/Autocomplete";

import Api from "../../service";

import { useEffect } from "react";
import PhoneFormatter from "../../utils/PhoneFormatter";
import DocumentFormatter from "../../utils/DocumentFormatter";

const useStyles = makeStyles((theme) => ({
  inputMaior: {
    marginBottom: 20,
    width: "100%",
  },
  inputStyle: {
    minWidth: "71vw",
    [theme.breakpoints.up("xxs")]: {
      minWidth: "64vw",
    },
    [theme.breakpoints.up("xs")]: {
      minWidth: "64vw",
    },
    [theme.breakpoints.up("sm")]: {
      minWidth: "50vw",
    },
    [theme.breakpoints.up("md")]: {
      minWidth: "58vw",
    },
    [theme.breakpoints.up("lg")]: {
      minWidth: "65vw",
    },
    [theme.breakpoints.up("xl")]: {
      minWidth: "71vw",
    },
  },
  hover: {
    "&:hover": {
      color: "#44533f",
    },
  },
  autocomplete: {
    "& .MuiAutocomplete-inputRoot": {
      minHeight: "56px",
    },
  },
}));

export default function CreateSeller() {
  const [inputValue, setInputValue] = useState("");
  const [cities, setCities] = useState([]);

  const {
    name,
    setName,
    city,
    setCity,
    cep,
    setCEP,
    street,
    setStreet,
    number,
    setNumber,
    neighborhood,
    setNeighborhood,
    complement,
    setComplement,
    setEmail,
    phone,
    setPhone,
    email,
    errorName,
    setErrorName,
    errorStreet,
    setErrorStreet,
    errorNumber,
    setErrorNumber,
    errorNeighborhood,
    setErrorNeighborhood,
    errorEmail,
    setErrorEmail,
    errorPhone,
    setErrorPhone,
    isFromEdit,
    cookie,
    setPage,
    setSelectedIndex,
    valueEdit,
    setUserStatus,
    document,
    setDocument,
    errorDocument,
    setErrorDocument,
  } = useContext(Context);

  useEffect(() => {
    setPage("Cadastrar Vendedor");
    setSelectedIndex([7, "Cadastrar Vendedor"]);
    if (!isFromEdit) {
      setName("");
      setEmail("");
      setPhone("");
      setCity(
        `${cookie.userInfo[0].address.city} / ${cookie.userInfo[0].address.state}`
      );
      setCEP("");
      setStreet("");
      setNumber("");
      setNeighborhood("");
      setComplement("");
      setDocument("");
    } else {
      Api.get("/api/user/detail", { params: { _id: valueEdit } })
        .then((res) => {
          setName(res.data[0].fullName);
          setEmail(res.data[0].email);
          setPhone(res.data[0].phone);
          setCity(`${res.data[0].address.city} / ${res.data[0].address.state}`);
          setCEP(res.data[0].address.cep);
          setStreet(res.data[0].address.street);
          setNumber(res.data[0].address.number);
          setNeighborhood(res.data[0].address.neighborhood);
          setComplement(res.data[0].address.complement);
          setUserStatus(res.data[0].status);
          setDocument(res.data[0].document);
        })
        .catch((err) => {
          console.error(err);
        });
    }
    setErrorName(false);
    setErrorStreet(false);
    setErrorNumber(false);
    setErrorNeighborhood(false);
    setErrorEmail(false);
    setErrorPhone(false);
    setErrorDocument(false);
  }, []);

  useEffect(() => {
    let isMounted = true; // Flag para verificar se o componente está montado

    const fetchCities = async () => {
      try {
        const response = await fetch(
          `https://servicodados.ibge.gov.br/api/v1/localidades/municipios?orderBy=nome`
        );
        if (response.ok) {
          const data = await response.json();
          if (isMounted) {
            const cityNames = data.map((city) => {
              return `${city.nome} / ${city.microrregiao.mesorregiao.UF.sigla}`;
            });
            setCities(cityNames);
          }
        } else {
          console.error("Erro ao buscar cidades");
        }
      } catch (error) {
        if (isMounted) {
          console.error("Erro na requisição: ", error);
        }
      }
    };

    fetchCities();

    return () => {
      isMounted = false; // Define a flag como false quando o componente for desmontado
    };
  }, [isFromEdit, cookie]); // eslint-disable-line

  const classes = useStyles();

  return (
    <>
      <Grid container className={classes.inputStyle}>
        <Grid container spacing={1}>
          <Grid item xl={7} lg={7} md={7} sm={12} xs={12}>
            <TextField
              id="fullName"
              required
              value={name || ""}
              error={!!errorName}
              style={{ marginRight: 10 }}
              onInvalid={(e) => {
                setErrorName(true);
              }}
              onInput={(e) => {
                setName(e.target.value);
                setErrorName(false);
              }}
              label="Nome"
              variant="outlined"
              className={classes.inputMaior}
            />
          </Grid>
          <Grid item xl={5} lg={5} md={5} sm={12} xs={12}>
            <TextField
              id={DocumentFormatter.defineFormatLabel(document)}
              required
              value={DocumentFormatter.formatDocument(document)}
              disabled={!isFromEdit ? false : true}
              error={errorDocument}
              onInvalid={(e) => {
                setErrorDocument(true);
              }}
              onInput={(e) => {
                const value = e.target.value;
                setDocument(value);
                const isValid = DocumentFormatter.isValidDocument(value);
                setErrorDocument(!isValid);
              }}
              label={DocumentFormatter.defineFormatLabel(document)}
              variant="outlined"
              className={classes.inputMaior}
            />
          </Grid>
        </Grid>
        {/* END NAME AND CPF FIELD CONTAINER */}

        <Grid container spacing={1}>
          <Grid item xl={7} lg={7} md={7} sm={12} xs={12}>
            <TextField
              id="email"
              required
              value={email || ""}
              disabled={isFromEdit}
              error={!!errorEmail}
              type="email"
              onInvalid={(e) => {
                setErrorEmail(true);
              }}
              onInput={(e) => {
                setEmail(e.target.value);
                setErrorEmail(false);
              }}
              label="E-mail"
              variant="outlined"
              className={classes.inputMaior}
            />
          </Grid>
          <Grid item xl={5} lg={5} md={5} sm={12} xs={12}>
            <TextField
              id="phone"
              required
              value={PhoneFormatter.formatPhoneNumber(phone)}
              error={errorPhone}
              onChange={(e) => {
                setPhone(PhoneFormatter.formatPhoneNumber(e.target.value));
                setErrorPhone(false);
              }}
              type="tel"
              onInvalid={() => setErrorPhone(true)}
              label="Telefone"
              variant="outlined"
              fullWidth
              inputProps={{ maxLength: 15 }}
              className={classes.inputMaior}
            />
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
            <TextField
              id="cep"
              type="cep"
              value={cep || ""}
              onInput={(e) => {
                setCEP(e.target.value);
              }}
              InputProps={{
                classes: {
                  input: classes.inputColor,
                  notchedOutline: classes.notchedOutline,
                },
              }}
              label="CEP"
              variant="outlined"
              className={classes.inputMaior}
            />
          </Grid>

          <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
            <FormControl variant="outlined" className={classes.inputMaior}>
              <Autocomplete
                value={city || ""}
                onChange={(event, newValue) => {
                  setCity(newValue);
                }}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                options={cities}
                getOptionLabel={(option) => option || ""}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Cidade"
                    variant="outlined"
                    required
                  />
                )}
                className={classes.autocomplete}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
            <TextField
              id="neighborhood"
              required
              value={neighborhood || ""}
              error={errorNeighborhood}
              onInvalid={(e) => {
                setErrorNeighborhood(true);
              }}
              onInput={(e) => {
                setNeighborhood(e.target.value);
                setErrorNeighborhood(false);
              }}
              InputProps={{
                classes: {
                  input: classes.inputColor,
                  notchedOutline: classes.notchedOutline,
                },
              }}
              label="Bairro"
              variant="outlined"
              className={classes.inputMaior}
            />
          </Grid>
          <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
            <TextField
              id="street"
              required
              value={street || ""}
              error={errorStreet}
              onInvalid={(e) => {
                setErrorStreet(true);
              }}
              onInput={(e) => {
                setStreet(e.target.value);
                setErrorStreet(false);
              }}
              InputProps={{
                classes: {
                  input: classes.inputColor,
                  notchedOutline: classes.notchedOutline,
                },
              }}
              label="Rua"
              variant="outlined"
              className={classes.inputMaior}
            />
          </Grid>

          <Grid item xl={2} lg={2} md={2} sm={12} xs={12}>
            <TextField
              id="number"
              required
              value={number || ""}
              error={errorNumber}
              onInvalid={(e) => {
                setErrorNumber(true);
              }}
              onInput={(e) => {
                setNumber(e.target.value);
                setErrorNumber(false);
              }}
              InputProps={{
                classes: {
                  input: classes.inputColor,
                  notchedOutline: classes.notchedOutline,
                },
              }}
              label="Número"
              variant="outlined"
              className={classes.inputMaior}
            />
          </Grid>
          <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
            <TextField
              id="complement"
              value={complement || ""}
              onInput={(e) => {
                setComplement(e.target.value);
              }}
              InputProps={{
                classes: {
                  input: classes.inputColor,
                  notchedOutline: classes.notchedOutline,
                },
              }}
              label="Complemento"
              variant="outlined"
              className={classes.inputMaior}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

import React, { useContext, useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  IconButton,
  TableCell,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Grid,
  Avatar,
  Tooltip,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import RandomColor from "randomcolor";

import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import MenuBookOutlinedIcon from "@material-ui/icons/MenuBookOutlined";
import BlockIcon from "@mui/icons-material/Block";
import CheckIcon from "@mui/icons-material/Check";

import { Context } from "../../context";
import Api from "../../service";
import history from "../../routes/history";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    justifyContent: "center",
    marginLeft: "30px",
    marginRight: "30px",
    marginTop: "100px",
    height: "85vh",
    [theme.breakpoints.up("xxs")]: {
      height: "100%",
      marginBottom: "30px",
    },
    [theme.breakpoints.up("xs")]: {
      height: "100%",
      marginBottom: "30px",
    },
    [theme.breakpoints.up("sm")]: {
      minHeight: "85vh",
      height: "100%",
    },
    [theme.breakpoints.up("md")]: {
      minHeight: "85vh",
      height: "100%",
    },
    [theme.breakpoints.up("lg")]: {
      minHeight: "85vh",
      height: "100%",
    },
    [theme.breakpoints.up("xl")]: {
      minHeight: "85vh",
      height: "100%",
    },
    scrollbarWidth: "thin",
  },
  general: {
    display: "flex",
    width: "90vw",
    [theme.breakpoints.up("xxs")]: {
      width: "80vw",
    },
    [theme.breakpoints.up("xs")]: {
      width: "80vw",
    },
    [theme.breakpoints.up("sm")]: {
      width: "90vw",
    },
    [theme.breakpoints.up("md")]: {
      width: "90vw",
    },
    [theme.breakpoints.up("lg")]: {
      width: "90vw",
    },
    [theme.breakpoints.up("xl")]: {
      width: "90vw",
    },
  },
  delete: {
    marginRight: 30,
  },
  modalDialog: {
    textAlign: "left",
  },
  modalText: {
    color: "gray",
    fontSize: "18px",
    fontWeight: "bold",
  },
  large: {
    height: 30,
    width: 30,
    fontSize: 15,
    marginTop: 0,
    textAlign: "center",
  },
  fontIcon: {
    fontSize: 28,
  },
  hover: {
    "&:hover": {
      color: "#211f58",
    },
  },
  input: {
    width: "90%",
    marginBottom: "30px",
  },
  hideAvatar: {
    [theme.breakpoints.up("xxs")]: {
      visibility: "visible",
      margin: "5px 10px 5px 5px",
    },
    [theme.breakpoints.up("xs")]: {
      visibility: "visible",
      margin: "5px 10px 5px 5px",
    },
    [theme.breakpoints.up("sm")]: {
      visibility: "visible",
      marginRight: 15,
    },
    [theme.breakpoints.up("md")]: {
      visibility: "visible",
      marginRight: 15,
    },
    [theme.breakpoints.up("lg")]: {
      visibility: "visible",
      marginRight: 15,
    },
    [theme.breakpoints.up("xl")]: {
      visibility: "visible",
      marginRight: 15,
    },
  },
  userNameRow: {
    display: "flex",
    alignItems: "center",
  },
}));

function DefineAvatar({ name }) {
  const trimmedName = name.trim();
  const firstLetter = trimmedName[0].toUpperCase();
  const lastLetter = trimmedName.split(" ").pop()[0].toUpperCase();

  return (
    <>
      {firstLetter}
      {lastLetter}
    </>
  );
}

const ManageStore = () => {
  const {
    cookie,
    userList,
    setUserList,
    setPage,
    setOpenUsers,
    setSelectedIndex,
    setTabIndex,
    setValueEdit,
    loadingUser,
    setLoadingUser,
    setIsFromEdit,
  } = useContext(Context);

  const classes = useStyles();

  const [deleteMessage, setDeleteMessage] = useState("");
  const [openResponse, setOpenResponse] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openSameUser, setOpenSameUser] = useState(false);
  const [valueDelete, setValueDelete] = useState("");
  const [userDelete, setUserDelete] = useState([]);

  var newUserList;

  const handleSeeUser = (id) => {
    setTabIndex(0);
    setValueEdit(id);
    setIsFromEdit(true);
    history.push("/create-store");
  };

  // Code snippet that avoid warning in MUIDataTable under 4.1.12v
  const oldRender = TableCell.render;

  TableCell.render = function (...args) {
    const [props, ...otherArgs] = args;
    if (typeof props === "object" && props && "isEmpty" in props) {
      const { isEmpty, ...propsWithoutEmpty } = props;
      return oldRender.apply(this, [propsWithoutEmpty, ...otherArgs]);
    } else {
      return oldRender.apply(this, args);
    }
  };
  // Code snippet that avoid warning in MUIDataTable under 4.1.12v

  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      fontSize: "1.2rem",
    },
  }))(TableCell);

  useEffect(() => {
    const token = cookie.token.accessToken;

    Api.defaults.headers.common["x-access-token"] = token;

    setPage("Meus Lojistas");
    setSelectedIndex([10, "Meus Lojistas"]);
    setIsFromEdit(false);
    setOpenUsers(true);

    Api.get("/api/user/list/store", {
      params: { _id: cookie.userInfo[0]._id },
    })
      .then((res) => {
        const newUpdatedUserList =
          res.data.length > 0
            ? res.data.map((user) => {
                return {
                  ...user,
                  role:
                    user.role === "admin"
                      ? "Administrador"
                      : user.role === "common"
                      ? "Usuário comum"
                      : user.role === "seller"
                      ? "Vendedor"
                      : user.role === "partner"
                      ? "Parceiro"
                      : "Lojista",
                };
              })
            : res.data;

        setUserList(newUpdatedUserList);
        setLoadingUser(false);
      })
      .catch((err) => {
        console.error(err);
        setLoadingUser(false);
      });

    setValueDelete("");
    setUserDelete("");
  }, []); // eslint-disable-line

  newUserList = userList && userList;

  var countStatus = 0;
  var totalRows = 0;

  newUserList &&
    newUserList.forEach((data) => {
      totalRows += 1;
      data.status === "inactive" && (countStatus += 1);
    });

  const columns = [
    {
      name: "_id",
      label: "ID",
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "status",
      label: "status",
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "fullName",
      label: "Usuário",
      options: {
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return (
            <StyledTableCell key={columnMeta.index}>
              {columnMeta.label}
            </StyledTableCell>
          );
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <div className={classes.userNameRow}>
                <Avatar
                  className={`${classes.large} ${classes.hideAvatar}`}
                  style={{
                    backgroundColor:
                      tableMeta.rowData[1] === "active"
                        ? RandomColor({
                            hue: "orange",
                            luminosity: "dark",
                          })
                        : "gray",
                  }}
                >
                  <DefineAvatar name={value}></DefineAvatar>
                </Avatar>

                <div
                  style={{
                    color: tableMeta.rowData[1] === "active" ? "black" : "gray",
                  }}
                >
                  {value}
                </div>
              </div>
            </>
          );
        },
      },
    },
    {
      name: "email",
      label: "E-mail",
      options: {
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return (
            <StyledTableCell key={columnMeta.index}>
              {columnMeta.label}
            </StyledTableCell>
          );
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Grid
              item
              xs={10}
              style={{
                marginTop: 5,
                color: tableMeta.rowData[1] === "active" ? "black" : "gray",
              }}
            >
              {value}
            </Grid>
          );
        },
      },
    },
    {
      name: "document",
      label: "Documento",
      options: {
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return (
            <StyledTableCell key={columnMeta.index}>
              {columnMeta.label}
            </StyledTableCell>
          );
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Grid
              item
              xs={10}
              style={{
                marginTop: 5,
                color: tableMeta.rowData[1] === "active" ? "black" : "gray",
              }}
            >
              {value}
            </Grid>
          );
        },
      },
    },
    {
      name: "phone",
      label: "Telefone",
      options: {
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return (
            <StyledTableCell key={columnMeta.index}>
              {columnMeta.label}
            </StyledTableCell>
          );
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Grid
              item
              xs={10}
              style={{
                marginTop: 5,
                color: tableMeta.rowData[1] === "active" ? "black" : "gray",
              }}
            >
              {value}
            </Grid>
          );
        },
      },
    },
    {
      name: "address",
      label: "Alocação",
      options: {
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return (
            <StyledTableCell key={columnMeta.index}>
              {columnMeta.label}
            </StyledTableCell>
          );
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Grid
              item
              xs={10}
              style={{
                marginTop: 5,
                color: tableMeta.rowData[1] === "active" ? "black" : "gray",
              }}
            >
              {value.city} / {value.state}
            </Grid>
          );
        },
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return (
            <StyledTableCell key={columnMeta.index}>
              {columnMeta.label}
            </StyledTableCell>
          );
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Grid
              item
              xs={10}
              style={{
                marginTop: 5,
                color: tableMeta.rowData[1] === "active" ? "black" : "gray",
              }}
            >
              {value === "active" ? (
                <CheckIcon
                  style={{
                    color: "green",
                    display: "flex",
                    justifyContent: "center",
                  }}
                />
              ) : (
                <BlockIcon
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                />
              )}
            </Grid>
          );
        },
      },
    },
    {
      name: "open",
      label: "Editar",
      options: {
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return <StyledTableCell key={columnMeta.index}></StyledTableCell>;
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Grid container>
                <Grid item xs={10}>
                  <IconButton
                    onClick={() => handleSeeUser(tableMeta.rowData[0])}
                  >
                    <Tooltip title="Ver Detalhes">
                      <MenuBookOutlinedIcon
                        className={classes.fontIcon}
                        style={{
                          color:
                            tableMeta.rowData[1] === "active"
                              ? "#ffa101"
                              : "gray",
                        }}
                      />
                    </Tooltip>
                  </IconButton>
                </Grid>
              </Grid>
            </>
          );
        },
      },
    },
  ];

  const handleCloseModalResponse = () => {
    setOpenResponse(false);
    window.location.reload();
  };

  function handleCloseModalConfirm(props) {
    if (props === "confirmar") {
      setOpenConfirm(false);
      setValueDelete("");
      if (
        valueDelete.filter((item) => {
          return item === cookie.userInfo[0]._id;
        }).length > 0
      ) {
        setOpenSameUser(true);
      } else {
        Api.put("/api/user/status", { _id: valueDelete })
          .then((res) => {
            setDeleteMessage(res.data.message);
            setOpenResponse(true);
          })
          .catch((err) => {
            setDeleteMessage(err.response.data.message);
            setOpenResponse(true);
          });
      }
    } else {
      setOpenConfirm(false);
    }
  }

  const handleCloseModalSameUser = () => {
    setOpenSameUser(false);
  };

  const handleDelete = (selectedRows, displayData) => {
    setUserDelete("");

    var userName = selectedRows.data.map((user) => {
      return displayData.find((item) => item.dataIndex === user.dataIndex)
        .data[0];
    });
    setValueDelete(userName);
    setOpenConfirm(true);
  };

  const handleCreateUser = () => {
    setPage("Cadastrar Lojista");
    setSelectedIndex([9, "Cadastrar Lojista"]);

    history.push("/create-store");
  };

  useEffect(() => {
    Api.get("/api/user/detail", { params: { _id: valueDelete } })
      .then((res) => {
        setUserDelete(res.data);
      })
      .catch((err) => {
        setUserDelete(err);
      });
  }, [openConfirm === true]); // eslint-disable-line

  return loadingUser ? (
    <Grid
      container
      style={{
        width: "78vw",
        height: "80vh",
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
      }}
    >
      <CircularProgress />
    </Grid>
  ) : (
    <div>
      <Paper elevation={0} className={classes.general}>
        <div style={{ width: "1000vw" }}>
          <MUIDataTable
            id={"table"}
            title={"Meus Lojistas"}
            data={newUserList}
            pagination="true"
            columns={columns}
            options={{
              sort: true,
              rowsPerPage: 5,
              rowsPerPageOptions: [5, 10, 15, 20],
              filter: false,
              download: false,
              responsive: "simple",
              selectableRowsOnClick: true,
              print: true,
              viewColumns: true,
              elevation: 0,
              textLabels: {
                body: {
                  noMatch: "Nenhum usuário encontrado!",
                  toolTip: "Ordenar",
                  columnHeaderTooltip: (column) =>
                    `Ordenar por ${column.label}`,
                },
                pagination: {
                  next: "Próxima página",
                  previous: "Página anterior",
                  rowsPerPage: "Linhas por página:",
                  displayRows: "de",
                },
                toolbar: {
                  search: "Procurar",
                  viewColumns: "Filtrar colunas",
                  print: "Imprimir tabela",
                },
                viewColumns: {
                  title: "Filtrar colunas",
                },
                selectedRows: {
                  text: "linha(s) selecionada(s)",
                },
              },
              customToolbarSelect: (
                selectedRows,
                displayData,
                setSelectedRows
              ) => {
                return (
                  <Tooltip title="Desativar Lojista">
                    <div className={classes.delete}>
                      <IconButton
                        onClick={() => handleDelete(selectedRows, displayData)}
                        className={classes.hover}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  </Tooltip>
                );
              },
              customToolbar: (data) => {
                return (
                  <>
                    <Tooltip title="Criar Lojista">
                      <IconButton
                        onClick={handleCreateUser}
                        className={classes.hover}
                      >
                        <AddIcon />
                      </IconButton>
                    </Tooltip>
                  </>
                );
              },
              isRowSelectable: (dataIndex, selectedRows) => {
                return dataIndex < totalRows - countStatus ? true : false;
              },
            }}
          />
        </div>
      </Paper>
      <Dialog
        open={openResponse}
        onClose={handleCloseModalResponse}
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle>
          <strong>
            <i>{"Atenção:"}</i>
          </strong>
        </DialogTitle>
        <DialogContent className={classes.modalDialog}>
          <Typography
            variant="h6"
            className={classes.modalText}
            style={{ textAlign: "center" }}
          >
            {deleteMessage}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseModalResponse}
            style={{ color: "#808080" }}
            autoFocus
          >
            <strong>fechar</strong>
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openConfirm}
        onClose={() => handleCloseModalConfirm("value")}
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle>
          <strong>
            <i>{"Atenção:"}</i>
          </strong>
        </DialogTitle>
        <DialogContent className={classes.modalDialog}>
          <Typography variant="h6" className={classes.modalText}>
            Você está prestes a DESATIVAR o(s) Lojista(s):
            <ul style={{ color: "black" }}>
              {userDelete !== "" &&
                userDelete.map((user, index) => {
                  return <li key={index}> {user.fullName} </li>;
                })}
            </ul>
            Essa ação só poderá ser revertida por um administrador Cupomzito.
            Deseja continuar?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleCloseModalConfirm("negar")}
            style={{ color: "#808080" }}
            autoFocus
          >
            <strong>fechar</strong>
          </Button>
          <Button
            onClick={() => handleCloseModalConfirm("confirmar")}
            color="secondary"
            autoFocus
          >
            <strong>confirmar</strong>
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openSameUser}
        onClose={handleCloseModalSameUser}
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle>
          <strong>
            <i>{"Atenção:"}</i>
          </strong>
        </DialogTitle>
        <DialogContent className={classes.modalDialog}>
          <Typography variant="h6" style={{ textAlign: "center" }}>
            Você não pode desativar seu próprio usuário!
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseModalSameUser}
            style={{ color: "#808080" }}
            autoFocus
          >
            <strong>fechar</strong>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default function SeeStores() {
  const classes = useStyles();

  return (
    <Paper elevation={2} className={classes.root}>
      <ManageStore />
    </Paper>
  );
}

import { useState } from "react";

export default function ClientHook() {
  const [clientBarLeftOpen, setClientBarLeftOpen] = useState(false);
  const [cityFilter, setCityFilter] = useState(false);
  const [isFromMyProfile, setIsFromMyProfile] = useState(false);

  return {
    clientBarLeftOpen,
    setClientBarLeftOpen,
    cityFilter,
    setCityFilter,
    isFromMyProfile,
    setIsFromMyProfile,
  };
}

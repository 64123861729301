import React, { useContext, useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  IconButton,
  TableCell,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Grid,
  Tooltip,
  CircularProgress,
  Typography,
} from "@material-ui/core";

import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import MenuBookOutlinedIcon from "@material-ui/icons/MenuBookOutlined";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";
import BlockIcon from "@mui/icons-material/Block";
import CheckIcon from "@mui/icons-material/Check";
import CheckroomOutlinedIcon from "@mui/icons-material/CheckroomOutlined";
import LocalHospitalOutlinedIcon from "@mui/icons-material/LocalHospitalOutlined";
import AutoAwesomeOutlinedIcon from "@mui/icons-material/AutoAwesomeOutlined";
import RestaurantOutlinedIcon from "@mui/icons-material/RestaurantOutlined";
import CottageOutlinedIcon from "@mui/icons-material/CottageOutlined";
import PetsOutlinedIcon from "@mui/icons-material/PetsOutlined";
import AppShortcutOutlinedIcon from "@mui/icons-material/AppShortcutOutlined";
import DirectionsCarOutlinedIcon from "@mui/icons-material/DirectionsCarOutlined";
import ChildFriendlyOutlinedIcon from "@mui/icons-material/ChildFriendlyOutlined";
import Diversity1OutlinedIcon from "@mui/icons-material/Diversity1Outlined";

import { Context } from "../../../context";
import Api from "../../../service";

const useStyles = makeStyles((theme) => ({
  general: {
    display: "flex",
    width: "90vw",
    [theme.breakpoints.up("xxs")]: {
      width: "80vw",
    },
    [theme.breakpoints.up("xs")]: {
      width: "80vw",
    },
    [theme.breakpoints.up("sm")]: {
      width: "90vw",
    },
    [theme.breakpoints.up("md")]: {
      width: "90vw",
    },
    [theme.breakpoints.up("lg")]: {
      width: "90vw",
    },
    [theme.breakpoints.up("xl")]: {
      width: "90vw",
    },
  },
  delete: {
    marginRight: 30,
  },
  modalDialog: {
    textAlign: "left",
  },
  modalText: {
    color: "gray",
    fontSize: "18px",
    fontWeight: "bold",
  },
  large: {
    height: 30,
    width: 30,
    fontSize: 15,
    marginTop: 0,
    textAlign: "center",
  },
  fontIcon: {
    fontSize: 28,
  },
  hover: {
    "&:hover": {
      color: "#211f58",
    },
  },
  input: {
    width: "90%",
    marginBottom: "30px",
  },
  hideAvatar: {
    [theme.breakpoints.up("xxs")]: {
      visibility: "visible",
      margin: "5px 10px 5px 5px",
    },
    [theme.breakpoints.up("xs")]: {
      visibility: "visible",
      margin: "5px 10px 5px 5px",
    },
    [theme.breakpoints.up("sm")]: {
      visibility: "visible",
      marginRight: 15,
    },
    [theme.breakpoints.up("md")]: {
      visibility: "visible",
      marginRight: 15,
    },
    [theme.breakpoints.up("lg")]: {
      visibility: "visible",
      marginRight: 15,
    },
    [theme.breakpoints.up("xl")]: {
      visibility: "visible",
      marginRight: 15,
    },
  },
  userNameRow: {
    display: "flex",
    alignItems: "center",
  },
}));

const ManageCampaign = () => {
  const {
    cookie,
    campaignList,
    setCampaignList,
    setPage,
    setSelectedIndex,
    setIsFromCreateCampaign,
    setValueEdit,
    loadingCampaign,
    setLoadingCampaign,
    setIsFromEditCampaign,
    setCookie,
    setUserInfos,
  } = useContext(Context);

  const classes = useStyles();

  const [deleteMessage, setDeleteMessage] = useState("");
  const [openResponse, setOpenResponse] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [valueDelete, setValueDelete] = useState("");
  const [creditDelete, setCreditDelete] = useState(0);
  const [campaignDelete, setCampaignDelete] = useState([]);

  var newCampaignList;

  const handleSeeCampaign = (id) => {
    setValueEdit(id);
    setIsFromCreateCampaign(true);
    setIsFromEditCampaign(true);
  };

  // Code snippet that avoid warning in MUIDataTable under 4.1.12v
  const oldRender = TableCell.render;

  TableCell.render = function (...args) {
    const [props, ...otherArgs] = args;
    if (typeof props === "object" && props && "isEmpty" in props) {
      const { isEmpty, ...propsWithoutEmpty } = props;
      return oldRender.apply(this, [propsWithoutEmpty, ...otherArgs]);
    } else {
      return oldRender.apply(this, args);
    }
  };
  // Code snippet that avoid warning in MUIDataTable under 4.1.12v

  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      fontSize: "1.2rem",
    },
  }))(TableCell);

  useEffect(() => {
    const token = cookie.token.accessToken;

    Api.defaults.headers.common["x-access-token"] = token;

    setPage("Consultar Cadastros");
    setSelectedIndex([6, "Consultar Cadastros"]);
    setIsFromEditCampaign(false);
    setIsFromCreateCampaign(false);

    Api.get("/api/campaign/detail/user", {
      params: { userId: cookie.userInfo[0]._id },
    })
      .then((res) => {
        const newUpdatedCampaignList =
          res.data.length > 0
            ? res.data
                .map((campaign) => {
                  return {
                    ...campaign,
                    category:
                      campaign.category === "health"
                        ? "Saúde"
                        : campaign.category === "beauty"
                        ? "Beleza"
                        : campaign.category === "cloth"
                        ? "Vestuário"
                        : campaign.category === "food"
                        ? "Comida"
                        : campaign.category === "home"
                        ? "Casa"
                        : campaign.category === "pets"
                        ? "Pets"
                        : campaign.category === "electronic"
                        ? "Eletrônicos"
                        : campaign.category === "automotive"
                        ? "Automotivo"
                        : campaign.category === "kid"
                        ? "Kids"
                        : campaign.category === "book"
                        ? "Livraria"
                        : "Diversos",
                  };
                })
                .filter((campaign) => campaign.status === "active")
            : res.data;

        setCampaignList(newUpdatedCampaignList);
        setLoadingCampaign(false);
      })
      .catch((err) => {
        console.error(err);
        setLoadingCampaign(false);
      });

    setValueDelete("");
    setCreditDelete(0);
    setCampaignDelete([]);
  }, []); // eslint-disable-line

  newCampaignList = campaignList && campaignList;

  var countStatus = 0;
  var totalRows = 0;

  newCampaignList &&
    newCampaignList.forEach((data) => {
      totalRows += 1;
      data.status === "inactive" && (countStatus += 1);
    });

  const columns = [
    {
      name: "_id",
      label: "ID",
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "status",
      label: "status",
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "city",
      label: "city",
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "state",
      label: "state",
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "category",
      label: "Categoria",
      options: {
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return (
            <StyledTableCell key={columnMeta.index}>
              {columnMeta.label}
            </StyledTableCell>
          );
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Grid
              item
              xs={10}
              style={{
                marginTop: 5,
                color: tableMeta.rowData[1] === "active" ? "black" : "gray",
                display: "flex",
                alignItems: "center",
                alignContent: "center",
              }}
            >
              {value === "Saúde" ? (
                <LocalHospitalOutlinedIcon />
              ) : value === "Beleza" ? (
                <AutoAwesomeOutlinedIcon />
              ) : value === "Vestuário" ? (
                <CheckroomOutlinedIcon />
              ) : value === "Comida" ? (
                <RestaurantOutlinedIcon />
              ) : value === "Casa" ? (
                <CottageOutlinedIcon />
              ) : value === "Pets" ? (
                <PetsOutlinedIcon />
              ) : value === "Eletrônicos" ? (
                <AppShortcutOutlinedIcon />
              ) : value === "Automotivo" ? (
                <DirectionsCarOutlinedIcon />
              ) : value === "Kids" ? (
                <ChildFriendlyOutlinedIcon />
              ) : value === "Livraria" ? (
                <MenuBookOutlinedIcon />
              ) : (
                <Diversity1OutlinedIcon />
              )}{" "}
              <div style={{ marginLeft: 10, marginTop: 3 }}>{value}</div>
            </Grid>
          );
        },
      },
    },
    {
      name: "fullName",
      label: "Nome",
      options: {
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return (
            <StyledTableCell key={columnMeta.index}>
              {columnMeta.label}
            </StyledTableCell>
          );
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Grid
                item
                xs={10}
                style={{
                  marginTop: 5,
                  color: tableMeta.rowData[1] === "active" ? "black" : "gray",
                }}
              >
                {value}
              </Grid>
            </>
          );
        },
      },
    },
    {
      name: "fullName",
      label: "Cidade",
      options: {
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return (
            <StyledTableCell key={columnMeta.index}>
              {columnMeta.label}
            </StyledTableCell>
          );
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Grid
                item
                xs={10}
                style={{
                  marginTop: 5,
                  color: tableMeta.rowData[1] === "active" ? "black" : "gray",
                }}
              >
                {`${tableMeta.rowData[2]} / ${tableMeta.rowData[3]}`}
              </Grid>
            </>
          );
        },
      },
    },
    {
      name: "couponsAvailable",
      label: "Total de cupons",
      options: {
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return (
            <StyledTableCell key={columnMeta.index}>
              {columnMeta.label}
            </StyledTableCell>
          );
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Grid
              item
              xs={10}
              style={{
                marginTop: 5,
                color: tableMeta.rowData[1] === "active" ? "black" : "gray",
              }}
            >
              {value} {value !== 1 ? "Cupons" : "Cupom"}
            </Grid>
          );
        },
      },
    },
    {
      name: "remainingCouponsAvailable",
      label: "Cupons restantes",
      options: {
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return (
            <StyledTableCell key={columnMeta.index}>
              {columnMeta.label}
            </StyledTableCell>
          );
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Grid
              item
              xs={10}
              style={{
                marginTop: 5,
                color: tableMeta.rowData[1] === "active" ? "black" : "gray",
              }}
            >
              {value} {value !== 1 ? "Cupons" : "Cupom"}
            </Grid>
          );
        },
      },
    },
    {
      name: "contact",
      label: "Contato",
      options: {
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return (
            <StyledTableCell key={columnMeta.index}>
              {columnMeta.label}
            </StyledTableCell>
          );
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Grid
              item
              xs={10}
              style={{
                marginTop: 5,
                color: tableMeta.rowData[1] === "active" ? "black" : "gray",
              }}
            >
              {value}
            </Grid>
          );
        },
      },
    },
    {
      name: "description",
      label: "Descrição",
      options: {
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return (
            <StyledTableCell key={columnMeta.index}>
              {columnMeta.label}
            </StyledTableCell>
          );
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Grid
              item
              xs={10}
              style={{
                marginTop: 5,
                color: tableMeta.rowData[1] === "active" ? "black" : "gray",
              }}
            >
              {value}
            </Grid>
          );
        },
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return (
            <StyledTableCell key={columnMeta.index}>
              {columnMeta.label}
            </StyledTableCell>
          );
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Grid
              item
              xs={10}
              style={{
                marginTop: 5,
                color: tableMeta.rowData[1] === "active" ? "black" : "gray",
              }}
            >
              {value === "active" ? (
                <CheckIcon
                  style={{
                    color: "green",
                    display: "flex",
                    justifyContent: "center",
                  }}
                />
              ) : (
                <BlockIcon
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                />
              )}
            </Grid>
          );
        },
      },
    },
    {
      name: "open",
      label: "Editar",
      options: {
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return <StyledTableCell key={columnMeta.index}></StyledTableCell>;
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Grid container>
                <Grid item xs={10}>
                  <IconButton
                    onClick={() => handleSeeCampaign(tableMeta.rowData[0])}
                  >
                    <Tooltip title="Ver Detalhes">
                      <EditNoteOutlinedIcon
                        className={classes.fontIcon}
                        style={{
                          color:
                            tableMeta.rowData[1] === "active"
                              ? "#ffa101"
                              : "gray",
                        }}
                      />
                    </Tooltip>
                  </IconButton>
                </Grid>
              </Grid>
            </>
          );
        },
      },
    },
  ];

  const handleCloseModalResponse = () => {
    setOpenResponse(false);
    window.location.reload();
  };

  async function handleCloseModalConfirm(props) {
    if (props === "confirmar") {
      try {
        const userUpdated = await Api.get("/api/user/detail", {
          params: {
            _id: cookie.userInfo[0]._id,
          },
        });

        const newCredit = userUpdated.data[0].credit + creditDelete;

        const deletedCampaignResult = await Api.put("/api/campaign/status", {
          _id: valueDelete,
        });

        setOpenConfirm(false);
        setValueDelete("");
        setCreditDelete(0);

        await Api.put("/api/user", {
          _id: cookie.userInfo[0]._id,
          credit: newCredit,
        });

        setDeleteMessage(deletedCampaignResult.data.message);
        setOpenResponse(true);

        updateCookie(newCredit);
      } catch (err) {
        setDeleteMessage(
          err.response?.data?.message ||
            "Servidor indisponível. Tente novamente mais tarde."
        );
        setOpenResponse(true);
      }
    } else {
      setOpenConfirm(false);
    }
  }

  function updateCookie(newCredit) {
    const newData = {
      _id: cookie.userInfo[0]._id,
      fullName: cookie.userInfo[0].fullName,
      password: cookie.userInfo[0].password,
      manager: cookie.userInfo[0].manager,
      role: cookie.userInfo[0].role,
      credit: newCredit,
      phone: cookie.userInfo[0].phone,
      email: cookie.userInfo[0].email,
      document: cookie.userInfo[0].document,
      address: cookie.userInfo[0].address,
      status: cookie.userInfo[0].status,
    };

    setCookie("userInfo", [newData], { expire: 1, path: "/" });
    setUserInfos([newData]);
  }

  const handleDelete = (selectedRows, displayData) => {
    setCampaignDelete([]);

    var campaignId = selectedRows.data.map((campaign) => {
      return displayData.find((item) => item.dataIndex === campaign.dataIndex)
        .data[0];
    });

    var credits = selectedRows.data
      .map((campaign) => {
        return displayData.find((item) => item.dataIndex === campaign.dataIndex)
          .data[8].props.children[0];
      })
      .reduce((acumulator, currentValue) => acumulator + currentValue);

    setCreditDelete(credits);
    setValueDelete(campaignId);
    setOpenConfirm(true);
  };

  const handleCreateCampaign = () => {
    setPage("Cadastrar");
    setIsFromCreateCampaign(true);
  };

  useEffect(() => {
    valueDelete !== "" &&
      Api.get("/api/campaign/detail", { params: { _id: valueDelete } })
        .then((res) => {
          setCampaignDelete(res.data);
        })
        .catch((err) => {
          setCampaignDelete([]);
        });
  }, [openConfirm === true]); // eslint-disable-line

  return loadingCampaign ? (
    <Grid
      container
      style={{
        width: "78vw",
        height: "80vh",
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
      }}
    >
      <CircularProgress />
    </Grid>
  ) : (
    <div>
      <Paper elevation={0} className={classes.general}>
        <div style={{ width: "1000vw" }}>
          <MUIDataTable
            id={"table"}
            title={"Campanhas"}
            data={newCampaignList}
            pagination="true"
            columns={columns}
            options={{
              sort: true,
              rowsPerPage: 10,
              rowsPerPageOptions: [5, 10, 15, 20],
              filter: false,
              download: false,
              responsive: "simple",
              selectableRowsOnClick: true,
              print: true,
              viewColumns: true,
              elevation: 0,
              textLabels: {
                body: {
                  noMatch: "Nenhuma campanha encontrada!",
                  toolTip: "Ordenar",
                  columnHeaderTooltip: (column) =>
                    `Ordenar por ${column.label}`,
                },
                pagination: {
                  next: "Próxima página",
                  previous: "Página anterior",
                  rowsPerPage: "Linhas por página:",
                  displayRows: "de",
                },
                toolbar: {
                  search: "Procurar",
                  viewColumns: "Filtrar colunas",
                  print: "Imprimir tabela",
                },
                viewColumns: {
                  title: "Filtrar colunas",
                },
                selectedRows: {
                  text: "linha(s) selecionada(s)",
                },
              },
              customToolbarSelect: (
                selectedRows,
                displayData,
                setSelectedRows
              ) => {
                return (
                  <Tooltip title="Desativar Campanha">
                    <div className={classes.delete}>
                      <IconButton
                        onClick={() => handleDelete(selectedRows, displayData)}
                        className={classes.hover}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  </Tooltip>
                );
              },
              customToolbar: (data) => {
                return (
                  <>
                    <Tooltip title="Criar Campanha">
                      <IconButton
                        onClick={handleCreateCampaign}
                        className={classes.hover}
                      >
                        <AddIcon />
                      </IconButton>
                    </Tooltip>
                  </>
                );
              },
              isRowSelectable: (dataIndex, selectedRows) => {
                return dataIndex < totalRows - countStatus ? true : false;
              },
            }}
          />
        </div>
      </Paper>
      <Dialog
        open={openResponse}
        onClose={handleCloseModalResponse}
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle>
          <strong>
            <i>{"Atenção:"}</i>
          </strong>
        </DialogTitle>
        <DialogContent className={classes.modalDialog}>
          <Typography
            variant="h6"
            className={classes.modalText}
            style={{ textAlign: "center" }}
          >
            {deleteMessage}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseModalResponse}
            style={{ color: "#808080" }}
            autoFocus
          >
            <strong>fechar</strong>
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openConfirm}
        onClose={() => handleCloseModalConfirm("value")}
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle>
          <strong>
            <i>{"Atenção:"}</i>
          </strong>
        </DialogTitle>
        <DialogContent className={classes.modalDialog}>
          <Typography variant="h6" className={classes.modalText}>
            Você está prestes a DESATIVAR a(s) campanha(s):
            <ul style={{ color: "black" }}>
              {campaignDelete !== "" &&
                campaignDelete.map((campaign, index) => {
                  return (
                    <li key={index}>
                      {campaign.fullName} ({campaign.city} / {campaign.state})
                    </li>
                  );
                })}
            </ul>
            Deseja continuar?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleCloseModalConfirm("negar")}
            style={{ color: "#808080" }}
            autoFocus
          >
            <strong>fechar</strong>
          </Button>
          <Button
            onClick={() => handleCloseModalConfirm("confirmar")}
            color="secondary"
            autoFocus
          >
            <strong>confirmar</strong>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ManageCampaign;

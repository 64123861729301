import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Card,
  CardContent,
  CircularProgress,
} from "@material-ui/core";
import { Context } from "../../context";
import Api from "../../service";

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    width: 300,
    height: 160,
    borderRadius: 20,
    boxShadow: " 0px 0px 22px 1px rgba(0,0,0,0.74)",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.light,
    textAlign: "center",
  },
}));

export default function TotalCampaign() {
  const classes = useStyles();
  const { cookie } = useContext(Context);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let mounted = true;

    const fetchData = async () => {
      try {
        await new Promise((resolve) => setTimeout(resolve, 5));

        const res = await Api.get("/api/campaign/count/total", {
          params: { userId: cookie.userInfo[0]._id },
        });

        if (mounted) {
          setTotal(res.data.campaignCount);
          setLoading(false);
        }
      } catch (err) {
        console.error(err);
      }
    };

    fetchData();

    return () => {
      mounted = false;
    };
  }, [cookie.userInfo]);

  return (
    <Card className={classes.cardContainer}>
      <CardContent>
        <Typography variant="h5" gutterBottom style={{ paddingBottom: 15 }}>
          Total de campanhas
        </Typography>
        {loading ? (
          <CircularProgress style={{ color: "white" }} />
        ) : (
          <Typography variant="h4">{total}</Typography>
        )}
      </CardContent>
    </Card>
  );
}

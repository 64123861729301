import React, { useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, CssBaseline } from "@material-ui/core";
import { Context } from "../../context";

import MainLogin from "./mainLogin";
import ForgetPassword from "../forgetPassword";

const useStyles = makeStyles((theme) => ({
  "@global": {
    "input:-webkit-autofill": {
      "-webkit-text-fill-color": "white !important",
      transition: "background-color 5000s ease-in-out 0s",
    },
    "input:-webkit-autofill:hover": {
      "-webkit-text-fill-color": "transparent",
      transition: "background-color 5000s ease-in-out 0s",
    },
    "input:-webkit-autofill:focus": {
      "-webkit-text-fill-color": "transparent",
      transition: "background-color 5000s ease-in-out 0s",
    },
    "input:-webkit-autofill:active": {
      "-webkit-text-fill-color": "transparent",
      transition: "background-color 5000s ease-in-out 0s",
    },
  },
  mainContainer: {
    height: "100%",
    top: 0,
    left: 0,
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("xxs")]: {
      flexDirection: "column",
    },
    [theme.breakpoints.up("xs")]: {
      flexDirection: "column",
    },
    [theme.breakpoints.up("sm")]: {
      flexDirection: "column",
    },
    [theme.breakpoints.up("md")]: {
      flexDirection: "column",
    },
    [theme.breakpoints.up("lg")]: {
      flexDirection: "row",
    },
    [theme.breakpoints.up("xl")]: {
      flexDirection: "row",
    },
  },
  leftContainer: {
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
  },
  rightContainer: {
    backgroundColor: theme.palette.primary.light,
    borderLeft: 1000,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.up("xxs")]: {
      backgroundColor: theme.palette.primary.main,
    },
    [theme.breakpoints.up("xs")]: {
      backgroundColor: theme.palette.primary.main,
    },
    [theme.breakpoints.up("sm")]: {
      backgroundColor: theme.palette.primary.main,
    },
    [theme.breakpoints.up("md")]: {
      backgroundColor: theme.palette.primary.main,
    },
    [theme.breakpoints.up("lg")]: {
      backgroundColor: theme.palette.primary.light,
    },
    [theme.breakpoints.up("xl")]: {
      backgroundColor: theme.palette.primary.light,
    },
  },
  leftContainerTitle: {
    color: theme.palette.primary.light,
    textAlign: "center",
    [theme.breakpoints.up("xxs")]: {
      visibility: "hidden",
      fontSize: 0,
    },
    [theme.breakpoints.up("xs")]: {
      visibility: "hidden",
      fontSize: 0,
    },
    [theme.breakpoints.up("sm")]: {
      visibility: "hidden",
      fontSize: 0,
    },
    [theme.breakpoints.up("md")]: {
      visibility: "hidden",
      fontSize: 0,
    },
    [theme.breakpoints.up("lg")]: {
      visibility: "visible",
      fontSize: 80,
    },
    [theme.breakpoints.up("xl")]: {
      visibility: "visible",
      fontSize: 80,
    },
  },
}));

const Login = () => {
  const classes = useStyles();

  const { isForgetPasswordVisible, setIsForgetPasswordVisible } =
    useContext(Context);

  useEffect(() => {
    setIsForgetPasswordVisible(false);
  }, []);

  return (
    <Grid container className={classes.mainContainer}>
      <CssBaseline />
      <Grid
        item
        xl={5}
        lg={5}
        md={false}
        sm={false}
        xs={false}
        className={classes.leftContainer}
      >
        <h1 className={classes.leftContainerTitle}> Vai um cupomzito aí?</h1>
      </Grid>
      <Grid
        item
        xl={7}
        lg={7}
        md={12}
        sm={12}
        xs={12}
        className={classes.rightContainer}
      >
        {!isForgetPasswordVisible ? <MainLogin /> : <ForgetPassword />}
      </Grid>
    </Grid>
  );
};

export default Login;

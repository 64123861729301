import React, { useState, useEffect, useContext, useCallback } from "react";
import {
  Grid,
  Typography,
  Button,
  FormControl,
  TextField,
  CircularProgress,
  Box,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import Api from "../../service";
import { Context } from "../../context";

import SearchIcon from "@mui/icons-material/Search";

import CampaignPreview from "../campaign/store/campaignPreview";

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up("xxs")]: {
      marginBottom: "30px",
      marginTop: 100,
    },
    [theme.breakpoints.up("xs")]: {
      marginBottom: "30px",
      marginTop: 100,
    },
    [theme.breakpoints.up("sm")]: {
      marginBottom: "30px",
      marginTop: 100,
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: "30px",
      marginTop: 100,
    },
    [theme.breakpoints.up("lg")]: {
      marginTop: 150,
    },
    [theme.breakpoints.up("xl")]: {
      marginTop: 150,
    },
    scrollbarWidth: "thin",
  },
  titles: {
    textAlign: "center",
    color: "white",
    marginTop: 20,
  },
  subtitles: {
    textAlign: "left",
    color: "white",
    marginTop: 20,
    paddingRight: 20,
    [theme.breakpoints.up("xxs")]: {
      paddingLeft: 20,
    },
    [theme.breakpoints.up("xs")]: {
      paddingLeft: 20,
    },
    [theme.breakpoints.up("sm")]: {
      paddingLeft: 20,
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: 20,
    },
    [theme.breakpoints.up("lg")]: {
      paddingLeft: "15vw",
    },
    [theme.breakpoints.up("xl")]: {
      paddingLeft: "15vw",
    },
  },
  categories: {
    paddingLeft: 20,
    paddingRight: 20,
    marginTop: 40,
    display: "flex",
    justifyContent: "center",
  },
  categoryButton: {
    margin: 1,
    flex: "0 0 auto",
    textTransform: "none",
    borderRadius: 30,
    fontWeight: "bold",
    marginRight: 10,
  },
  campaigns: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.up("xxs")]: {
      width: "100%",
    },
    [theme.breakpoints.up("xs")]: {
      width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
    [theme.breakpoints.up("lg")]: {
      maxWidth: "70vw",
    },
    [theme.breakpoints.up("xl")]: {
      maxWidth: "70vw",
    },
  },
  campaignsSubContainer: {
    display: "flex",
    [theme.breakpoints.up("xxs")]: {
      justifyContent: "space-evenly",
    },
    [theme.breakpoints.up("xs")]: {
      justifyContent: "space-evenly",
    },
    [theme.breakpoints.up("sm")]: {
      justifyContent: "space-evenly",
    },
    [theme.breakpoints.up("md")]: {
      justifyContent: "space-evenly",
    },
    [theme.breakpoints.up("lg")]: {
      justifyContent: "center",
    },
    [theme.breakpoints.up("xl")]: {
      justifyContent: "center",
    },
  },
  motionDiv: {
    position: "relative",
    marginTop: 5,
    [theme.breakpoints.up("xxs")]: {
      paddingLeft: 0,
      paddingRight: 0,
      marginTop: 5,
    },
    [theme.breakpoints.up("xs")]: {
      paddingLeft: 0,
      paddingRight: 0,
      marginTop: 5,
    },
    [theme.breakpoints.up("sm")]: {
      paddingLeft: 0,
      paddingRight: 0,
      marginTop: 5,
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: 0,
      paddingRight: 0,
      marginTop: 5,
    },
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 10,
      paddingRight: 10,
      marginTop: 20,
    },
    [theme.breakpoints.up("xl")]: {
      paddingLeft: 10,
      paddingRight: 10,
      marginTop: 20,
    },
  },
  searchCity: {
    [theme.breakpoints.up("xxs")]: {
      width: "70%",
    },
    [theme.breakpoints.up("xs")]: {
      width: "70%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "70%",
    },
    [theme.breakpoints.up("md")]: {
      width: "70%",
    },
    [theme.breakpoints.up("lg")]: {
      width: "25%",
    },
    [theme.breakpoints.up("xl")]: {
      width: "25%",
    },
  },
  inputStyle: {
    width: "100%",
    [`& fieldset`]: {
      borderRadius: 20,
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.primary.lightSecondary,
      },
      "&:hover fieldset": {
        borderColor: theme.palette.primary.lightSecondary,
      },
    },
  },
  inputColor: {
    color: theme.palette.primary.light,
  },
  notchedOutline: {
    backgroundColor: "transparent",
    borderColor: theme.palette.primary.light,
    textColor: theme.palette.primary.light,
  },
  clearIndicator: {
    color: theme.palette.primary.light, // Cor do botão "x"
  },
  popupIndicator: {
    color: theme.palette.primary.light, // Cor da seta
  },
  buttonSeeAll: {
    margin: 20,
    marginTop: 0,
    fontWeight: "bold",
    [theme.breakpoints.up("xxs")]: {
      width: "65%",
    },
    [theme.breakpoints.up("xs")]: {
      width: "65%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "65%",
    },
    [theme.breakpoints.up("md")]: {
      width: "65%",
    },
    [theme.breakpoints.up("lg")]: {
      width: "25%",
    },
    [theme.breakpoints.up("xl")]: {
      width: "25%",
    },
  },
}));

export default function HomeClients() {
  const classes = useStyles();
  const { cityFilter, setCityFilter, cookie, setIsFromMyProfile } =
    useContext(Context);
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [emphasis, setEmpashis] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [lastCategory, setLastCategory] = useState(selectedCategory);
  const [lastCity, setLastCity] = useState(cityFilter);
  const [productFilter, setProductFilter] = useState("");
  const [tempFilter, setTempFilter] = useState("");
  const [lastProductFilter, setLastProductFilter] = useState(productFilter);
  const [itemsCounter, setItemsCounter] = useState(0);
  const [reloadUseCallback, setReloadUseCallback] = useState(false);

  const fetchEmpashis = useCallback(async () => {
    if (itemsCounter < 20) {
      try {
        const res = await Api.get(
          `/api/list?page=${page}&category=${selectedCategory}&city=${cityFilter}&product=${productFilter}`
        );

        if (res.data.length > 0) {
          setEmpashis((prevEmpashis) => {
            const newData = res.data.filter(
              (newItem) =>
                !prevEmpashis.some(
                  (existingItem) => existingItem._id === newItem._id
                )
            );
            return [...prevEmpashis, ...newData];
          });
          setItemsCounter(itemsCounter + 1);
          setPage((prevPage) => prevPage + 1);
        }
        setLoading(false);
      } catch (error) {
        console.error("Erro ao buscar empashis:", error);
      }
    }
  }, [page, selectedCategory, cityFilter, productFilter, reloadUseCallback]);

  useEffect(() => {
    if (selectedCategory !== lastCategory) {
      restartUseCalback();
      setLastCategory(selectedCategory);
    }

    if (cityFilter !== lastCity) {
      restartUseCalback();
      setLastCity(cityFilter);
    }

    if (productFilter !== lastProductFilter) {
      restartUseCalback();
      setLastProductFilter(productFilter);
    }

    fetchEmpashis();
  }, [
    fetchEmpashis,
    selectedCategory,
    cityFilter,
    productFilter,
    reloadUseCallback,
  ]);

  useEffect(() => {
    if (cookie.userInfo && Object.keys(cookie).length !== 0) {
      const token = cookie.token.accessToken;
      Api.defaults.headers.common["x-access-token"] = token;

      setCityFilter(
        `${cookie.userInfo[0].address.city} / ${cookie.userInfo[0].address.state}`
      );
    }
    setIsFromMyProfile(false);
  }, []);

  function restartUseCalback() {
    setLoading(true);
    setEmpashis([]);
    setPage(1);
    setItemsCounter(0);
  }

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    setItemsCounter(0);
  };

  const handleSearchProductClick = () => {
    if (tempFilter !== productFilter) {
      setLoading(true);
    }
    setProductFilter(tempFilter);
    setItemsCounter(0);
  };

  const handleSeeMoreButton = () => {
    setItemsCounter(0);
    setReloadUseCallback(!reloadUseCallback);
    setLoading(true);
  };

  return (
    <>
      <Grid container className={classes.root}>
        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className={classes.titles}
        >
          <Typography variant="h5" gutterBottom>
            Cadastre-se de Graça e Resgate seu Cupom
          </Typography>
          <Typography
            variant="body1"
            style={{ paddingLeft: 30, paddingRight: 30 }}
            gutterBottom
          >
            Aproveite os descontos exclusivos em produtos e serviços na sua
            cidade e região!
          </Typography>
          <Typography
            variant="body1"
            style={{ paddingLeft: 30, paddingRight: 30 }}
          >
            Depois vá no menu 'Meus Cupons' clique em 'Validar Cupom' fale
            diretamente com os lojistas pelo WhatsApp.
          </Typography>
        </Grid>
        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className={classes.categories}
        >
          <FormControl variant="outlined" className={classes.searchCity}>
            <TextField
              label="Busque por produtos"
              variant="outlined"
              value={tempFilter}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleSearchProductClick} edge="end">
                      <SearchIcon style={{ color: "white" }} />
                    </IconButton>
                  </InputAdornment>
                ),
                classes: {
                  input: classes.inputColor,
                  notchedOutline: classes.notchedOutline,
                },
              }}
              InputLabelProps={{
                style: {
                  color: "white",
                },
              }}
              onInput={(e) => {
                setTempFilter(e.target.value);
              }}
              className={classes.inputStyle}
              style={{ marginBottom: 20 }}
            />
          </FormControl>
        </Grid>
        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className={classes.categories}
          style={{ marginTop: 20 }}
        >
          <Box
            sx={{
              display: "flex",
              overflowX: "scroll",
              padding: 2,
              "&::-webkit-scrollbar": {
                display: "none",
              },
              "-ms-overflow-style": "none", // IE e Edge
              "scrollbar-width": "none", // Firefox
              marginBottom: 20,
            }}
          >
            <Button
              variant={selectedCategory === "all" ? "contained" : "outlined"}
              color="secondary"
              className={classes.categoryButton}
              onClick={() => handleCategoryClick("all")}
            >
              Todos
            </Button>
            <Button
              variant={selectedCategory === "pets" ? "contained" : "outlined"}
              color="secondary"
              className={classes.categoryButton}
              onClick={() => handleCategoryClick("pets")}
            >
              Pets
            </Button>
            <Button
              variant={selectedCategory === "beauty" ? "contained" : "outlined"}
              color="secondary"
              className={classes.categoryButton}
              onClick={() => handleCategoryClick("beauty")}
            >
              Beleza
            </Button>
            <Button
              variant={selectedCategory === "food" ? "contained" : "outlined"}
              color="secondary"
              className={classes.categoryButton}
              onClick={() => handleCategoryClick("food")}
            >
              Comida
            </Button>
            <Button
              variant={selectedCategory === "health" ? "contained" : "outlined"}
              color="secondary"
              className={classes.categoryButton}
              onClick={() => handleCategoryClick("health")}
            >
              Saúde
            </Button>

            <Button
              variant={selectedCategory === "home" ? "contained" : "outlined"}
              color="secondary"
              className={classes.categoryButton}
              onClick={() => handleCategoryClick("home")}
            >
              Casa
            </Button>
            <Button
              variant={selectedCategory === "cloth" ? "contained" : "outlined"}
              color="secondary"
              className={classes.categoryButton}
              onClick={() => handleCategoryClick("cloth")}
            >
              Vestuário
            </Button>
            <Button
              variant={
                selectedCategory === "electronic" ? "contained" : "outlined"
              }
              color="secondary"
              className={classes.categoryButton}
              onClick={() => handleCategoryClick("electronic")}
            >
              Eletrônicos
            </Button>
            <Button
              variant={
                selectedCategory === "automotive" ? "contained" : "outlined"
              }
              color="secondary"
              className={classes.categoryButton}
              onClick={() => handleCategoryClick("automotive")}
            >
              Automotivo
            </Button>
            <Button
              variant={selectedCategory === "kid" ? "contained" : "outlined"}
              color="secondary"
              className={classes.categoryButton}
              onClick={() => handleCategoryClick("kid")}
            >
              Kids
            </Button>
            <Button
              variant={selectedCategory === "book" ? "contained" : "outlined"}
              color="secondary"
              className={classes.categoryButton}
              onClick={() => handleCategoryClick("book")}
            >
              Livraria
            </Button>
            <Button
              variant={selectedCategory === "other" ? "contained" : "outlined"}
              color="secondary"
              className={classes.categoryButton}
              onClick={() => handleCategoryClick("other")}
            >
              Diversos
            </Button>
          </Box>
        </Grid>
        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className={classes.campaigns}
        >
          <Grid container className={classes.campaignsSubContainer}>
            {!loading ? (
              emphasis.map((item, index) => (
                <div key={index} className={classes.motionDiv}>
                  <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                    <CampaignPreview
                      description={item.description}
                      title={item.fullName}
                      category={
                        item.category === "health"
                          ? "Saúde"
                          : item.category === "beauty"
                          ? "Beleza"
                          : item.category === "cloth"
                          ? "Vestuário"
                          : item.category === "food"
                          ? "Comida"
                          : item.category === "home"
                          ? "Casa"
                          : item.category === "pets"
                          ? "Pets"
                          : item.category === "electronic"
                          ? "Eletrônicos"
                          : item.category === "automotive"
                          ? "Automotivo"
                          : item.category === "kid"
                          ? "Kids"
                          : item.category === "book"
                          ? "Livraria"
                          : "Diversos"
                      }
                      remaining={item.remainingCouponsAvailable}
                      banner={item.banner}
                      store={item.userId?.fullName || "Loja"}
                      storeId={item.userId?._id}
                      campaignId={item._id}
                      city={`${item.city} / ${item.state}`}
                    />
                  </Grid>
                </div>
              ))
            ) : (
              <div
                style={{
                  width: "100%",
                  height: "30vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress style={{ color: "white" }} size={54} />
              </div>
            )}
            {!loading && emphasis.length === 0 && (
              <Typography
                variant="h6"
                style={{
                  textAlign: "center",
                  color: "white",
                  marginTop: 20,
                  paddingLeft: 10,
                  paddingRight: 10,
                }}
              >
                Ops! Não há cupons cadastrados com os filtros selecionados.
                <br></br>
                Que tal se cadastrar e ser o primeiro a divulgar seus cupons?
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
      {itemsCounter >= 20 && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            variant="outlined"
            color="secondary"
            className={classes.buttonSeeAll}
            onClick={handleSeeMoreButton}
          >
            Ver mais
          </Button>
        </div>
      )}
    </>
  );
}

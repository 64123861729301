import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";
import { Context } from "../../context";

import ManagePackage from "./managePackage";
import BuyPackage from "./buyPackages";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    justifyContent: "center",
    margin: "30px",
    marginTop: "100px",
    [theme.breakpoints.up("xxs")]: {
      minHeight: "80vh",
    },
    [theme.breakpoints.up("xs")]: {
      minHeight: "80vh",
    },
    [theme.breakpoints.up("sm")]: {
      minHeight: "80vh",
    },
    [theme.breakpoints.up("md")]: {
      minHeight: "80vh",
    },
    [theme.breakpoints.up("lg")]: {
      minHeight: "84vh",
    },
    [theme.breakpoints.up("xl")]: {
      minHeight: "84vh",
    },
    scrollbarWidth: "thin",
  },
}));

export default function User() {
  const classes = useStyles();

  const { cookie } = useContext(Context);

  return (
    <Paper elevation={2} className={classes.root}>
      {cookie.userInfo[0].role === "store" ? <BuyPackage /> : <ManagePackage />}
    </Paper>
  );
}

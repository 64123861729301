import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  IconButton,
  CircularProgress,
  Typography,
} from "@material-ui/core";

import { styled } from "@mui/material/styles";

import { Context } from "../../../context";
import Api from "../../../service";

import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import Autocomplete from "@mui/material/Autocomplete";
import PhoneFormatter from "../../../utils/PhoneFormatter";

const useStyles = makeStyles((theme) => ({
  inputMaior: {
    marginBottom: 20,
    width: "100%",
  },
  inputStyle: {
    width: "100%",
  },
  hover: {
    "&:hover": {
      color: "#44533f",
    },
  },
  uploadBanner: {
    display: "flex",
    [theme.breakpoints.up("xxs")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
    [theme.breakpoints.up("xs")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
    [theme.breakpoints.up("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
    [theme.breakpoints.up("md")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
    [theme.breakpoints.up("lg")]: {
      flexDirection: "row",
      alignItems: "center",
    },
    [theme.breakpoints.up("xl")]: {
      flexDirection: "row",
      alignItems: "center",
    },
  },
  uploadBannerLabel: {
    [theme.breakpoints.up("xxs")]: {
      marginLeft: 0,
      marginTop: 10,
    },
    [theme.breakpoints.up("xs")]: {
      marginLeft: 0,
      marginTop: 10,
    },
    [theme.breakpoints.up("sm")]: {
      marginLeft: 0,
      marginTop: 10,
    },
    [theme.breakpoints.up("md")]: {
      marginLeft: 0,
      marginTop: 10,
    },
    [theme.breakpoints.up("lg")]: {
      marginLeft: 10,
      marginTop: 0,
    },
    [theme.breakpoints.up("xl")]: {
      marginLeft: 10,
      marginTop: 0,
    },
  },
  cityStyle: {
    [theme.breakpoints.up("xxs")]: {
      paddingRight: 0,
    },
    [theme.breakpoints.up("xs")]: {
      paddingRight: 0,
    },
    [theme.breakpoints.up("sm")]: {
      paddingRight: 0,
    },
    [theme.breakpoints.up("md")]: {
      paddingRight: 0,
    },
    [theme.breakpoints.up("lg")]: {
      paddingRight: 10,
    },
    [theme.breakpoints.up("xl")]: {
      paddingRight: 10,
    },
  },
  autocomplete: {
    "& .MuiAutocomplete-inputRoot": {
      minHeight: "56px",
    },
  },
}));

export default function CreateCampaignItems() {
  const {
    campaignName,
    campaignStatus,
    setCampaignStatus,
    setCampaignName,
    campaignCouponsAvailable,
    setCampaignCouponsAvailable,
    campaignContact,
    setCampaignContact,
    setCampaignBanner,
    campaignCategory,
    setCampaignCategory,
    campaignDescription,
    setCampaignDescription,
    errorCampaignName,
    setErrorCampaignName,
    errorCampaignCouponsAvailable,
    setErrorCampaignCouponsAvailable,
    errorCampaignContact,
    setErrorCampaignContact,
    errorCampaignDescription,
    setErrorCampaignDescription,
    isFromEditCampaign,
    valueEdit,
    cookie,
    campaignCity,
    setCampaignCity,
    setIsLoadingCampaign,
  } = useContext(Context);

  const [bannerFileName, setBannerFileName] = useState("Escolha um ficheiro");
  const [cities, setCities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    const token = cookie.token.accessToken;
    Api.defaults.headers.common["x-access-token"] = token;

    if (!isFromEditCampaign) {
      setCampaignName("");
      setCampaignCouponsAvailable("");
      setCampaignContact("");
      setCampaignBanner("default");
      setCampaignStatus("active");
      setCampaignDescription("");
      setCampaignCategory("other");
      setCampaignCity(
        `${cookie.userInfo[0].address.city} / ${cookie.userInfo[0].address.state}`
      );
      setIsLoadingCampaign(false);
      setBannerFileName("Escolha um ficheiro");
    } else {
      valueEdit !== "" &&
        Api.get("/api/campaign/detail", { params: { _id: valueEdit } })
          .then((res) => {
            setCampaignName(res.data[0].fullName);
            setCampaignCouponsAvailable(res.data[0].couponsAvailable);
            setCampaignContact(res.data[0].contact);
            setCampaignBanner(res.data[0].banner);
            setCampaignStatus(res.data[0].status);
            setCampaignDescription(res.data[0].description);
            setCampaignCategory(res.data[0].category);
            setCampaignCity(`${res.data[0].city} / ${res.data[0].state}`);
            setIsLoadingCampaign(false);
          })
          .catch(() => {
            setCampaignName("");
            setCampaignCouponsAvailable("");
            setCampaignContact("");
            setCampaignBanner("default");
            setCampaignStatus("active");
            setCampaignDescription("");
            setCampaignCategory("other");
            setCampaignCity(
              `${cookie.userInfo[0].address.city} / ${cookie.userInfo[0].address.state}`
            );
            setIsLoadingCampaign(false);
            setBannerFileName("Escolha um ficheiro");
          });
    }
    setErrorCampaignName(false);
    setErrorCampaignCouponsAvailable(false);
    setErrorCampaignContact(false);
    setErrorCampaignDescription(false);
  }, []); // eslint-disable-line

  const classes = useStyles();

  useEffect(() => {
    const fetchCities = async () => {
      try {
        const response = await fetch(
          `https://servicodados.ibge.gov.br/api/v1/localidades/municipios?orderBy=nome`
        );
        if (response.ok) {
          const data = await response.json();
          const cityNames = data.map((city) => {
            return `${city.nome} / ${city.microrregiao.mesorregiao.UF.sigla}`;
          });
          setCities(cityNames);
          setLoading(false);
        } else {
          console.error("Erro ao buscar cidades");
        }
      } catch (error) {
        console.error("Erro na requisição:", error);
      }
    };

    fetchCities();
  }, []);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    const fileName = event.target.files[0].name;

    reader.onload = (e) => {
      const base64Image = e.target.result;

      setCampaignBanner(base64Image);
      setBannerFileName(fileName);
    };

    reader.readAsDataURL(file);
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const handleCancelBannerUpload = () => {
    setBannerFileName("Escolha um ficheiro");
    setCampaignBanner("default");
  };

  return loading ? (
    <Grid
      container
      style={{
        width: "78vw",
        height: "80vh",
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
      }}
    >
      <CircularProgress />
    </Grid>
  ) : (
    <>
      <Grid container className={classes.inputStyle}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <TextField
            id="fullName"
            required
            value={campaignName || ""}
            error={!!errorCampaignName}
            style={{ marginRight: 10 }}
            onInvalid={(e) => {
              setErrorCampaignName(true);
            }}
            onInput={(e) => {
              setCampaignName(e.target.value);
              setErrorCampaignName(false);
            }}
            inputProps={{ maxLength: 30 }}
            label="Nome da campanha"
            variant="outlined"
            className={classes.inputMaior}
          />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <TextField
            id="campaignCouponsAvailable"
            required
            value={campaignCouponsAvailable || ""}
            error={!!errorCampaignCouponsAvailable}
            disabled={isFromEditCampaign}
            type="number"
            onInvalid={(e) => {
              setErrorCampaignCouponsAvailable(true);
            }}
            onInput={(e) => {
              setCampaignCouponsAvailable(e.target.value);
              setErrorCampaignCouponsAvailable(false);
            }}
            label="Total de Cupons"
            variant="outlined"
            className={classes.inputMaior}
          />
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <TextField
            id="campaignDescription"
            required
            value={campaignDescription || ""}
            error={!!errorCampaignDescription}
            onInvalid={(e) => {
              setErrorCampaignDescription(true);
            }}
            onInput={(e) => {
              setCampaignDescription(e.target.value);
              setErrorCampaignDescription(false);
            }}
            inputProps={{ maxLength: 80 }}
            multiline
            label="Descrição da Campanha"
            variant="outlined"
            minRows={2}
            className={classes.inputMaior}
          />
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <TextField
            id="campaignContact"
            required
            value={PhoneFormatter.formatPhoneNumber(campaignContact)}
            error={!!errorCampaignContact}
            onChange={(e) => {
              setCampaignContact(
                PhoneFormatter.formatPhoneNumber(e.target.value)
              );
              setErrorCampaignContact(false);
            }}
            type="tel"
            onInvalid={() => setErrorCampaignContact(true)}
            label="Telefone"
            variant="outlined"
            fullWidth
            inputProps={{ maxLength: 15 }}
            className={classes.inputMaior}
          />
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <FormControl variant="outlined" className={classes.inputMaior}>
            <Autocomplete
              value={campaignCity}
              onChange={(event, newValue) => {
                setCampaignCity(newValue);
              }}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              options={cities}
              getOptionLabel={(option) => option || ""}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Cidade"
                  variant="outlined"
                  required
                />
              )}
              className={classes.autocomplete}
            />
          </FormControl>
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <FormControl variant="outlined" className={classes.inputMaior}>
            <InputLabel>Categoria</InputLabel>
            <Select
              id="campaignCategory"
              value={campaignCategory}
              onChange={(e) => setCampaignCategory(e.target.value)}
              label="Categoria"
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
                PaperProps: {
                  style: {
                    maxHeight: 200,
                  },
                },
              }}
            >
              <MenuItem value={"other"}>Diversos</MenuItem>
              <MenuItem value={"health"}>Saúde</MenuItem>
              <MenuItem value={"beauty"}>Beleza</MenuItem>
              <MenuItem value={"cloth"}>Vestuário</MenuItem>
              <MenuItem value={"food"}>Comida</MenuItem>
              <MenuItem value={"home"}>Casa</MenuItem>
              <MenuItem value={"pets"}>Pets</MenuItem>
              <MenuItem value={"electronic"}>Eletrônicos</MenuItem>
              <MenuItem value={"automotive"}>Automotivos</MenuItem>
              <MenuItem value={"kid"}>Kids</MenuItem>
              <MenuItem value={"book"}>Livraria</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <FormControl variant="outlined" className={classes.inputMaior}>
            <InputLabel>Status da Camapanha</InputLabel>
            <Select
              id="campaignStatus"
              value={campaignStatus}
              onChange={(e) => setCampaignStatus(e.target.value)}
              label="Status da Camapanha"
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
                PaperProps: {
                  style: {
                    maxHeight: 200,
                  },
                },
              }}
            >
              <MenuItem value={"active"}>Habilitado</MenuItem>
              <MenuItem value={"inactive"}>Desabilitado</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className={classes.uploadBanner}
        >
          <Button
            component="label"
            role={undefined}
            variant="contained"
            tabIndex={-1}
            color="primary"
            startIcon={<CloudUploadIcon />}
          >
            Banner
            <VisuallyHiddenInput type="file" onChange={handleImageUpload} />
          </Button>
          <div className={classes.uploadBannerLabel}>
            <label>{bannerFileName}</label>
            <IconButton
              style={{
                marginLeft: 5,
                width: 20,
                height: 20,
                visibility:
                  bannerFileName === "Escolha um ficheiro"
                    ? "hidden"
                    : "visible",
              }}
              onClick={handleCancelBannerUpload}
            >
              <CloseOutlinedIcon style={{ width: 20, color: "red" }} />
            </IconButton>
          </div>
        </Grid>
        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className={classes.uploadBanner}
          style={{ marginTop: 10 }}
        >
          <Typography variant="body2">
            Tamanho máximo do banner: <strong>50MB</strong>
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}

import React, { useState, useEffect, useRef } from "react";
import {
  Grid,
  Typography,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import history from "../../routes/history";
import Api from "../../service";

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up("xxs")]: {
      marginBottom: "30px",
      marginTop: 120,
    },
    [theme.breakpoints.up("xs")]: {
      marginBottom: "30px",
      marginTop: 120,
    },
    [theme.breakpoints.up("sm")]: {
      marginBottom: "30px",
      marginTop: 120,
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: "30px",
      marginTop: 120,
    },
    [theme.breakpoints.up("lg")]: {
      marginTop: 170,
    },
    [theme.breakpoints.up("xl")]: {
      marginTop: 170,
    },
  },
  inputColor: {
    color: theme.palette.primary.light,
    fontSize: "30px",
    textAlign: "center",
  },
  notchedOutline: {
    backgroundColor: "transparent",
    borderColor: theme.palette.primary.light,
    textColor: theme.palette.primary.light,
  },
  inputMaior: {
    width: "50px",
    [`& fieldset`]: {
      borderRadius: 20,
      borderWidth: 2,
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.primary.lightSecondary,
        borderWidth: 2,
      },
      "&:hover fieldset": {
        borderColor: theme.palette.primary.lightSecondary,
        borderWidth: 2,
      },
    },
  },
  buttonStyle: {
    marginTop: 70,
    width: 200,
    fontWeight: "bold",
    borderRadius: 20,
    borderWidth: 2,
    height: 45,
  },
}));

export default function VerifyUser() {
  const classes = useStyles();
  const [first, setFirst] = useState("");
  const [second, setSecond] = useState("");
  const [third, setThird] = useState("");
  const [fourth, setFourth] = useState("");
  const [fiveth, setFiveth] = useState("");
  const [sixth, setSixth] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorStatusCode, setErrorStatusCode] = useState(null);

  const firstRef = useRef(null);
  const secondRef = useRef(null);
  const thirdRef = useRef(null);
  const fourthRef = useRef(null);
  const fivethRef = useRef(null);
  const sixthRef = useRef(null);

  useEffect(() => {
    setFirst("");
    setSecond("");
    setThird("");
    setFourth("");
    setFiveth("");
    setSixth("");
    setErrorStatusCode(null);
    setErrorMessage("");
  }, []);

  const handleConfirmButton = () => {
    if (
      first !== "" &&
      second !== "" &&
      third !== "" &&
      fourth !== "" &&
      fiveth !== "" &&
      sixth !== ""
    ) {
      const confirmationCode =
        first.toString() +
        second.toString() +
        third.toString() +
        fourth.toString() +
        fiveth.toString() +
        sixth.toString();
      Api.put("/api/user/confirmAccount", {
        confirmationCode: confirmationCode,
      })
        .then((res) => {
          console.log(res.status);
          setErrorMessage(res.data.message);
          setOpenModal(true);
        })
        .catch((err) => {
          const statusCode = err.response ? err.response.status : null;
          setErrorStatusCode(statusCode);
          setErrorMessage(err.response.data.message);
          setOpenModal(true);
        });
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    if (errorStatusCode === null) {
      history.push("/");
    }
  };

  return (
    <Grid container className={classes.root}>
      <Grid
        item
        xl={12}
        lg={12}
        md={12}
        sm={12}
        xs={12}
        style={{ color: "white", textAlign: "center" }}
      >
        <Typography variant="h4">Confirmação de cadastro</Typography>
        <Typography variant="body1" style={{ padding: 20 }}>
          Insira abaixo o código de confirmação de 6 dígitos recebido em seu
          e-mail para confirmar seu cadastro.
        </Typography>
        <Typography variant="body1" style={{ padding: 20, paddingTop: 0 }}>
          Lembre-se de verificar sua caixa de Spam ou Lixo Eletrônico.
        </Typography>
        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{ marginTop: 50 }}
        >
          <TextField
            id="first"
            required
            value={first}
            style={{ marginRight: 10 }}
            onInput={(e) => {
              setFirst(e.target.value);
              if (e.target.value.length > 0) {
                secondRef.current.focus();
              }
            }}
            inputProps={{ maxLength: 1 }}
            InputProps={{
              classes: {
                input: classes.inputColor,
                notchedOutline: classes.notchedOutline,
              },
            }}
            InputLabelProps={{
              style: {
                color: "white",
              },
            }}
            variant="outlined"
            className={classes.inputMaior}
            inputRef={firstRef}
          />
          <TextField
            id="second"
            required
            value={second}
            style={{ marginRight: 10 }}
            onInput={(e) => {
              setSecond(e.target.value);
              if (e.target.value.length > 0) {
                thirdRef.current.focus();
              }
            }}
            inputProps={{ maxLength: 1 }}
            InputProps={{
              classes: {
                input: classes.inputColor,
                notchedOutline: classes.notchedOutline,
              },
            }}
            InputLabelProps={{
              style: {
                color: "white",
              },
            }}
            variant="outlined"
            className={classes.inputMaior}
            inputRef={secondRef}
          />
          <TextField
            id="third"
            required
            value={third}
            style={{ marginRight: 10 }}
            onInput={(e) => {
              setThird(e.target.value);
              if (e.target.value.length > 0) {
                fourthRef.current.focus();
              }
            }}
            inputProps={{ maxLength: 1 }}
            InputProps={{
              classes: {
                input: classes.inputColor,
                notchedOutline: classes.notchedOutline,
              },
            }}
            InputLabelProps={{
              style: {
                color: "white",
              },
            }}
            variant="outlined"
            className={classes.inputMaior}
            inputRef={thirdRef}
          />
          <TextField
            id="fourth"
            required
            value={fourth}
            style={{ marginRight: 10 }}
            onInput={(e) => {
              setFourth(e.target.value);
              if (e.target.value.length > 0) {
                fivethRef.current.focus();
              }
            }}
            inputProps={{ maxLength: 1 }}
            InputProps={{
              classes: {
                input: classes.inputColor,
                notchedOutline: classes.notchedOutline,
              },
            }}
            InputLabelProps={{
              style: {
                color: "white",
              },
            }}
            variant="outlined"
            className={classes.inputMaior}
            inputRef={fourthRef}
          />
          <TextField
            id="fiveth"
            required
            value={fiveth}
            style={{ marginRight: 10 }}
            onInput={(e) => {
              setFiveth(e.target.value);
              if (e.target.value.length > 0) {
                sixthRef.current.focus();
              }
            }}
            inputProps={{ maxLength: 1 }}
            InputProps={{
              classes: {
                input: classes.inputColor,
                notchedOutline: classes.notchedOutline,
              },
            }}
            InputLabelProps={{
              style: {
                color: "white",
              },
            }}
            variant="outlined"
            className={classes.inputMaior}
            inputRef={fivethRef}
          />
          <TextField
            id="sixth"
            required
            value={sixth}
            onInput={(e) => {
              setSixth(e.target.value);
            }}
            inputProps={{ maxLength: 1 }}
            InputProps={{
              classes: {
                input: classes.inputColor,
                notchedOutline: classes.notchedOutline,
              },
            }}
            InputLabelProps={{
              style: {
                color: "white",
              },
            }}
            variant="outlined"
            className={classes.inputMaior}
            inputRef={sixthRef}
          />
        </Grid>
        <Button
          variant="outlined"
          color="secondary"
          className={classes.buttonStyle}
          onClick={handleConfirmButton}
        >
          Confirmar
        </Button>
      </Grid>
      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle>
          <strong>
            <em>Atenção:</em>
          </strong>
        </DialogTitle>
        <DialogContent style={{ textAlign: "center" }}>
          <Typography variant="h6" style={{ color: "gray" }}>
            {errorMessage}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseModal}
            style={{ color: "#808080" }}
            autoFocus
          >
            <strong>fechar</strong>
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}

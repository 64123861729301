import React, { useContext, useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  IconButton,
  TableCell,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Grid,
  Tooltip,
  Typography,
} from "@material-ui/core";

import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import DeleteIcon from "@material-ui/icons/Delete";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";
import CheckroomOutlinedIcon from "@mui/icons-material/CheckroomOutlined";
import LocalHospitalOutlinedIcon from "@mui/icons-material/LocalHospitalOutlined";
import AutoAwesomeOutlinedIcon from "@mui/icons-material/AutoAwesomeOutlined";
import RestaurantOutlinedIcon from "@mui/icons-material/RestaurantOutlined";
import CottageOutlinedIcon from "@mui/icons-material/CottageOutlined";
import PetsOutlinedIcon from "@mui/icons-material/PetsOutlined";
import AppShortcutOutlinedIcon from "@mui/icons-material/AppShortcutOutlined";
import DirectionsCarOutlinedIcon from "@mui/icons-material/DirectionsCarOutlined";
import ChildFriendlyOutlinedIcon from "@mui/icons-material/ChildFriendlyOutlined";
import Diversity1OutlinedIcon from "@mui/icons-material/Diversity1Outlined";

import MenuBookOutlinedIcon from "@material-ui/icons/MenuBookOutlined";
import BlockIcon from "@mui/icons-material/Block";
import CheckIcon from "@mui/icons-material/Check";

import { Context } from "../../context";
import Api from "../../service";
import history from "../../routes/history";
import FilterStore from "./filterStore";

const useStyles = makeStyles((theme) => ({
  general: {
    display: "flex",
    width: "90vw",
    height: "100%",
    [theme.breakpoints.up("xxs")]: {
      width: "80vw",
    },
    [theme.breakpoints.up("xs")]: {
      width: "80vw",
    },
    [theme.breakpoints.up("sm")]: {
      width: "90vw",
    },
    [theme.breakpoints.up("md")]: {
      width: "90vw",
    },
    [theme.breakpoints.up("lg")]: {
      width: "90vw",
    },
    [theme.breakpoints.up("xl")]: {
      width: "90vw",
    },
  },
  setpContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignContent: "space-between",
    height: "100%",
  },
  delete: {
    marginRight: 30,
  },
  modalDialog: {
    textAlign: "left",
  },
  modalText: {
    color: "gray",
    fontSize: "18px",
    fontWeight: "bold",
  },
  large: {
    height: 30,
    width: 30,
    fontSize: 15,
    marginTop: 0,
    textAlign: "center",
  },
  fontIcon: {
    fontSize: 28,
  },
  hover: {
    "&:hover": {
      color: "#211f58",
    },
  },
  input: {
    width: "90%",
    marginBottom: "30px",
  },
  hideAvatar: {
    [theme.breakpoints.up("xxs")]: {
      visibility: "visible",
      margin: "5px 10px 5px 5px",
    },
    [theme.breakpoints.up("xs")]: {
      visibility: "visible",
      margin: "5px 10px 5px 5px",
    },
    [theme.breakpoints.up("sm")]: {
      visibility: "visible",
      marginRight: 15,
    },
    [theme.breakpoints.up("md")]: {
      visibility: "visible",
      marginRight: 15,
    },
    [theme.breakpoints.up("lg")]: {
      visibility: "visible",
      marginRight: 15,
    },
    [theme.breakpoints.up("xl")]: {
      visibility: "visible",
      marginRight: 15,
    },
  },
  userNameRow: {
    display: "flex",
    alignItems: "center",
  },
  stepper: {
    paddingTop: 25,
    [theme.breakpoints.up("xxs")]: {
      paddingLeft: 15,
    },
    [theme.breakpoints.up("xs")]: {
      paddingLeft: 15,
    },
    [theme.breakpoints.up("sm")]: {
      paddingLeft: 15,
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: 15,
    },
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 0,
    },
    [theme.breakpoints.up("xl")]: {
      paddingLeft: 0,
    },
  },
  datatableStyle: {
    [theme.breakpoints.up("xxs")]: {
      minHeight: "55vh",
    },
    [theme.breakpoints.up("xs")]: {
      minHeight: "55vh",
    },
    [theme.breakpoints.up("sm")]: {
      minHeight: "55vh",
    },
    [theme.breakpoints.up("md")]: {
      minHeight: "55vh",
    },
    [theme.breakpoints.up("lg")]: {
      minHeight: "70vh",
    },
    [theme.breakpoints.up("xl")]: {
      minHeight: "70vh",
    },
  },
}));

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const ManageCampaign = () => {
  const {
    cookie,
    packageList,
    setPackageList,
    setPage,
    setOpenPackage,
    setSelectedIndex,
    filteredStore,
  } = useContext(Context);

  const classes = useStyles();

  const [deleteMessage, setDeleteMessage] = useState("");
  const [openResponse, setOpenResponse] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [valueDelete, setValueDelete] = useState("");
  const [campaignDelete, setCampaignDelete] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const [stepperOrientation, setStepperOrientation] = useState("vertical");
  const [creditDelete, setCreditDelete] = useState(0);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    const newWindowDimensions = getWindowDimensions();
    function handleResize() {
      setWindowDimensions(newWindowDimensions);
    }

    const newSetepperOrientation =
      newWindowDimensions.width >= 0 && newWindowDimensions.width <= 300
        ? "vertical"
        : newWindowDimensions.width >= 300 && newWindowDimensions.width <= 600
        ? "vertical"
        : newWindowDimensions.width >= 600 && newWindowDimensions.width <= 900
        ? "vertical"
        : newWindowDimensions.width >= 900 && newWindowDimensions.width <= 1280
        ? "vertical"
        : newWindowDimensions.width >= 1280 && newWindowDimensions.width <= 1920
        ? "horizontal"
        : "horizontal";

    setStepperOrientation(newSetepperOrientation);

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [windowDimensions]);

  const steps = ["Selecione uma loja", "Consultar campanhas da loja"];

  var newCampaignList;

  // const handleSeeCampaign = (id) => {
  //   setValueEdit(id);
  //   setIsFromCreateCampaign(true);
  //   setIsFromEditCampaign(true);
  // };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  // Code snippet that avoid warning in MUIDataTable under 4.1.12v
  const oldRender = TableCell.render;

  TableCell.render = function (...args) {
    const [props, ...otherArgs] = args;
    if (typeof props === "object" && props && "isEmpty" in props) {
      const { isEmpty, ...propsWithoutEmpty } = props;
      return oldRender.apply(this, [propsWithoutEmpty, ...otherArgs]);
    } else {
      return oldRender.apply(this, args);
    }
  };
  // Code snippet that avoid warning in MUIDataTable under 4.1.12v

  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      fontSize: "1.2rem",
    },
  }))(TableCell);

  useEffect(() => {
    const token = cookie.token.accessToken;

    Api.defaults.headers.common["x-access-token"] = token;

    setPage("Campanhas");
    setSelectedIndex([3, "Campanhas"]);
    setOpenPackage(true);
    setCreditDelete(0);
    setValueDelete("");
    setCampaignDelete("");
  }, []); // eslint-disable-line

  useEffect(() => {
    if (filteredStore && filteredStore.includes("!")) {
      Api.get("/api/campaign/detail/user", {
        params: {
          userId: filteredStore.split("!")[0],
        },
      })
        .then((res) => {
          const newUpdatedCampaignList =
            res.data.length > 0
              ? res.data.map((campaign) => {
                  return {
                    ...campaign,
                    category:
                      campaign.category === "health"
                        ? "Saúde"
                        : campaign.category === "beauty"
                        ? "Beleza"
                        : campaign.category === "cloth"
                        ? "Vestuário"
                        : campaign.category === "food"
                        ? "Comida"
                        : campaign.category === "home"
                        ? "Casa"
                        : campaign.category === "pets"
                        ? "Pets"
                        : campaign.category === "electronic"
                        ? "Eletrônicos"
                        : campaign.category === "automotive"
                        ? "Automotivo"
                        : campaign.category === "kid"
                        ? "Kids"
                        : campaign.category === "book"
                        ? "Livraria"
                        : "Diversos",
                  };
                })
              : res.data;

          setPackageList(newUpdatedCampaignList);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [filteredStore]);

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  newCampaignList = packageList && packageList;

  var countStatus = 0;
  var totalRows = 0;

  newCampaignList &&
    newCampaignList.forEach((data) => {
      totalRows += 1;
      data.status === "inactive" && (countStatus += 1);
    });

  const columns = [
    {
      name: "_id",
      label: "ID",
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "status",
      label: "status",
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "city",
      label: "city",
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "state",
      label: "state",
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "category",
      label: "Categoria",
      options: {
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return (
            <StyledTableCell key={columnMeta.index}>
              {columnMeta.label}
            </StyledTableCell>
          );
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Grid
              item
              xs={10}
              style={{
                marginTop: 5,
                color: tableMeta.rowData[1] === "active" ? "black" : "gray",
                display: "flex",
                alignItems: "center",
                alignContent: "center",
              }}
            >
              {value === "Saúde" ? (
                <LocalHospitalOutlinedIcon />
              ) : value === "Beleza" ? (
                <AutoAwesomeOutlinedIcon />
              ) : value === "Vestuário" ? (
                <CheckroomOutlinedIcon />
              ) : value === "Comida" ? (
                <RestaurantOutlinedIcon />
              ) : value === "Casa" ? (
                <CottageOutlinedIcon />
              ) : value === "Pets" ? (
                <PetsOutlinedIcon />
              ) : value === "Eletrônicos" ? (
                <AppShortcutOutlinedIcon />
              ) : value === "Automotivo" ? (
                <DirectionsCarOutlinedIcon />
              ) : value === "Kids" ? (
                <ChildFriendlyOutlinedIcon />
              ) : value === "Livraria" ? (
                <MenuBookOutlinedIcon />
              ) : (
                <Diversity1OutlinedIcon />
              )}{" "}
              <div style={{ marginLeft: 10, marginTop: 3 }}>{value}</div>
            </Grid>
          );
        },
      },
    },
    {
      name: "fullName",
      label: "Nome",
      options: {
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return (
            <StyledTableCell key={columnMeta.index}>
              {columnMeta.label}
            </StyledTableCell>
          );
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Grid
                item
                xs={10}
                style={{
                  marginTop: 5,
                  color: tableMeta.rowData[1] === "active" ? "black" : "gray",
                }}
              >
                {value}
              </Grid>
            </>
          );
        },
      },
    },
    {
      name: "fullName",
      label: "Cidade",
      options: {
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return (
            <StyledTableCell key={columnMeta.index}>
              {columnMeta.label}
            </StyledTableCell>
          );
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Grid
                item
                xs={10}
                style={{
                  marginTop: 5,
                  color: tableMeta.rowData[1] === "active" ? "black" : "gray",
                }}
              >
                {`${tableMeta.rowData[2]} / ${tableMeta.rowData[3]}`}
              </Grid>
            </>
          );
        },
      },
    },
    {
      name: "couponsAvailable",
      label: "Total de cupons",
      options: {
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return (
            <StyledTableCell key={columnMeta.index}>
              {columnMeta.label}
            </StyledTableCell>
          );
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Grid
              item
              xs={10}
              style={{
                marginTop: 5,
                color: tableMeta.rowData[1] === "active" ? "black" : "gray",
              }}
            >
              {value} {value !== 1 ? "Cupons" : "Cupom"}
            </Grid>
          );
        },
      },
    },
    {
      name: "remainingCouponsAvailable",
      label: "Cupons restantes",
      options: {
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return (
            <StyledTableCell key={columnMeta.index}>
              {columnMeta.label}
            </StyledTableCell>
          );
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Grid
              item
              xs={10}
              style={{
                marginTop: 5,
                color: tableMeta.rowData[1] === "active" ? "black" : "gray",
              }}
            >
              {value} {value !== 1 ? "Cupons" : "Cupom"}
            </Grid>
          );
        },
      },
    },
    {
      name: "contact",
      label: "Contato",
      options: {
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return (
            <StyledTableCell key={columnMeta.index}>
              {columnMeta.label}
            </StyledTableCell>
          );
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Grid
              item
              xs={10}
              style={{
                marginTop: 5,
                color: tableMeta.rowData[1] === "active" ? "black" : "gray",
              }}
            >
              {value}
            </Grid>
          );
        },
      },
    },
    {
      name: "description",
      label: "Descrição",
      options: {
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return (
            <StyledTableCell key={columnMeta.index}>
              {columnMeta.label}
            </StyledTableCell>
          );
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Grid
              item
              xs={10}
              style={{
                marginTop: 5,
                color: tableMeta.rowData[1] === "active" ? "black" : "gray",
              }}
            >
              {value}
            </Grid>
          );
        },
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return (
            <StyledTableCell key={columnMeta.index}>
              {columnMeta.label}
            </StyledTableCell>
          );
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Grid
              item
              xs={10}
              style={{
                marginTop: 5,
                color: tableMeta.rowData[1] === "active" ? "black" : "gray",
              }}
            >
              {value === "active" ? (
                <CheckIcon
                  style={{
                    color: "green",
                    display: "flex",
                    justifyContent: "center",
                  }}
                />
              ) : (
                <BlockIcon
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                />
              )}
            </Grid>
          );
        },
      },
    },
    // {
    //   name: "open",
    //   label: "Editar",
    //   options: {
    //     customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
    //       return <StyledTableCell key={columnMeta.index}></StyledTableCell>;
    //     },
    //     customBodyRender: (value, tableMeta, updateValue) => {
    //       return (
    //         <>
    //           <Grid container>
    //             <Grid item xs={10}>
    //               <IconButton
    //                 onClick={() => handleSeeCampaign(tableMeta.rowData[0])}
    //               >
    //                 <Tooltip title="Ver Detalhes">
    //                   <EditNoteOutlinedIcon
    //                     className={classes.fontIcon}
    //                     style={{
    //                       color:
    //                         tableMeta.rowData[1] === "active"
    //                           ? "#ffa101"
    //                           : "gray",
    //                     }}
    //                   />
    //                 </Tooltip>
    //               </IconButton>
    //             </Grid>
    //           </Grid>
    //         </>
    //       );
    //     },
    //   },
    // },
  ];

  const handleCloseModalResponse = () => {
    setOpenResponse(false);
    window.location.reload();
  };

  function handleCloseModalConfirm(props) {
    if (props === "confirmar") {
      setOpenConfirm(false);
      setValueDelete("");
      setCreditDelete(0);
      const newCredit = Number(filteredStore.split("!")[2]) + creditDelete;

      Api.put("/api/campaign/status", { _id: valueDelete })
        .then((res) => {
          Api.put("/api/user", {
            _id: filteredStore.split("!")[0],
            credit: newCredit,
          })
            .then(() => {
              setDeleteMessage(res.data.message);
              setOpenResponse(true);
            })
            .catch(() => {
              setDeleteMessage(
                "Impossível desativar a(s) campanha(s). Servidor indisponível."
              );
              setOpenResponse(true);
            });
        })
        .catch((err) => {
          setDeleteMessage(err.response.data.message);
          setOpenResponse(true);
        });
    } else {
      setOpenConfirm(false);
    }
  }

  const handleDelete = (selectedRows, displayData) => {
    setCampaignDelete([]);

    var campaignId = selectedRows.data.map((campaign) => {
      return displayData.find((item) => item.dataIndex === campaign.dataIndex)
        .data[0];
    });

    var credits = selectedRows.data
      .map((campaign) => {
        return displayData.find((item) => item.dataIndex === campaign.dataIndex)
          .data[8].props.children[0];
      })
      .reduce((acumulator, currentValue) => acumulator + currentValue);

    setCreditDelete(credits);
    setValueDelete(campaignId);
    setOpenConfirm(true);
  };

  useEffect(() => {
    if (openConfirm) {
      valueDelete !== "" &&
        Api.get("/api/campaign/detail", { params: { _id: valueDelete } })
          .then((res) => {
            setCampaignDelete(res.data);
          })
          .catch((err) => {
            setCampaignDelete([]);
          });
    }
  }, [openConfirm]); // eslint-disable-line

  return (
    <div>
      <Paper elevation={0} className={classes.general}>
        <Grid container className={classes.setpContainer}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Stepper
              activeStep={activeStep}
              orientation={stepperOrientation}
              className={classes.stepper}
            >
              {steps.map((label) => {
                const stepProps = {};
                const labelProps = {};

                return (
                  <Step
                    key={label}
                    {...stepProps}
                    sx={{
                      "& .MuiStepLabel-root .Mui-completed": {
                        color: "#24323d", // circle color (COMPLETED)
                      },
                      "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel":
                        {
                          color: "grey.500", // Just text label (COMPLETED)
                        },
                      "& .MuiStepLabel-root .Mui-active": {
                        color: "#ffa101", // circle color (ACTIVE)
                      },
                      "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel":
                        {
                          color: "common.white", // Just text label (ACTIVE)
                        },
                      "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
                        fill: "common.white", // circle's number (ACTIVE)
                      },
                    }}
                  >
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </Grid>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            {activeStep === 0 ? (
              <FilterStore />
            ) : (
              <div className={classes.datatableStyle}>
                <MUIDataTable
                  id={"table"}
                  title={`Campanhas de ${filteredStore.split("!")[1]}`}
                  data={newCampaignList}
                  pagination="true"
                  columns={columns}
                  options={{
                    sort: true,
                    rowsPerPage: 10,
                    rowsPerPageOptions: [5, 10, 15, 20],
                    filter: false,
                    download: false,
                    responsive: "simple",
                    selectableRowsOnClick: true,
                    print: true,
                    viewColumns: true,
                    elevation: 0,
                    textLabels: {
                      body: {
                        noMatch: "Nenhuma campanha encontrada!",
                        toolTip: "Ordenar",
                        columnHeaderTooltip: (column) =>
                          `Ordenar por ${column.label}`,
                      },
                      pagination: {
                        next: "Próxima página",
                        previous: "Página anterior",
                        rowsPerPage: "Linhas por página:",
                        displayRows: "de",
                      },
                      toolbar: {
                        search: "Procurar",
                        viewColumns: "Filtrar colunas",
                        print: "Imprimir tabela",
                      },
                      viewColumns: {
                        title: "Filtrar colunas",
                      },
                      selectedRows: {
                        text: "linha(s) selecionada(s)",
                      },
                    },
                    customToolbarSelect: (selectedRows, displayData) => {
                      return (
                        <Tooltip title="Desativar Campanha">
                          <div className={classes.delete}>
                            <IconButton
                              onClick={() =>
                                handleDelete(selectedRows, displayData)
                              }
                              className={classes.hover}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </div>
                        </Tooltip>
                      );
                    },

                    isRowSelectable: (dataIndex) => {
                      return dataIndex < totalRows - countStatus ? true : false;
                    },
                  }}
                />
              </div>
            )}
          </Grid>
          <Grid
            item
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            style={{
              display: "flex",
              justifyContent: "space-around",
              paddingBottom: "15px",
            }}
          >
            <Button
              disabled={activeStep === 0}
              onClick={handleBack}
              color="primary"
            >
              Voltar
            </Button>
            <Button
              disabled={activeStep === steps.length - 1}
              color="secondary"
              onClick={handleNext}
            >
              Consultar
            </Button>
          </Grid>
        </Grid>
      </Paper>
      <Dialog
        open={openResponse}
        onClose={handleCloseModalResponse}
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle>
          <strong>
            <i>{"Atenção:"}</i>
          </strong>
        </DialogTitle>
        <DialogContent className={classes.modalDialog}>
          <Typography
            variant="h6"
            className={classes.modalText}
            style={{ textAlign: "center" }}
          >
            {deleteMessage}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseModalResponse}
            style={{ color: "#808080" }}
            autoFocus
          >
            <strong>fechar</strong>
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openConfirm}
        onClose={() => handleCloseModalConfirm("value")}
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle>
          <strong>
            <i>{"Atenção:"}</i>
          </strong>
        </DialogTitle>
        <DialogContent className={classes.modalDialog}>
          <Typography variant="h6" className={classes.modalText}>
            Você está prestes a DESATIVAR a(s) campanha(s):
            <ul style={{ color: "black" }}>
              {campaignDelete !== "" &&
                campaignDelete.map((campaign, index) => {
                  return (
                    <li key={index}>
                      {campaign.fullName} ({campaign.city} / {campaign.state})
                    </li>
                  );
                })}
            </ul>
            O saldo de {creditDelete} crédito(s) retornará ao lojista. Deseja
            continuar?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleCloseModalConfirm("negar")}
            style={{ color: "#808080" }}
            autoFocus
          >
            <strong>fechar</strong>
          </Button>
          <Button
            onClick={() => handleCloseModalConfirm("confirmar")}
            color="secondary"
            autoFocus
          >
            <strong>confirmar</strong>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ManageCampaign;

import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  CircularProgress,
  Grid,
  CardMedia,
  Typography,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { motion } from "framer-motion";

import Api from "../../service/gatewayApi";
import Api2 from "../../service/index";

import PixOutlinedIcon from "@mui/icons-material/PixOutlined";
import { Context } from "../../context";
import GenerateCurrentTime from "../../utils/GenerateCurrentTime";

const useStyles = makeStyles((theme) => ({
  button: {
    textTransform: "none",
  },
  dialogContent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "60vh",
  },
}));

function getCurrentDay() {
  var today = new Date();

  var year = today.getFullYear();
  var month = (today.getMonth() + 1).toString().padStart(2, "0");
  var day = today.getDate().toString().padStart(2, "0");

  return year + "-" + month + "-" + day;
}

export default function (props) {
  const classes = useStyles();
  const { valueToPay, openDialog, handleOpenGenerateDialog, credits } = props;
  const { cookie } = useContext(Context);
  const [loading, setLoading] = useState(true);
  const [apiResponse, setApiResponse] = useState("");
  const [copySuccess, setCopySuccess] = useState("");

  useEffect(() => {
    if (openDialog) {
      let requestNumber = Math.random().toString(36).slice(-8);
      let currentDay = getCurrentDay();
      Api.post(
        "/api/v1/gateway/request-qrcode",
        {
          requestNumber: requestNumber,
          dueDate: currentDay,
          amount: valueToPay,
          callbackUrl: "https://api.cupomzito.com/api/log/creditcharge",
          usernameCheckout: "user_charger_credit",
          client: {
            name: cookie.userInfo[0].fullName,
            document: cookie.userInfo[0].document,
            email: cookie.userInfo[0].email,
          },
        },
        {
          headers: {
            ci: process.env.REACT_APP_CI,
            cs: process.env.REACT_APP_CS,
          },
        }
      )
        .then((res) => {
          Api2.post("/api/transaction", {
            userDocument: cookie.userInfo[0].document,
            pixCode: res.data.paymentCode,
            managerId: cookie.userInfo[0].manager,
            credits: credits,
            oldCredit: cookie.userInfo[0].credit,
            timestamp: GenerateCurrentTime.getCurrentTime(),
          }).catch((err) => {
            console.error(err);
          });

          setApiResponse(res.data);
          setLoading(false);
        })
        .catch((err) => {
          console.error(err.response.data);
        });
    } else {
      setCopySuccess("");
      setLoading(true);
    }
  }, [openDialog]);

  const handleCopyToClipboard = () => {
    navigator.clipboard
      .writeText(apiResponse.paymentCode)
      .then(() => {
        setCopySuccess("Copiado com sucesso!");
      })
      .catch((err) => {
        console.error("Erro ao copiar para o clipboard:", err);
        setCopySuccess("Erro ao copiar!");
      });
  };

  const isNotSyntheticEvent = (variable) => {
    // Verifica se a variável não possui a propriedade nativeEvent
    return !(
      variable &&
      variable.nativeEvent &&
      variable.nativeEvent instanceof Event
    );
  };

  return (
    <Dialog
      open={openDialog}
      onClose={handleOpenGenerateDialog}
      fullWidth={true}
      maxWidth="sm"
    >
      <DialogContent>
        <div className={classes.dialogContent}>
          {loading ? (
            <CircularProgress />
          ) : (
            <Grid container>
              <Grid
                item
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                style={{
                  textAlign: "center",
                }}
              >
                <Typography variant="body1" gutterBottom>
                  Total de créditos: {isNotSyntheticEvent(credits) && credits}
                </Typography>
                <Typography variant="body1">
                  Valor da compra: R${" "}
                  {isNotSyntheticEvent(valueToPay) && valueToPay},00
                </Typography>
              </Grid>
              <Grid
                item
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "center",
                }}
              >
                <CardMedia
                  component="img"
                  alt="qrCode"
                  src={"data:image/png;base64," + apiResponse.paymentCodeBase64}
                  style={{
                    maxHeight: "80%",
                    maxWidth: "80%",
                  }}
                />
              </Grid>

              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <TextField
                  label="Código do QR Code"
                  variant="outlined"
                  value={apiResponse.paymentCode}
                  multiline
                  disabled
                  maxRows={12}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: 15,
                  }}
                />
              </Grid>

              <Grid
                item
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: 15,
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={handleCopyToClipboard}
                  startIcon={<PixOutlinedIcon />}
                >
                  Copiar códido do Pix
                </Button>
              </Grid>

              <Grid
                item
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                style={{ textAlign: "center" }}
              >
                {copySuccess && (
                  <motion.div
                    className="box"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                  >
                    <Typography>{copySuccess}</Typography>
                  </motion.div>
                )}
              </Grid>
            </Grid>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleOpenGenerateDialog}
          style={{ color: "#808080" }}
          autoFocus
        >
          <strong>fechar</strong>
        </Button>
      </DialogActions>
    </Dialog>
  );
}

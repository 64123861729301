import React, { useEffect, useContext, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid, Paper, Button } from "@material-ui/core";

import { motion } from "framer-motion";

import CheckIcon from "@mui/icons-material/Check";
import GenerateQrCode from "../qrCode/generateQrCode";

import { Context } from "../../context";

const useStyles = makeStyles((theme) => ({
  containerTitle: {
    textAlign: "center",
    padding: 25,
    paddingBottom: 0,
    color: theme.palette.primary.main,
  },
  containerSubtitle: {
    textAlign: "center",
    color: theme.palette.primary.main,
  },
  containerDesktop: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignContent: "flex-start",
    [theme.breakpoints.up("xxs")]: {
      flexDirection: "column",
      alignItems: "center",
      marginTop: 30,
    },
    [theme.breakpoints.up("xs")]: {
      flexDirection: "column",
      alignItems: "center",
      marginTop: 30,
    },
    [theme.breakpoints.up("sm")]: {
      flexDirection: "column",
      alignItems: "center",
      marginTop: 30,
    },
    [theme.breakpoints.up("md")]: {
      flexDirection: "column",
      alignItems: "center",
      marginTop: 30,
    },
    [theme.breakpoints.up("lg")]: {
      flexDirection: "row",
      alignItems: "flex-start",
      marginTop: 0,
    },
    [theme.breakpoints.up("xl")]: {
      flexDirection: "row",
      alignItems: "flex-start",
      marginTop: 0,
    },
  },
  packagesContainer: {
    height: 350,
    width: 300,
    borderRadius: 15,
    padding: 10,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.light,
  },
  packagesContainerTitle: {
    padding: 25,
    paddingBottom: 0,
  },
  packagesContainerSubtitle: {
    padding: 25,
    paddingTop: 0,
  },
  buttonInput: {
    textTransform: "none",
    color: theme.palette.primary.light,
    fontWeight: "bold",
  },
  packageDetails: {
    padding: 25,
    display: "flex",
    alignItems: "center",
  },
  iconStyle: {
    maxWidth: 20,
    maxHeight: 20,
    marginRight: 10,
    color: "#00b090",
  },
  paperSpacer: {
    [theme.breakpoints.up("xxs")]: {
      marginRight: 0,
      marginBottom: 30,
    },
    [theme.breakpoints.up("xs")]: {
      marginRight: 0,
      marginBottom: 30,
    },
    [theme.breakpoints.up("sm")]: {
      marginRight: 0,
      marginBottom: 30,
    },
    [theme.breakpoints.up("md")]: {
      marginRight: 0,
      marginBottom: 30,
    },
    [theme.breakpoints.up("lg")]: {
      marginRight: 30,
      marginBottom: 0,
    },
    [theme.breakpoints.up("xl")]: {
      marginRight: 30,
      marginBottom: 0,
    },
  },
}));

export default function BuyPackages() {
  const classes = useStyles();
  const { setPage, setSelectedIndex } = useContext(Context);
  const [openDialog, setOpenDialog] = useState(false);
  const [valueToPay, setValueToPay] = useState(false);
  const [credit, setCredit] = useState(false);

  const firstPromoCredit = 125;
  const firstPromoPrice = 50;

  const secondPromoCredit = 250;
  const secondPromoPrice = 75;

  const thirdPromoCredit = 360;
  const thirdPromoPrice = 90;

  const fourthPromoCredit = 480;
  const fourthPromoPrice = 120;

  useEffect(() => {
    setPage("Recarregar créditos");
    setSelectedIndex([4, "Recarregar créditos"]);
  }, []);

  const handleOpenGenerateDialog = (value, credits) => {
    setValueToPay(value);
    setCredit(credits);
    setOpenDialog(!openDialog);
  };

  return (
    <Grid container>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Typography
          variant="h4"
          gutterBottom
          className={classes.containerTitle}
        >
          Escolha o plano ideal para você
        </Typography>
        <Typography variant="h6" className={classes.containerSubtitle}>
          Faça sua recarga de créditos e continue publicando seus cupons!
        </Typography>
      </Grid>
      <Grid
        item
        xl={12}
        lg={12}
        md={12}
        sm={12}
        xs={12}
        className={classes.containerDesktop}
      >
        <motion.div whileHover={{ scale: 1.1 }} transition={{ duration: 0.5 }}>
          <Paper
            elevation={3}
            className={`${classes.packagesContainer} ${classes.paperSpacer}`}
          >
            <Typography
              variant="h5"
              className={`${classes.packagesContainerTitle}`}
            >
              {firstPromoCredit} em créditos
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              className={`${classes.packagesContainerSubtitle}`}
            >
              Adquira seus primeiros créditos
            </Typography>
            <Typography
              variant="h2"
              gutterBottom
              className={`${classes.packagesContainerSubtitle}`}
              style={{ textAlign: "center" }}
            >
              <span style={{ fontSize: 25 }}>R$ </span>
              {firstPromoPrice}
            </Typography>

            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                variant="contained"
                color="secondary"
                className={`${classes.buttonInput}`}
                onClick={() =>
                  handleOpenGenerateDialog(firstPromoPrice, firstPromoCredit)
                }
              >
                Escolher Recarga
              </Button>
            </div>
            <Typography
              variant="body2"
              gutterBottom
              className={classes.packageDetails}
            >
              <CheckIcon className={classes.iconStyle} />
              {firstPromoCredit} Créditos para campanhas
            </Typography>
          </Paper>
        </motion.div>
        <motion.div whileHover={{ scale: 1.1 }} transition={{ duration: 0.5 }}>
          <Paper
            elevation={3}
            className={`${classes.packagesContainer} ${classes.paperSpacer}`}
          >
            <Typography
              variant="h5"
              className={`${classes.packagesContainerTitle}`}
            >
              {secondPromoCredit} em créditos
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              className={`${classes.packagesContainerSubtitle}`}
            >
              Expanda mais sua visibilidade
            </Typography>
            <Typography
              variant="h2"
              gutterBottom
              className={`${classes.packagesContainerSubtitle}`}
              style={{ textAlign: "center" }}
            >
              <span style={{ fontSize: 25 }}>R$ </span>
              {secondPromoPrice}
            </Typography>

            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                variant="contained"
                color="secondary"
                className={`${classes.buttonInput}`}
                onClick={() =>
                  handleOpenGenerateDialog(secondPromoPrice, secondPromoCredit)
                }
              >
                Escolher Recarga
              </Button>
            </div>
            <Typography
              variant="body2"
              gutterBottom
              className={classes.packageDetails}
            >
              <CheckIcon className={classes.iconStyle} />
              {secondPromoCredit} Créditos para campanhas
            </Typography>
          </Paper>
        </motion.div>
        <motion.div whileHover={{ scale: 1.1 }} transition={{ duration: 0.5 }}>
          <Paper
            elevation={3}
            className={`${classes.packagesContainer} ${classes.paperSpacer}`}
          >
            <Typography
              variant="h5"
              className={`${classes.packagesContainerTitle}`}
            >
              {thirdPromoCredit} em créditos
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              className={`${classes.packagesContainerSubtitle}`}
            >
              Os holofotes agora serão seus
            </Typography>
            <Typography
              variant="h2"
              gutterBottom
              className={`${classes.packagesContainerSubtitle}`}
              style={{ textAlign: "center" }}
            >
              <span style={{ fontSize: 25 }}>R$</span> {thirdPromoPrice}
            </Typography>

            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                variant="contained"
                color="secondary"
                className={`${classes.buttonInput}`}
                onClick={() =>
                  handleOpenGenerateDialog(thirdPromoPrice, thirdPromoCredit)
                }
              >
                Escolher Recarga
              </Button>
            </div>
            <Typography
              variant="body2"
              gutterBottom
              className={classes.packageDetails}
            >
              <CheckIcon className={classes.iconStyle} />
              {thirdPromoCredit} Créditos para campanhas
            </Typography>
          </Paper>
        </motion.div>
        <motion.div whileHover={{ scale: 1.1 }} transition={{ duration: 0.5 }}>
          <Paper
            elevation={3}
            className={`${classes.packagesContainer} ${classes.paperSpacer}`}
          >
            <Typography
              variant="h5"
              className={`${classes.packagesContainerTitle}`}
            >
              {fourthPromoCredit} em créditos
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              className={`${classes.packagesContainerSubtitle}`}
            >
              O maior BOOM do seu negócio
            </Typography>
            <Typography
              variant="h2"
              gutterBottom
              className={`${classes.packagesContainerSubtitle}`}
              style={{ textAlign: "center" }}
            >
              <span style={{ fontSize: 25 }}>R$</span> {fourthPromoPrice}
            </Typography>

            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                variant="contained"
                color="secondary"
                className={`${classes.buttonInput}`}
                onClick={() =>
                  handleOpenGenerateDialog(fourthPromoPrice, fourthPromoCredit)
                }
              >
                Escolher Recarga
              </Button>
            </div>
            <Typography
              variant="body2"
              gutterBottom
              className={classes.packageDetails}
            >
              <CheckIcon className={classes.iconStyle} />
              {fourthPromoCredit} Créditos para campanhas
            </Typography>
          </Paper>
        </motion.div>
      </Grid>
      <GenerateQrCode
        valueToPay={valueToPay}
        openDialog={openDialog}
        handleOpenGenerateDialog={handleOpenGenerateDialog}
        credits={credit}
      />
    </Grid>
  );
}

import React, { useContext, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Card,
  CardContent,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Grid,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  CircularProgress,
} from "@material-ui/core";

import { Context } from "../../context";
import Api2 from "../../service";
import Api from "../../service/gatewayApi";
import GenerateCurrentTime from "../../utils/GenerateCurrentTime";

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    width: 300,
    height: 160,
    borderRadius: 20,
    boxShadow: " 0px 0px 22px 1px rgba(0,0,0,0.74)",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.light,
    textAlign: "center",
  },
  inputMaior: {
    width: "100%",
  },
  dialogContainer: {
    display: "flex",
  },
  nonClickable: {
    pointerEvents: "none",
    cursor: "not-allowed",
  },
}));

export default function WithdrawCredits() {
  const classes = useStyles();
  const { cookie, setCookie, setUserInfos } = useContext(Context);
  const [openModal, setOpenModal] = useState(false);
  const [openModalResponse, setOpenModalResponse] = useState(false);
  const [valueToWithdraw, setValueToWithDraw] = useState(0);
  const [pixCode, setPixCode] = useState("0");
  const [pixType, setPixType] = useState("document");
  const [loadingResponse, setLoadingResponse] = useState(false);
  const [withdrawResponse, setWithdrawResponse] = useState("");

  useEffect(() => {
    setValueToWithDraw(0);
    setPixCode(cookie.userInfo[0].document);
    setPixType("document");
    setLoadingResponse(false);
    setWithdrawResponse("");
  }, []);

  const handleButton = async () => {
    await updateCookie();
    setOpenModal(true);
  };

  const updateCookie = async () => {
    Api2.get("/api/user/detail", {
      params: {
        _id: cookie.userInfo[0]._id,
      },
    })
      .then((res) => {
        setCookie("userInfo", res.data, { expire: 1, path: "/" });
        setUserInfos(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleCloseModal = async (props) => {
    if (props === "fechar") {
      setOpenModal(false);
      setValueToWithDraw(0);
      setWithdrawResponse("");
    } else {
      setLoadingResponse(true);
      setOpenModalResponse(true);
      setOpenModal(false);

      const responseMap = {
        OK: "Transação realizada com sucesso!",
        ACCOUNT_DOCUMENTS_NOT_VALIDATED: "Conta não validada.",
        NO_FUNDS: "Saldo insuficiente para saque.",
        PIX_KEY_NOT_FOUND: "Chave PIX não encontrada.",
        UNAUTHORIZED_IP: "IP não autorizado para saque.",
        DOCUMENT_VALIDATE:
          "A chave pix não pertence ao documento informado na validação.",
        ERROR: "Erro interno do servidor. Tente novamente mais tarde.",
      };

      try {
        const apiResponse = await Api.post(
          "/api/v1/gateway/pix-payment",
          {
            key: pixCode,
            typeKey: pixType,
            callbackUrl: "https://api.cupomzito.com/api/log/pix/cashout",
            value: valueToWithdraw - (valueToWithdraw * 7) / 100,
            documentValidation: cookie.userInfo[0].document,
          },
          {
            headers: {
              ci: process.env.REACT_APP_CI,
              cs: process.env.REACT_APP_CS,
            },
          }
        );

        await Api2.put("/api/user", {
          _id: cookie.userInfo[0]._id,
          credit: cookie.userInfo[0].credit - valueToWithdraw,
        });

        await updateCookie();

        setWithdrawResponse(
          responseMap[apiResponse.data.response] || "Resultado desconhecido"
        );
        setValueToWithDraw(0);
        setLoadingResponse(false);
      } catch (err) {
        setWithdrawResponse(
          responseMap[err.response.data.response] || "Resultado desconhecido"
        );
        setLoadingResponse(false);
      }
    }
  };

  const handleCloseModalResponse = () => {
    setOpenModalResponse(false);
    setValueToWithDraw(0);
  };

  return (
    <>
      <Card className={classes.cardContainer}>
        <CardContent>
          <Typography variant="h5" gutterBottom>
            Saldo
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            style={{ paddingBottom: 15 }}
          >
            Saldo atual: R$ {Number(cookie.userInfo[0].credit).toFixed(2)}
          </Typography>
          <Button variant="outlined" color="secondary" onClick={handleButton}>
            Sacar
          </Button>
        </CardContent>
      </Card>
      <Dialog
        open={openModal}
        onClose={() => handleCloseModal("fechar")}
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle>
          <strong>
            <em>Atenção:</em>
          </strong>
        </DialogTitle>
        <DialogContent style={{ textAlign: "center" }}>
          <Grid container className={classes.dialogContainer}>
            <Grid
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              style={{ textAlign: "left" }}
            >
              <Typography>
                Valor disponível: R${" "}
                {Number(cookie.userInfo[0].credit).toFixed(2)}
              </Typography>
            </Grid>
            <Grid
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              style={{ textAlign: "left" }}
            >
              <Typography gutterBottom>
                Valor mínimo para saque: R$ 500.00
              </Typography>
            </Grid>
            <Grid
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              style={{ marginBottom: 20, textAlign: "left" }}
            >
              <Typography gutterBottom>
                A CupomZito descontará a taxa de transação (7%) do valor de
                saque solicitado. Simule abaixo o quanto você receberá:
              </Typography>
            </Grid>
            <Grid
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              style={{ marginBottom: 20, textAlign: "left" }}
            >
              <Typography gutterBottom>
                Insira o valor que deseja sacar:
              </Typography>
            </Grid>
            <Grid
              item
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
              style={{ padding: 5 }}
            >
              <TextField
                id="valueToWithdraw"
                type="number"
                value={valueToWithdraw}
                onInput={(e) => {
                  setValueToWithDraw(e.target.value);
                }}
                label="Valor do saque"
                variant="outlined"
                className={classes.inputMaior}
              />
            </Grid>
            <Grid
              item
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
              style={{ padding: 5 }}
            >
              <div
                onClick={(e) => e.stopPropagation()} // Intercepta o evento de clique no container
                className={classes.nonClickable}
              >
                <TextField
                  id="finalValue"
                  value={
                    valueToWithdraw - (valueToWithdraw * 7) / 100 > 0
                      ? valueToWithdraw - (valueToWithdraw * 7) / 100
                      : 0
                  }
                  label="Valor a receber"
                  variant="outlined"
                  className={classes.inputMaior}
                />
              </div>
            </Grid>
            <Grid
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              style={{ marginTop: 20, marginBottom: 20, textAlign: "left" }}
            >
              <Typography gutterBottom>
                Insira a Chave PIX na qual deseja receber seu saque:
              </Typography>
            </Grid>
            <Grid
              item
              xl={8}
              lg={8}
              md={8}
              sm={12}
              xs={12}
              style={{ padding: 5 }}
            >
              <TextField
                id="pixCode"
                type="text"
                value={pixCode}
                onInput={(e) => {
                  setPixCode(e.target.value);
                }}
                label="Chave PIX"
                variant="outlined"
                className={classes.inputMaior}
              />
            </Grid>
            <Grid
              item
              xl={4}
              lg={4}
              md={4}
              sm={12}
              xs={12}
              style={{ padding: 5 }}
            >
              <FormControl variant="outlined" className={classes.inputMaior}>
                <InputLabel>Tipo da chave PIX</InputLabel>
                <Select
                  id="role"
                  value={pixType}
                  onChange={(e) => setPixType(e.target.value)}
                  label="Tipo da chave PIX"
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                    PaperProps: {
                      style: {
                        maxHeight: 200,
                      },
                    },
                  }}
                  style={{ textAlign: "left" }}
                >
                  <MenuItem value={"document"}>CPF / CNPJ</MenuItem>
                  <MenuItem value={"phoneNumber"}>Nº de Telefone</MenuItem>
                  <MenuItem value={"email"}>E-mail</MenuItem>
                  <MenuItem value={"randomKey"}>Chave Aleatória</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleCloseModal("fechar")}
            style={{ color: "#808080" }}
            autoFocus
          >
            <strong>fechar</strong>
          </Button>

          <Button
            onClick={() => handleCloseModal("outro")}
            color="secondary"
            autoFocus
            disabled={
              cookie.userInfo[0].credit < 500 ||
              valueToWithdraw < 500 ||
              pixCode === "" ||
              valueToWithdraw > cookie.userInfo[0].credit
            }
          >
            <strong>Sacar</strong>
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openModalResponse}
        onClose={handleCloseModalResponse}
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle>
          <strong>
            <em>Atenção:</em>
          </strong>
        </DialogTitle>
        <DialogContent style={{ textAlign: "center" }}>
          {loadingResponse ? (
            <CircularProgress />
          ) : (
            <Typography variant="h6" style={{ color: "gray" }}>
              {withdrawResponse}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseModalResponse}
            style={{ color: "#808080" }}
            autoFocus
          >
            <strong>fechar</strong>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  CardHeader,
  Avatar,
  CardActionArea,
} from "@material-ui/core";

import LocalHospitalOutlinedIcon from "@mui/icons-material/LocalHospitalOutlined";
import AutoAwesomeOutlinedIcon from "@mui/icons-material/AutoAwesomeOutlined";
import RestaurantOutlinedIcon from "@mui/icons-material/RestaurantOutlined";
import CottageOutlinedIcon from "@mui/icons-material/CottageOutlined";
import PetsOutlinedIcon from "@mui/icons-material/PetsOutlined";
import AppShortcutOutlinedIcon from "@mui/icons-material/AppShortcutOutlined";
import DirectionsCarOutlinedIcon from "@mui/icons-material/DirectionsCarOutlined";
import ChildFriendlyOutlinedIcon from "@mui/icons-material/ChildFriendlyOutlined";
import Diversity1OutlinedIcon from "@mui/icons-material/Diversity1Outlined";
import CheckroomOutlinedIcon from "@mui/icons-material/CheckroomOutlined";
import MenuBookOutlinedIcon from "@material-ui/icons/MenuBookOutlined";

import { Context } from "../../../context";

import CupomzitoSvg from "../../../assets/cupomzito.svg";

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: theme.palette.secondary.main,
  },
  cardContainer: {
    width: 270,
    height: 420,
    minHeight: 420,
    minWidth: 270,
    backgroundColor: theme.palette.primary.light,
    width: 270,
  },
  cardActionAreaContainer: {
    minHeight: 420,
    minWidth: 270,
  },
  subheaderColor: {
    color: theme.palette.primary.mainSecondary,
  },
  titleColor: {
    color: theme.palette.primary.main,
  },
  contentTitle: {
    fontWeight: "bold",
    position: "absolute",
    top: 245,
    wordBreak: "break-word",
    paddingRight: 10,
    color: theme.palette.primary.main,
  },
  contentDescription: {
    position: "absolute",
    top: 295,
    wordBreak: "break-word",
    paddingRight: 10,
    color: theme.palette.primary.main,
  },
  contentContact: {
    wordBreak: "break-word",
    position: "absolute",
    bottom: 10,
    paddingRight: 10,
    color: theme.palette.primary.mainSecondary,
    display: "flex",
    alignContent: "center",
  },
  cardActions: {
    display: "flex",
    justifyContent: "center",
  },
  cardMediaContainer: {
    position: "absolute",
    top: 70,
    height: 170,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    left: "50%",
    transform: "translateX(-50%)",
    backgroundColor: theme.palette.primary.main,
  },
  campaignCity: {
    fontSize: 13,
    color: theme.palette.primary.light,
    fontWeight: "bold",
    padding: 5,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  cardCouponsCity: {
    position: "absolute",
    top: 210,
    right: 0,
    maxWidth: "100%",
    borderRadius: 10,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    backgroundColor: theme.palette.primary.main,
    zIndex: 9,
  },
}));

export default function MaximizedCampaignPreview(props) {
  const classes = useStyles();
  const { description, title, category, remaining, banner, store, city } =
    props;

  const { cookie } = useContext(Context);

  const handleClickOnCardArea = (e) => {
    e.preventDefault();
  };

  return (
    <Card className={classes.cardContainer}>
      <CardActionArea
        className={classes.cardActionAreaContainer}
        onClick={(e) => handleClickOnCardArea(e)}
      >
        <CardHeader
          style={{
            position: "absolute",
            top: 0,
          }}
          avatar={
            <Avatar className={classes.avatar}>
              {category === "Saúde" ? (
                <LocalHospitalOutlinedIcon />
              ) : category === "Beleza" ? (
                <AutoAwesomeOutlinedIcon />
              ) : category === "Vestuário" ? (
                <CheckroomOutlinedIcon />
              ) : category === "Comida" ? (
                <RestaurantOutlinedIcon />
              ) : category === "Casa" ? (
                <CottageOutlinedIcon />
              ) : category === "Pets" ? (
                <PetsOutlinedIcon />
              ) : category === "Eletrônicos" ? (
                <AppShortcutOutlinedIcon />
              ) : category === "Automotivo" ? (
                <DirectionsCarOutlinedIcon />
              ) : category === "Kids" ? (
                <ChildFriendlyOutlinedIcon />
              ) : category === "Livraria" ? (
                <MenuBookOutlinedIcon />
              ) : (
                <Diversity1OutlinedIcon />
              )}
            </Avatar>
          }
          title={
            <Typography variant="body1" className={classes.titleColor}>
              {category}
            </Typography>
          }
          subheader={
            <Typography variant="body2" className={classes.subheaderColor}>{`${
              remaining > 0 ? `Disponível: ${remaining}` : "Esgotado"
            }`}</Typography>
          }
        />
        <div className={classes.cardMediaContainer}>
          <CardMedia
            component="img"
            alt="bannerImage"
            src={banner !== "default" ? banner : CupomzitoSvg}
            style={{
              padding: banner !== "default" ? 0 : 10,
              height: banner !== "default" && "100%",
              width: "100%",
              objectFit: "cover",
            }}
          />
        </div>
        <div className={classes.cardCouponsCity}>
          <Typography variant="body2" className={classes.campaignCity}>
            {city}
          </Typography>
        </div>
        <CardContent>
          <Typography variant="body1" className={classes.contentTitle}>
            {title.toUpperCase()}
          </Typography>
          <Typography variant="body1" className={classes.contentDescription}>
            {description}
          </Typography>
          <Typography variant="body1" className={classes.contentContact}>
            {store || cookie.userInfo[0].fullName}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

import React, { useState, useEffect, useContext } from "react";
import {
  AppBar,
  Toolbar,
  Grid,
  Button,
  IconButton,
  SwipeableDrawer,
  FormControl,
  TextField,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import Autocomplete from "@mui/material/Autocomplete";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { ReactComponent as Logo } from "../../assets/cupomzito.svg";
import { Context } from "../../context";

import MenuIcon from "@material-ui/icons/Menu";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import AccountCircle from "@mui/icons-material/AccountCircleOutlined";
import ClearIcon from "@material-ui/icons/Clear";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import HomeClients from "../../components/clients/home";

import history from "../../routes/history";

import Api from "../../service";

import "./style.css";

const useStyles = makeStyles((theme) => ({
  "@global": {
    "input:-webkit-autofill": {
      "-webkit-text-fill-color": "white !important",
      transition: "background-color 5000s ease-in-out 0s",
    },
    "input:-webkit-autofill:hover": {
      "-webkit-text-fill-color": "transparent",
      transition: "background-color 5000s ease-in-out 0s",
    },
    "input:-webkit-autofill:focus": {
      "-webkit-text-fill-color": "transparent",
      transition: "background-color 5000s ease-in-out 0s",
    },
    "input:-webkit-autofill:active": {
      "-webkit-text-fill-color": "transparent",
      transition: "background-color 5000s ease-in-out 0s",
    },
  },
  appBar: {
    position: "fixed",
    [theme.breakpoints.up("xxs")]: {
      padding: 10,
      backgroundColor: theme.palette.primary.main,
    },
    [theme.breakpoints.up("xs")]: {
      padding: 10,
      backgroundColor: theme.palette.primary.main,
    },
    [theme.breakpoints.up("sm")]: {
      padding: 10,
      backgroundColor: theme.palette.primary.main,
    },
    [theme.breakpoints.up("md")]: {
      padding: 10,
      backgroundColor: theme.palette.primary.main,
    },
    [theme.breakpoints.up("lg")]: {
      padding: 30,
      backgroundColor: theme.palette.primary.main,
    },
    [theme.breakpoints.up("xl")]: {
      padding: 30,
      backgroundColor: theme.palette.primary.main,
    },
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-evenly",
  },
  leftContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignContent: "center",
    alignItems: "center",
  },
  rightContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignContent: "center",
    alignItems: "center",
  },
  buttonStyle: {
    color: theme.palette.primary.light,
    fontWeight: "bold",
    height: 50,
  },
  mobileButtonStyle: {
    color: theme.palette.primary.light,
    fontWeight: "bold",
    height: 35,
    width: 80,
    textTransform: "none",
  },
  subItemButton: {
    marginTop: 10,
    textTransform: "none",
    width: "100%",
    color: theme.palette.primary.light,
    fontSize: "1.2rem",
    display: "flex",
    justifyContent: "flex-start",
  },
  subItemLoginButton: {
    marginTop: 10,
    textTransform: "none",
    width: "100%",
    color: theme.palette.primary.main,
    fontSize: "1.2rem",
    fontWeight: "bold",
  },
  subItemCreateButton: {
    marginTop: 10,
    textTransform: "none",
    width: "100%",
    fontSize: "1.2rem",
    fontWeight: "bold",
  },
  logo: {
    [theme.breakpoints.up("xxs")]: {
      width: 125,
      marginRight: 50,
      height: 65,
    },
    [theme.breakpoints.up("xs")]: {
      width: 125,
      marginRight: 50,
      height: 65,
    },
    [theme.breakpoints.up("sm")]: {
      width: 125,
      marginRight: 50,
      height: 65,
    },
    [theme.breakpoints.up("md")]: {
      width: 125,
      marginRight: 50,
      height: 65,
    },
    [theme.breakpoints.up("lg")]: {
      width: 200,
      marginRight: 50,
      height: 65,
    },
    [theme.breakpoints.up("xl")]: {
      width: 200,
      marginRight: 50,
      height: 65,
    },
  },
  appbarIcon: {
    color: theme.palette.primary.light,
    width: 30,
    height: 30,
  },
  mobileAppbarGrid: {
    display: "flex",
    justifyContent: "center",
  },
  drawer: {
    width: "100vw",
    [theme.breakpoints.up("xxs")]: {
      width: "250px",
    },
    [theme.breakpoints.up("xs")]: {
      width: "250px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "250px",
    },
    [theme.breakpoints.up("md")]: {
      width: "250px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "300px",
    },
    [theme.breakpoints.up("xl")]: {
      width: "300px",
    },
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    background: theme.palette.primary.main,
    width: "350px",
    overflowX: "hidden",
    scrollbarWidth: "thin",
  },
  searchCity: {
    width: "100%",
  },
  inputStyle: {
    width: "100%",
    [`& fieldset`]: {
      borderRadius: 20,
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.primary.lightSecondary,
      },
      "&:hover fieldset": {
        borderColor: theme.palette.primary.lightSecondary,
      },
    },
  },
  inputColor: {
    color: theme.palette.primary.light,
  },
  notchedOutline: {
    backgroundColor: "transparent",
    borderColor: theme.palette.primary.light,
    textColor: theme.palette.primary.light,
  },
  clearIndicator: {
    color: theme.palette.primary.light, // Cor do botão "x"
  },
  popupIndicator: {
    color: theme.palette.primary.light, // Cor da seta
  },
  footer: {
    backgroundColor: "#333",
    color: "#fff",
    textAlign: "center",
    padding: "10px 0",
    position: "fixed",
    left: "0",
    bottom: "0",
    width: "100%",
    zIndex: 99999,
  },
}));

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export default function Home() {
  const classes = useStyles();
  const {
    clientBarLeftOpen,
    setClientBarLeftOpen,
    cookie,
    cityFilter,
    setCityFilter,
    handleLogout,
    setName,
    setEmail,
    setPhone,
    setCity,
    setCEP,
    setStreet,
    setNumber,
    setNeighborhood,
    setComplement,
    setDocument,
    setIsFromMyProfile,
    setLoading,
  } = useContext(Context);
  const [isMobile, setIsMobile] = useState(false);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const [cities, setCities] = useState(["Todas"]);
  const [inputValue, setInputValue] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    const newWindowDimensions = getWindowDimensions();
    function handleResize() {
      setWindowDimensions(newWindowDimensions);
    }

    if (
      (newWindowDimensions.width >= 0 && newWindowDimensions.width <= 300) ||
      (newWindowDimensions.width > 300 && newWindowDimensions.width <= 600) ||
      (newWindowDimensions.width > 600 && newWindowDimensions.width <= 900) ||
      (newWindowDimensions.width > 900 && newWindowDimensions.width <= 1280)
    ) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [windowDimensions]);

  useEffect(() => {
    let isMounted = true;
    setInputValue("Todas");
    if (cookie.userInfo && Object.keys(cookie).length !== 0) {
      setCityFilter(
        `${cookie.userInfo[0].address.city} / ${cookie.userInfo[0].address.state}`
      );
    } else {
      setCityFilter("Todas");
    }
    const fetchCities = async () => {
      try {
        const response = await fetch(
          `https://servicodados.ibge.gov.br/api/v1/localidades/municipios?orderBy=nome`
        );
        if (response.ok) {
          const data = await response.json();

          if (isMounted) {
            const cityNames = data.map((city) => {
              return `${city.nome} / ${city.microrregiao.mesorregiao.UF.sigla}`;
            });
            cityNames.unshift("Todas");
            setCities(cityNames);
          }
        } else {
          console.error("Erro ao buscar cidades");
        }
      } catch (error) {
        if (isMounted) {
          console.error("Erro na requisição: ", error);
        }
      }
    };

    fetchCities();
    return () => {
      isMounted = false; // Define a flag como false quando o componente for desmontado
    };
  }, [cookie]);

  useEffect(() => {
    if (cookie.userInfo && Object.keys(cookie).length !== 0) {
      const token = cookie.token.accessToken;
      Api.defaults.headers.common["x-access-token"] = token;

      setCityFilter(
        `${cookie.userInfo[0].address.city} / ${cookie.userInfo[0].address.state}`
      );
    } else {
      setCityFilter("Todas");
    }
    setIsFromMyProfile(false);
  }, []);

  const handleDrawerOpen = () => {
    setClientBarLeftOpen(true);
  };

  const handleDrawerClose = () => {
    setClientBarLeftOpen(false);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleGoToHome = () => {
    handleClose();
    handleDrawerClose();
    history.push("/");
  };

  const handleHelpLogout = () => {
    if (cookie.userInfo && Object.keys(cookie).length !== 0) {
      handleLogout();
    }
    handleClose();
    handleDrawerClose();
  };

  const handleOpenEditProfile = () => {
    const token = cookie.token.accessToken;
    Api.defaults.headers.common["x-access-token"] = token;

    handleClose();
    handleDrawerClose();
    setIsFromMyProfile(true);
    Api.get("/api/user/detail", {
      params: {
        _id: cookie.userInfo[0]._id,
      },
    }).then((res) => {
      setName(res.data[0].fullName);
      setEmail(res.data[0].email);
      setPhone(res.data[0].phone);
      setCity(`${res.data[0].address.city} / ${res.data[0].address.state}`);
      setCEP(res.data[0].address.cep);
      setStreet(res.data[0].address.street);
      setNumber(res.data[0].address.number);
      setNeighborhood(res.data[0].address.neighborhood);
      setComplement(res.data[0].address.complement);
      setDocument(res.data[0].document);
      history.push("/create-account");
    });
  };

  const handleOpenMyCoupons = () => {
    handleClose();
    handleDrawerClose();
    history.push("/my-coupons");
  };

  const handleOpenAdminPanel = () => {
    setLoading(true);
    handleClose();
    handleDrawerClose();
    history.push("/main");
    window.location.reload();
  };

  return (
    <>
      <AppBar className={classes.appBar}>
        <Toolbar>
          {!isMobile ? (
            <Grid
              container
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Grid
                item
                xl={3}
                lg={3}
                md={3}
                sm={12}
                xs={12}
                className={classes.leftContainer}
              >
                <Link to="/">
                  <Logo className={classes.logo} />
                </Link>

                {/* {cookie.userInfo && (
                  <Button className={classes.buttonStyle}>Meus Cupons</Button>
                )} */}
              </Grid>

              <Grid item xl={2} lg={2} md={2} sm={12} xs={12} />

              <Grid item xl={2} lg={2} md={2} sm={12} xs={12}>
                <FormControl variant="outlined" className={classes.searchCity}>
                  <Autocomplete
                    value={cityFilter || ""}
                    onChange={(event, newValue) => {
                      setCityFilter(newValue);
                    }}
                    inputValue={inputValue}
                    onInputChange={(event, newInputValue) => {
                      setInputValue(newInputValue);
                    }}
                    options={cities}
                    getOptionLabel={(option) => option || ""}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Cidade"
                        variant="outlined"
                        InputProps={{
                          ...params.InputProps,
                          classes: {
                            input: classes.inputColor,
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                        InputLabelProps={{
                          style: {
                            color: "white",
                          },
                        }}
                        className={classes.inputStyle}
                      />
                    )}
                    clearOnEscape
                    clearIcon={<ClearIcon className={classes.clearIndicator} />}
                    popupIcon={
                      <ExpandMoreIcon className={classes.popupIndicator} />
                    }
                  />
                </FormControl>
              </Grid>

              <Grid item xl={2} lg={2} md={2} sm={12} xs={12} />

              <Grid
                item
                xl={3}
                lg={3}
                md={3}
                sm={12}
                xs={12}
                className={classes.rightContainer}
              >
                {cookie.userInfo && Object.keys(cookie).length !== 0 ? (
                  <>
                    <Typography>
                      Bem vindo,{" "}
                      <strong>
                        {cookie.userInfo[0].fullName.split(" ")[0]}!
                      </strong>
                    </Typography>

                    <div>
                      <IconButton onClick={handleMenu} color="inherit">
                        <AccountCircle style={{ width: 40, height: 40 }} />
                      </IconButton>
                      <Menu
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        keepMounted
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        PaperProps={{
                          style: {
                            marginTop: "50px",
                            paddingLeft: 10,
                            paddingRight: 10,
                          },
                        }}
                      >
                        <MenuItem onClick={handleGoToHome}>Início</MenuItem>
                        <MenuItem onClick={handleOpenEditProfile}>
                          Meu Perfil
                        </MenuItem>
                        <MenuItem onClick={handleOpenMyCoupons}>
                          Meus Cupons
                        </MenuItem>
                        {cookie.userInfo &&
                          cookie.userInfo[0].role !== "common" && (
                            <MenuItem onClick={handleOpenAdminPanel}>
                              Painel Administrador
                            </MenuItem>
                          )}
                        <MenuItem onClick={handleHelpLogout}>Sair</MenuItem>
                      </Menu>
                    </div>
                  </>
                ) : (
                  <>
                    {history.location.pathname !== "/create-account" && (
                      <Link to="/create-account">
                        <Button className={classes.buttonStyle}>
                          Criar Conta
                        </Button>
                      </Link>
                    )}

                    <Link to="/login">
                      <Button
                        variant="outlined"
                        color="secondary"
                        className={classes.buttonStyle}
                        style={{ marginLeft: 40 }}
                      >
                        Entrar
                      </Button>
                    </Link>
                  </>
                )}
              </Grid>
            </Grid>
          ) : (
            <>
              <Grid
                container
                spacing={2}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                  <IconButton edge="start" onClick={handleDrawerOpen}>
                    <MenuIcon className={classes.appbarIcon} />
                  </IconButton>
                </Grid>
                <Grid item xl={5} lg={5} md={5} sm={5} xs={5}>
                  <Link to="/">
                    <Logo className={classes.logo} />
                  </Link>
                </Grid>
                <Grid
                  item
                  xl={5}
                  lg={5}
                  md={5}
                  sm={5}
                  xs={5}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <FormControl className={classes.searchCity}>
                    <Autocomplete
                      value={cityFilter || ""}
                      onChange={(event, newValue) => {
                        setCityFilter(newValue);
                      }}
                      inputValue={inputValue}
                      onInputChange={(event, newInputValue) => {
                        setInputValue(newInputValue);
                      }}
                      options={cities}
                      getOptionLabel={(option) => option || ""}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Cidade"
                          variant="outlined"
                          InputProps={{
                            ...params.InputProps,
                            classes: {
                              input: classes.inputColor,
                              notchedOutline: classes.notchedOutline,
                            },
                          }}
                          InputLabelProps={{
                            style: {
                              color: "white",
                            },
                          }}
                          className={classes.inputStyle}
                        />
                      )}
                      clearOnEscape
                      clearIcon={
                        <ClearIcon className={classes.clearIndicator} />
                      }
                      popupIcon={
                        <ExpandMoreIcon className={classes.popupIndicator} />
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <SwipeableDrawer
                open={clientBarLeftOpen}
                onClose={handleDrawerClose}
                onOpen={handleDrawerOpen}
                classes={{
                  paper: classes.drawerOpen,
                }}
              >
                <Grid
                  container
                  style={{
                    padding: 30,
                    display: "flex",
                    alignItems: "center",
                    paddingTop: 10,
                  }}
                >
                  <Grid
                    item
                    xl={6}
                    lg={6}
                    md={6}
                    sm={6}
                    xs={6}
                    style={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    <Link to="/" onClick={handleDrawerClose}>
                      <Logo className={classes.logo} style={{ width: 150 }} />
                    </Link>
                  </Grid>
                  <Grid
                    item
                    xl={6}
                    lg={6}
                    md={6}
                    sm={6}
                    xs={6}
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <IconButton onClick={handleDrawerClose}>
                      <CloseOutlinedIcon className={classes.appbarIcon} />
                    </IconButton>
                  </Grid>
                  {cookie.userInfo && Object.keys(cookie).length !== 0 ? (
                    <>
                      <Grid
                        item
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          marginTop: 20,
                        }}
                      >
                        <Typography variant="h6" style={{ color: "white" }}>
                          Bem vindo,{" "}
                          <strong>
                            {cookie.userInfo[0].fullName.split(" ")[0]}!
                          </strong>
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          marginTop: 10,
                        }}
                      >
                        <Button
                          color="secondary"
                          className={classes.subItemButton}
                          onClick={handleGoToHome}
                        >
                          Início
                        </Button>
                      </Grid>
                      <Grid
                        item
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        <Button
                          color="secondary"
                          className={classes.subItemButton}
                          onClick={handleOpenEditProfile}
                        >
                          Meu Perfil
                        </Button>
                      </Grid>
                      <Grid
                        item
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        <Button
                          color="secondary"
                          className={classes.subItemButton}
                          onClick={handleOpenMyCoupons}
                        >
                          Meus Cupons
                        </Button>
                      </Grid>
                      {cookie.userInfo &&
                        cookie.userInfo[0].role !== "common" && (
                          <Grid
                            item
                            xl={12}
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                            }}
                          >
                            <Button
                              color="secondary"
                              className={classes.subItemButton}
                              onClick={handleOpenAdminPanel}
                            >
                              Painel Administrador
                            </Button>
                          </Grid>
                        )}
                    </>
                  ) : (
                    <Grid
                      item
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        marginTop: 10,
                      }}
                    >
                      <Button
                        color="secondary"
                        className={classes.subItemButton}
                        onClick={handleGoToHome}
                      >
                        Início
                      </Button>
                    </Grid>
                  )}
                  <Grid
                    item
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      marginTop: 10,
                    }}
                  >
                    {cookie.userInfo && Object.keys(cookie).length !== 0 ? (
                      <Button
                        variant="contained"
                        color="secondary"
                        className={classes.subItemLoginButton}
                        onClick={handleHelpLogout}
                      >
                        Sair
                      </Button>
                    ) : (
                      <Link to="/login" className={classes.subItemLoginButton}>
                        <Button
                          variant="contained"
                          color="secondary"
                          className={classes.subItemLoginButton}
                          onClick={handleDrawerClose}
                        >
                          Entrar
                        </Button>
                      </Link>
                    )}
                  </Grid>
                  {history.location.pathname !== "/create-account" &&
                    Object.keys(cookie).length === 0 && (
                      <Grid
                        item
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          marginTop: 10,
                        }}
                      >
                        <Link
                          to="/create-account"
                          className={classes.subItemCreateButton}
                        >
                          <Button
                            variant="outlined"
                            color="secondary"
                            className={classes.subItemCreateButton}
                            onClick={handleDrawerClose}
                          >
                            Criar conta
                          </Button>
                        </Link>
                      </Grid>
                    )}
                </Grid>
              </SwipeableDrawer>
            </>
          )}
        </Toolbar>
      </AppBar>
      {history.location.pathname === "/" && <HomeClients />}
      {/* <Grid
        style={{
          width: "100%",
          position: "absolute",
          bottom: 0,
          backgroundColor: "blue",
          paddingTop: "1rem",
          paddingBottom: "1rem",
        }}
      >
        <Grid container direction="column" alignItems="center">
          <Grid item xs={12}>
            <Typography variant="h5">React Starter App</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1">
              {`${new Date().getFullYear()} | React | Material UI | React Router`}
            </Typography>
          </Grid>
        </Grid>
      </Grid> */}
    </>
  );
}
